import React from "react";

class Page extends React.Component {
  constructor(props) {
    super(props);
  }

  binRowHtml() {
    const lots = this.props.lots;
    let list = [];
    for (const l of lots) {
      // use prod img
      let imgUrl = l.lot_image;
      imgUrl = imgUrl.replace(
        "//s3.amazonaws.com/images-dev.charitybuzz.com/",
        "//s3.amazonaws.com/images.charitybuzz.com/"
      );

      list.push(
        <div className="bin-item" key={l.id}>
          <a
            href={`/catalog_items/${l.pretty_id}`}
            style={{ backgroundImage: `url(${imgUrl})` }}
          ></a>
        </div>
      );
    }
    return list;
  }

  render() {
    const rowHtml = this.binRowHtml();

    let html = (
      <div className="bin-row">
        <div className="bin-items-wrapper">{rowHtml}</div>
        <div className="bin-title">
          Check out our top <span className="blue-text">Buy Now</span> items
        </div>
        <div className="bin-nav-btn">
          <a href="/search?filters%5Bbuying_format%5D%5B0%5D=Buy%20It%20Now">
            Shop now {">"}
          </a>
        </div>
      </div>
    );

    return html;
  }
}

export default Page;
