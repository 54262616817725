import React from 'react'

class Page extends React.Component {
  constructor(props){
    super(props)
  }

  itemHtml({item}){
    let listHtml = []
    const list = item.detail.list
    for(let i=0; i<list.length; i++){
      let ans = list[i].answer
      const ref = list[i].ref
      const type = list[i].type
      if(type == 'file_url'){
        if(ans.indexOf('.pdf') > -1){
          ans = <a href={ans} target="_blank">View PDF</a>
        }
        else{
          ans = <img className="did-img" src={ans} alt={"Answer of "+ list[i].title} />
        }
      }
      listHtml.push(
        <div className='grid-x did-line' key={ref}>
          <div className='cell small-8 did-title'>{list[i].title}</div>
          <div className='cell small-4 did-answer'>{ans}</div>
        </div>
      )
    }

    const translation = item.detail.translation
    let translationHtml = []
    for(let i=0; i<translation.length; i++){
      translationHtml.push(
        <div className='did-translation' key={`trans-${i}`}>
          {translation[i]}
        </div>
      )
    }

    let html = (
      <div className="">
        <div className="did-list">
          {listHtml}
        </div>

        {/*<hr />

        <div className="h4">
          How it will be displayed
        </div>
        <div className="did-translations">
          {translationHtml}
        </div>*/}
      </div>
    )

    return html
  }


  render(){
    const item = this.props.item
    if(!item || !item.detail){
      return ""
    }

    let html = (
      <div className="cell">
        {this.itemHtml({item: item})}
      </div>
    )

    return html
  }
}

export default Page
