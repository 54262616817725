class Notification {
  checkSMSSettings(){
    const url = `/account/notifications/settings.json`
    return $.get(url)
  }

  setSMSSettings({settings, event_action, last_action_id}){
    const url = `/account/notifications/settings.json`
    const data = {
      settings: settings,
      for_action: event_action,
      last_action_id: last_action_id
    }
    return $.post(url, data)
  }
}

export default Notification