class Tracker {

  trackPageView({url}){
    ga('send', {
      hitType: 'pageview',
      page: url
    })
  }

  trackSearchResults({results}){
    const keyword = this.sanitizeKeyword(results.term)
    const total = results.pagination.total_objects
    const time = results.response_time
    let label
    let action
    if(total <= 0){
      action = "No Search Results"
      label = `keyword:[${keyword}]|response:${time}ms`
    }
    else{
      action = "Search Results"
      label = `keyword:[${keyword}]|results:${total}|response:${time}ms`
    }
    ga('send', {
      'hitType': 'event',
      'eventCategory': 'Search 2.0',
      'eventAction': action,
      'eventLabel': label
    })

    dataLayer.push({
      'event' : 'ua_event',
      'event_category' : 'Search 2.0',
      'event_action' : action,
      'event_label' : label
    })
  }

  trackSortFilterClick({source, v1, v2}){
    ga('send', {
      'hitType': 'event',
      'eventCategory': source, //'Home Page' || 'Search 2.0'
      'eventAction': "Sort",
      'eventLabel': `${v1}|${v2}`
    })

    dataLayer.push({
      'event' : 'ua_event',
      'event_category' : source,
      'event_action' : 'Sort',
      'event_label' : `${v1}|${v2}`
    })
  }

  trackBannerClick({pos}){
    ga('send', {
      'hitType': 'event',
      'eventCategory': 'Home Page',
      'eventAction': "Banner Clicks",
      'eventLabel': `position:${pos}`
    })

    dataLayer.push({
      'event' : 'ua_event',
      'event_category' : 'Home Page',
      'event_action' : 'Banner Clicks',
      'event_label' : `position:${pos}`
    })
  }

  trackLotClick({keyword, pos, lot, page, lot_count}){
    ga('send', {
      'hitType': 'event',
      'eventCategory': 'Search 2.0',
      'eventAction': "Search Results Position Clicks",
      'eventLabel': `keyword:[${keyword}]|lotid:${lot.id}|position:${pos}|page:${page}|pagesize:${lot_count}`
    })

    dataLayer.push({
      'event': 'ua_event',
      'event_category': 'Search 2.0',
      'event_action': "Search Results Position Clicks",
      'event_label': `keyword:[${keyword}]|lotid:${lot.id}|position:${pos}|page:${page}|pagesize:${lot_count}`
    })
  }

  trackLotClickV2({pos, row_type, row_title, row_rank, lot_count}){
    ga('send', {
      'hitType': 'event',
      'eventCategory': 'Home Page',
      'eventAction': "Lot Card Clicks",
      'eventLabel': `rowtype:[${row_type}]|rowtitle:[${row_title}]|rank:${row_rank}|position:${pos}|rowsize:${lot_count}`
    })

    dataLayer.push({
      'event': 'ua_event',
      'event_category': 'Home Page',
      'event_action': "Lot Card Clicks",
      'event_label': `rowtype:[${row_type}]|rowtitle:[${row_title}]|rank:${row_rank}|position:${pos}|rowsize:${lot_count}`
    })
  }

  trackLotClickUnfied({page_type, page_version}){
    if(!(page_type == 'HomePage' || page_type == 'ThemePage' || page_type == 'CategoryPage')){
      page_type = 'ListingPage'
    }
    ga('send', {
      'hitType': 'event',
      'eventCategory': 'Lot Card',
      'eventAction': "Click",
      'eventLabel': `pagetype:${page_type}|pagever:${page_version}`
    })

    dataLayer.push({
      'event': 'ua_event',
      'event_category': 'Lot Card',
      'event_action': "Click",
      'event_label': `pagetype:${page_type}|pagever:${page_version}`
    })
  }

  trackCategoryClick({slug}){
    ga('send', {
      'hitType': 'event',
      'eventCategory': 'Home Page',
      'eventAction': "Category Clicks",
      'eventLabel': `slug:[${slug}]`
    })

    dataLayer.push({
      'event' : 'ua_event',
      'event_category' : 'Home Page',
      'event_action' : 'Category Clicks',
      'event_label' : `slug:[${slug}]`
    })
  }

  trackSearchBarOpen({location, keyword}){
    let action
    if(location == 'nav'){
      action = "Activate Search"
    }
    else{
      action = "Activate Search Field"
    }
      ga('send', {
        'hitType': 'event',
        'eventCategory': 'Search 2.0',
        'eventAction': action,
        'eventLabel': `keyword:[${keyword}]`
      })

      dataLayer.push({
      'event' : 'ua_event',
      'event_category' : 'Search 2.0',
      'event_action' : action,
      'event_label' : `keyword:[${keyword}]`
    })
  }

  trackRecentSearchClicked({keyword, pos}){
    ga('send', {
      'hitType': 'event',
      'eventCategory': 'Search 2.0',
      'eventAction': "Click Recent Search",
      'eventLabel': `keyword:[${keyword}]|position:${pos}`
    })

    dataLayer.push({
      'event': 'ua_event',
      'event_category': 'Search 2.0',
      'event_action': "Click Recent Search",
      'event_label': `keyword:[${keyword}]|position:${pos}`
    })
  }

  trackRecentViewClicked({lot, pos}){
    ga('send', {
      'hitType': 'event',
      'eventCategory': 'Search 2.0',
      'eventAction': "Click Recently Viewed",
      'eventLabel': `lotid:${lot.id}|position:${pos}`
    })

    dataLayer.push({
      'event': 'ua_event',
      'event_category': 'Search 2.0',
      'event_action': "Click Recently Viewed",
      'event_label': `keyword:[${keyword}]|position:${pos}`
    })
  }

  trackSearchTipClicked(){
    ga('send', {
      'hitType': 'event',
      'eventCategory': 'Search 2.0',
      'eventAction': "View Search Tips"
    })

    dataLayer.push({
      'event': 'ua_event',
      'event_category': 'Search 2.0',
      'event_action': "View Search Tips"
    })
  }

  trackCloseSearch({keyword}){
    const q = this.sanitizeKeyword(keyword)
    ga('send', {
      'hitType': 'event',
      'eventCategory': 'Search 2.0',
      'eventAction': "Close Search",
      'eventLabel': `keyword:[${q}]`
    })

    dataLayer.push({
      'event': 'ua_event',
      'event_category': 'Search 2.0',
      'event_action': "Close Search",
      'event_label': `keyword:[${q}]`
    })
  }

  trackAutoSuggests({results, keyword}){
    const sanitizedKeyword = this.sanitizeKeyword(keyword)
    const total = results.results.dropdown_catalog_items.length
    const time = results.response_time
    
    let label
    let action
    if(total <= 0){
      action = "No Auto-suggest Results"
      label = `keyword:[${sanitizedKeyword}]|response:${time}ms`
    }
    else{
      action = "Auto-suggest Results"
      label = `keyword:[${sanitizedKeyword}]|results:${total}|response:${time}ms`
    }

    ga('send', {
      'hitType': 'event',
      'eventCategory': 'Search 2.0',
      'eventAction': action,
      'eventLabel': label
    })

    dataLayer.push({
      'event': 'ua_event',
      'eventCategory': 'Search 2.0',
      'eventAction': action,
      'eventLabel': label
    })
  }

  trackTopAutoSuggestClick({keyword, lot, pos}){
    ga('send', {
      'hitType': 'event',
      'eventCategory': 'Search 2.0',
      'eventAction': "Auto-suggest Top Three Clicks",
      'eventLabel': `keyword:[${keyword}]|lotid:${lot.id}|position:${pos}`
    })

    dataLayer.push({
      'event': 'ua_event',
      'event_category': 'Search 2.0',
      'event_action': "Auto-suggest Top Three Clicks",
      'event_label': `keyword:[${keyword}]|lotid:${lot.id}|position:${pos}`
    })
  }

  trackSaveSearchAlertClick({keyword}){
    ga('send', {
      'hitType': 'event',
      'eventCategory': 'Search 2.0',
      'eventAction': "Create Search Alert",
      'eventLabel': `keyword:[${keyword}]`
    })

    dataLayer.push({
      'event': 'ua_event',
      'event_category': 'Search 2.0',
      'event_action': "Create Search Alert",
      'event_label': `keyword:[${keyword}]`
    })
  }

  trackSimilarAutoSuggestClick({keyword, entity, pos}){
    ga('send', {
      'hitType': 'event',
      'eventCategory': 'Search 2.0',
      'eventAction': "Auto-suggest Similar Clicks",
      'eventLabel': `keyword:[${keyword}]|entity:${entity}|position:${pos}`
    })

    dataLayer.push({
      'event': 'ua_event',
      'event_category': 'Search 2.0',
      'event_action': "Auto-suggest Similar Clicks",
      'event_label': `keyword:[${keyword}]|entity:${entity}|position:${pos}`
    })
  }

  trackRelatedAutoSuggestClick({keyword, entity, pos}){
    ga('send', {
      'hitType': 'event',
      'eventCategory': 'Search 2.0',
      'eventAction': "Auto-suggest Related Clicks",
      'eventLabel': `keyword:[${keyword}]|entity:${entity}|position:${pos}`
    })

    dataLayer.push({
      'event': 'ua_event',
      'event_category': 'Search 2.0',
      'event_action': "Auto-suggest Related Clicks",
      'event_label': `keyword:[${keyword}]|entity:${entity}|position:${pos}`
    })
  }

  trackCharityAutoSuggestClick({keyword, org, pos}){
    ga('send', {
      'hitType': 'event',
      'eventCategory': 'Search 2.0',
      'eventAction': "Auto-suggest Charity Clicks",
      'eventLabel': `keyword:[${keyword}]|orgid:${org.id}|position:${pos}`
    })

    dataLayer.push({
      'event': 'ua_event',
      'event_category': 'Search 2.0',
      'event_action': "Auto-suggest Charity Clicks",
      'event_label': `keyword:[${keyword}]|orgid:${org.id}|position:${pos}`
    })
  }

  sanitizeKeyword(keyword){
    if(!keyword) keyword = ""
    let str = keyword.replace(/[^0-9a-zA-Z ']/gi, '')
    str = str.trim()
    return str
  }
}

export default Tracker