import React from 'react';
import IconBlackFriday from "../svg/IconBlackFriday";



class BannerBlackFriday extends React.Component {
  render() {
    const timeNow = Date.now();
    const weekendStart = new Date(2021, 10, 26, 0, 0, 0, 0).getTime()
    const weekendEnd = new Date(2021, 10, 29, 0, 0, 0, 0).getTime()
    const MondayEnd = new Date(2021, 10, 30, 0, 0, 0, 0).getTime()
    
    const is_weekend = (timeNow >= weekendStart && timeNow <= weekendEnd)
    const is_monday = (timeNow >= weekendEnd && timeNow <= MondayEnd)
    let banner_html = ""
    // console.log(timeNow);
    // console.log(weekendStart);
    if(is_weekend){
      banner_html = (
        <>
          <b> This Weekend Only:</b> <a href="/theme/buy-now">Buy-Now Store is 10% Off <span className="icon-wrapper"><i className="fal fa-angle-right"> </i></span></a>
        </>
      )
    } 
    else if(is_monday) {
      banner_html = (
        <>
          <b> Today Only:</b> <a href="/theme/buy-now">Buy-Now Store is 15% Off <span className="icon-wrapper"><i className="fal fa-angle-right"> </i></span></a>
        </>
      )
    }
    else{
      return (
        <div></div>
      )
    }

    return (
      <div className="banner__black-friday">
        <div className="wrapper">
          <IconBlackFriday />
          <p>
          {banner_html}
          </p>
        </div>
      </div>
    )
  }
}

export default BannerBlackFriday;