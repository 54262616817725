import React from "react"
import _ from "lodash"
import Tracker from '../services/Tracker'

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropDownState: null,
    };

    this.trackerService = new Tracker();

    this.onFilterClicked = this.onFilterClicked.bind(this);
    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.sortersHtml = this.sortersHtml.bind(this);
    this.adjustSelections = this.adjustSelections.bind(this);
    this.listenGlobalEvents = this.listenGlobalEvents.bind(this);

    this.adjustSelections();
    this.listenGlobalEvents();
  }

  listenGlobalEvents() {
    const self = this;
    document.addEventListener(
      "change-listing-filters-sortby",
      function (event) {
        const code = event.detail.sort_by;

        self.onFilterChanged({ code: code, userTriggered: false });

        // const sort_by = {
        //   on: false,
        //   query: {
        //     code: sort_by_code //'best_content:desc'
        //   }
        // }

        // let state = self.state
        // state.filters.sort_by = sort_by
        // self.setState(state)
      }
    );
  }

  onFilterClicked() {
    let model = _.cloneDeep(this.props.model);
    let filters = model.filters;
    filters.sort_by.on = !filters.sort_by.on;
    filters.departments.on = false;
    filters.details.on = false;
    filters.orgs.on = false;
    filters.themes.on = false;
    this.props.changeState(model);
    if (!this.state.dropDownState) {
      this.setState({ dropDownState: true });
    } else if (this.state.dropDownState) {
      this.setState({ dropDownState: false });
    }
  }

  onFilterKeyDown = (event) => {
    if(event.keyCode === 13 || event.keyCode === 40){
      let model = _.cloneDeep(this.props.model);
      let filters = model.filters;
      filters.sort_by.on = !filters.sort_by.on;
      filters.departments.on = false;
      filters.details.on = false;
      filters.orgs.on = false;
      filters.themes.on = false;
      this.props.changeState(model);
      if (!this.state.dropDownState) {
        this.setState({ dropDownState: true });
      } else if (this.state.dropDownState) {
        this.setState({ dropDownState: false });
      }
    }
  }

  onFilterChanged(event, { code, userTriggered = true }) {
    if(event.type === 'click' || event.keyCode === 13){
      let model = _.cloneDeep(this.props.model);
      let filters = model.filters;
      const oldCode = filters.sort_by.query.code;

      filters.sort_by.query.code = code;
      filters.sort_by.on = false;
      delete filters.ab;
      this.props.changeState(model);
      this.props.search({ m: model });

      if (userTriggered) {
        const source =
          location.pathname.toLowerCase() == "/search"
            ? "Search 2.0"
            : "Home Page";
        this.trackerService.trackSortFilterClick({
          source,
          v1: oldCode,
          v2: code,
        });
      }

      // filters showing up in different parts of page, use this hack to sync them
      var event = new CustomEvent("update-listing-filters", {
        detail: { filters: filters },
      });
      document.dispatchEvent(event);
    }
  }

  adjustSelections() {
    const model = this.props.model;
    const filters = model.filters;

    if (filters.buying_format.code == "Buy It Now") {
      this.selections = [
        ["close_date:asc", "Closing Next"],
        ["open_date:desc", "Newly Added"],
        ["estimated_price:desc", "Estimate (high to low)"],
        ["estimated_price:asc", "Estimate (low to high)"],
        ["current_price:desc", "Sale price (high to low)"],
        ["current_price:asc", "Sale price (low to high)"],
      ];
      if (!model.hide_qty_sort) {
        this.selections.push(["quantity:desc", "Quantity (high to low)"]);
        this.selections.push(["quantity:asc", "Quantity (low to high)"]);
      }
    } else if (filters.buying_format.code == "Auction") {
      this.selections = [
        ["close_date:asc", "Closing Next"],
        ["open_date:desc", "Newly Added"],
        ["estimated_price:desc", "Estimate (high to low)"],
        ["estimated_price:asc", "Estimate (low to high)"],
        ["bid_count:desc", "Bid Count (high to low)"],
        ["bid_count:asc", "Bid Count (low to high)"],
        ["current_price:desc", "Current price (high to low)"],
        ["current_price:asc", "Current price (low to high)"],
      ];
    } else {
      // all
      this.selections = [
        ["close_date:asc", "Closing Next"],
        ["open_date:desc", "Newly Added"],
        ["estimated_price:desc", "Estimate (high to low)"],
        ["estimated_price:asc", "Estimate (low to high)"],
        ["bid_count:desc", "Bid Count (high to low)"],
        ["bid_count:asc", "Bid Count (low to high)"],
        ["current_price:desc", "Current price (high to low)"],
        ["current_price:asc", "Current price (low to high)"],
      ];
      // if(window.current_page && window.current_page.version==2){
      // this.selections.unshift(['best_content:desc', 'Best Content'])
      // }
    }

    if (model.is_relevancy_filter_enabled == true) {
      this.selections.push(["_text_match:desc", "Relevancy"]);
    }

    // if(window.current_page && window.current_page.version==2){
    //   this.selections.unshift(['best_content:desc', 'Best Content'])
    // }
    this.selections.unshift(["best_content:desc", "Best Content"]);

    if (filters.custom_sort_enabled) {
      this.selections.unshift(["pos:asc", "Featured"]);
    }
  }

  sortersHtml() {
    this.adjustSelections();
    const model = this.props.model;
    const filters = model.filters;

    const namePrice = "Current Price";

    let options = this.selections;

    let listHtml = [];
    for (const i of options) {
      listHtml.push(
        <div
          value={i[1]}
          className='list-item'
          key={i[0]}
          tabIndex='0'
          role='filterOptions'
          onClick={(event) => {
            this.onFilterChanged(event,{ code: i[0] });
          }}
          onKeyDown={(event) => {this.onFilterChanged(event,{ code: i[0] })}}
        >
          {i[1]}
        </div>
      );
    }

    let selectedOption = options.filter((i) => {
      return i[0] == filters.sort_by.query.code;
    })[0];
    selectedOption = selectedOption || [];
    let selectedName = selectedOption[1] || options[0][1];

    let html = (
      <div>
        <div className='filter-title' aria-roledescription='drop down title'>
          Sort By
        </div>
        <div
          className='selected'
          onClick={this.onFilterClicked}
          onKeyDown={this.onFilterKeyDown}
          role='current selected filter'
          tabIndex='0'
          aria-expanded={this.state.dropDownState}
        >
          {selectedName}
          <i
            className={`ico-down fal fa-chevron-${
              filters.sort_by.on ? "up" : "down"
            }`}
          ></i>
        </div>
        <div
          className={`list ${filters.sort_by.on ? "" : "hidden"}`}
          role='filter item group'
        >
          {listHtml}
        </div>
      </div>
    );

    return html;
  }

  render() {
    const self = this;
    const model = self.props.model;
    const filters = model.filters;
    const sorters = this.sortersHtml();
    let html = <div>{sorters}</div>;

    return html;
  }
}

export default Page
