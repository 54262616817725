import React from "react";
import Search from "../services/Search";
import ReactPaginate from "react-paginate";
import ItemCard from "./ItemCard/ItemCard";

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.searchService = new Search();

    this.onPageChange = this.onPageChange.bind(this);
    this.paginationHtml = this.paginationHtml.bind(this);
    this.lotListHtml = this.lotListHtml.bind(this);
  }

  onPageChange(page) {
    const model = this.props.model;
    model.filters.pagination.page = page.selected + 1;
    this.props.changeState(model);
    this.props.search({}).then(() => {
      $("html,body").animate(
        {
          scrollTop: $(".lot-filters").offset().top,
        },
        "slow"
      );
    });
  }

  notFoundDueToStrictedFiltersHtml() {
    let html = (
      <div className="filters-too-strict align-middle">
        <div className="empty-title">No or too few results?</div>
        <p>
          <b>Filtering Tips</b>
        </p>
        <ul>
          <li>
            Use different filters — click "Show All Search Filters" and remove
            some
          </li>
          <li>
            Check number counts (i.e. "(10)") — other filter values may have
            more results
          </li>
          <li>Adjust price ranges</li>
          <li>
            Save an alert and check back later! Our inventory changes daily.
          </li>
        </ul>
      </div>
    );
    return html;
  }

  lotListHtml() {
    const self = this;
    const model = self.props.model;
    const results = model.results;
    const lots = results.results.catalog_items;
    const keyword = results.term;

    const pagination = self.paginationHtml();
    const page = results.pagination.page;
    let lotList = [];

    const lot_count = lots.length;
    for (let i = 0; i < lot_count; i++) {
      const pos = i + 1;
      const lot = lots[i];
      const meta = { lot_count, pos, page, keyword };
      let lotCardHtml = <ItemCard item={lot} meta={meta} key={i} />;

      lotList.push(lotCardHtml);
    }

    let html = (
      <div>
        <div className="lot-cards grid-x grid-margin-x">{lotList}</div>
        <div className="lot-pagination text-center">{pagination}</div>
      </div>
    );

    return html;
  }

  paginationHtml() {
    const self = this;
    const model = self.props.model;
    const filters = model.filters;
    const pagination = filters.pagination || {};
    let page_count = 0;
    try {
      page_count = model.results.pagination.total_pages;
    } catch (err) {}

    if (page_count == 0) {
      return "";
    }

    const prevLabel = <i className="fal fa-chevron-left"></i>;
    const nextLabel = <i className="fal fa-chevron-right"></i>;
    const page = pagination.page - 1;

    let html = (
      <ReactPaginate
        onPageChange={self.onPageChange}
        pageCount={page_count}
        forcePage={page}
        previousLabel={prevLabel}
        nextLabel={nextLabel}
      />
    );
    return html;
  }

  render() {
    const self = this;
    const model = self.props.model;

    let lotListHtml = "";

    let found = 0;
    const hasFilters = this.searchService.hasStrictFilters({
      filters: model.filters,
    });
    try {
      found = model.results.pagination.total_objects;
    } catch (err) {
      found = 0;
    }

    let lotsHtml = "";
    if (hasFilters && found == 0) {
      lotListHtml = self.notFoundDueToStrictedFiltersHtml();
    } else {
      lotListHtml = self.lotListHtml();
    }

    let html = <React.Fragment>{lotListHtml}</React.Fragment>;

    return html;
  }
}

export default Page;
