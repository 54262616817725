class Lot {
  saveWatch({lot_id, watch}){
    const url = `/catalog_items/${lot_id}/toggle_watch`
    const data = {
      id: lot_id,
      watching: watch
    }
    return $.post(url, data)
  }
}

export default Lot