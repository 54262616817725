import React from "react"
import BaseComponent from "../__application/BaseComponent"

class Page extends BaseComponent {

  constructor(props) {
    super(props)
    let profile = ""
    let profile_lock = "icon-lock"
    let billing = ""
    let shipping = ""
    let shipping_lock = "icon-lock"
    let done = ""

    if(this.props.step == 'profile'){
      profile = "active"
    }
    else if(this.props.step == 'billing'){
      profile = "unlocked"
      profile_lock = "icon-unlock"
      billing = "active"
    }
    else if(this.props.step == 'shipping'){
      profile = "unlocked"
      profile_lock = "icon-unlock"
      billing = "unlocked"
      shipping = "active"
    }
    else if(this.props.step == 'done'){
      profile = "unlocked"
      profile_lock = "icon-unlock"
      billing = "unlocked"
      shipping = "unlocked"
      shipping_lock = "icon-unlock"
      done = "active"
    }
    else{

    }

    this.state = {
      classNames: {
        profile: profile,
        profile_lock: profile_lock,
        billing: billing,
        shipping: shipping,
        shipping_lock: shipping_lock,
        done: done
      }
    }


  }

  render() {
    return (
      <div className="reg2-status-nav">
        <div className="unlock-block">
          <div className="unlock-title">Your account Access</div>
          <div className="unlock-text">As you create your account and complete your profile, you'll unlock access to all of the great features of Charitybuzz.</div>
        </div>
        <div className="steps-block">
          <div className="steps-col">
            <div className="step-item">
              <div className="step-text">Basic Info</div>
              <div className="step-div">
                <div className={`div-line first-line ${this.state.classNames.profile}`}></div>
                <div className={`div-circle ${this.state.classNames.profile}`}></div>
              </div>
            </div>
            <div className="step-item">
              <div className="step-text">Billing Info</div>
              <div className="step-div">
                <div className={`div-line ${this.state.classNames.billing}`}></div>
                <div className={`div-circle ${this.state.classNames.billing}`}></div>
              </div>
            </div>
            <div className="step-item">
              <div className="step-text">Shipping Info</div>
              <div className="step-div">
                <div className={`div-line ${this.state.classNames.shipping}`}></div>
                <div className={`div-circle ${this.state.classNames.shipping}`}></div>
              </div>
            </div>
          </div>
          <div className="features-col">
            <div className="feature-items">
              <div className={`feature-item ${this.state.classNames.profile}`}><i className={`icon ${this.state.classNames.profile_lock}`}></i> &nbsp; Your Watchlist</div>
              <div className={`feature-item ${this.state.classNames.profile}`}><i className={`icon ${this.state.classNames.profile_lock}`}></i> &nbsp; Recommendations</div>
              <div className={`feature-item ${this.state.classNames.profile}`}><i className={`icon ${this.state.classNames.profile_lock}`}></i> &nbsp; Notifications</div>
            </div>
            <div className="feature-items"></div>
            <div className="feature-items">
            <div className={`feature-item ${this.state.classNames.shipping}`}><i className={`icon ${this.state.classNames.shipping_lock}`}></i> &nbsp; Bid access</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Page
