import React from "react";
import ItemCard from "./ItemCard/ItemCard";
import Tracker from "../services/Tracker";

class Page extends React.Component {
  constructor(props) {
    super(props);

    this.scrollLeft = this.scrollLeft.bind(this);
    this.scrollRight = this.scrollRight.bind(this);
    this.onViewAllClicked = this.onViewAllClicked.bind(this);

    this.rowRef = React.createRef();

    this.trackerService = new Tracker();
  }

  componentDidMount() {
    this.initScrollable();
  }

  scrollLeft() {
    const elem = this.rowRef.current;
    const dis = elem.offsetWidth - 100;
    const currentScroll = elem.scrollLeft;
    elem.scrollLeft = currentScroll - dis;
  }

  scrollRight() {
    const elem = this.rowRef.current;
    const dis = elem.offsetWidth - 100;
    const currentScroll = elem.scrollLeft;
    elem.scrollLeft = currentScroll + dis;
  }

  initScrollable() {
    if (!this.rowRef.current) {
      return;
    }
    // const slider = document.querySelector('.items');
    const slider = this.rowRef.current;
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener("mousedown", (e) => {
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener("mouseleave", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mouseup", () => {
      isDown = false;
      slider.classList.remove("active");
    });
    slider.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
      console.log(walk);
    });
  }

  onViewAllClicked({ event, pos, row_type, row_title, row_rank, lot_count }) {
    this.trackerService.trackLotClickV2({
      pos,
      row_type,
      row_title,
      row_rank,
      lot_count,
    });
  }

  entityLotsHtml() {
    const self = this;
    let lotsList = [];
    const entity = self.props.entity;
    const lots = entity.catalog_items;
    const lot_count = entity.pagination.total_objects;
    const row_title = entity.title;
    const row_type = entity.row_type;
    const row_rank = entity.rank;
    const v = "homepage2";
    for (let i = 0; i < lots.length; i++) {
      const pos = i + 1;
      const lot = lots[i];
      const meta = { pos, row_type, row_title, row_rank, lot_count, v };
      lotsList.push(<ItemCard item={lot} meta={meta} key={lot.id} />);
    }

    // end treatment
    const entityUrl = self.urlForEntity(entity);
    let viewAllBtn = "";
    if (entityUrl != null && lot_count > 10) {
      viewAllBtn = (
        <div
          key={`entity-${entity.id}-view-all-btn`}
          onClick={(e) => {
            self.onViewAllClicked({
              event: e,
              pos: "all",
              row_type,
              row_title,
              row_rank,
              lot_count,
            });
          }}
        >
          <div className="entity-btn view-all">
            <div className="make-center">
              <a href={entityUrl}>
                <div>
                  <i className="fas fa-angle-right"></i>
                </div>
                <div>
                  <b>View All</b>
                </div>
                <div>{entity.title}</div>
              </a>
            </div>
          </div>
        </div>
      );
    }
    lotsList.push(viewAllBtn);

    // end treatment, todo: show for v2 redesign
    if (false && lot_count < 3) {
      const cusProfileBtn = (
        <div key={`entity-${entity.id}-cuz-profiel-btn`}>
          <div className="entity-btn cus-profile">
            <div className="make-center">
              <a href="#">
                <div>
                  <i className="fal fa-user-edit"></i>
                </div>
                <div>
                  <b>Customize</b>
                </div>
                <div>
                  <b>Profile</b>
                </div>
              </a>
            </div>
          </div>
        </div>
      );
      lotsList.push(cusProfileBtn);
    }

    return lotsList;
  }

  urlForEntity(entity) {
    let url = null;
    // row_type: [entity, charity, theme, category, top_bin_lots, custom_row, featured_lot]
    if (entity.row_type == "charity") {
      url = `/support/${entity.id}`;
    } else if (entity.row_type == "category") {
      url = `/categories/${entity.slug}/catalog_items?hide=carousel`;
    } else if (entity.row_type == "entity") {
      url = `/collection/${entity.entity_type}-${entity.entity_name}-${entity.id}`;
    } else if (entity.row_type == "theme") {
      url = `/theme/${entity.slug}`;
    } else {
    }
    return url;
  }

  render() {
    const self = this;
    const entity = self.props.entity;
    const lot_count = entity.pagination.total_objects;
    const row_title = entity.title;
    const row_type = entity.row_type;
    const row_rank = entity.rank;

    const lotsHtml = self.entityLotsHtml();
    const lotCount = entity.pagination.total_objects;

    if (lotCount == 0) {
      return "";
    }

    const url = self.urlForEntity(entity);
    let viewAllBtnHtml = "";
    if (url != null && lotCount > 10) {
      viewAllBtnHtml = (
        <span
          className="btn-view-all"
          onClick={(e) => {
            self.onViewAllClicked({
              event: e,
              pos: "all",
              row_type,
              row_title,
              row_rank,
              lot_count,
            });
          }}
        >
          <a href={url}>View All ({lotCount})</a>
        </span>
      );
    }

    let leftRightBtn = "";
    if (lotCount > 4) {
      leftRightBtn = (
        <div>
          <span className="btn-arrow show-for-medium">
            <i
              className="fal fa-chevron-circle-left"
              onClick={self.scrollLeft}
            ></i>
          </span>
          <span className="btn-arrow show-for-medium">
            <i
              className="fal fa-chevron-circle-right"
              onClick={self.scrollRight}
            ></i>
          </span>
        </div>
      );
    }
    let html = (
      <div className="entity-row">
        <div className="head-bar grid-x">
          <div className="entity-title cell small-6 small-medium-4">
            {entity.parent ? entity.parent.name + " > " : ""} {entity.title}
          </div>
          <div className="row-action cell small-6 small-medium-8 align-right">
            {viewAllBtnHtml}

            {leftRightBtn}
          </div>
        </div>
        <div className="entity-lots lot-cards-horizontal">
          <div className="lot-cards-horizontal-wrapper" ref={self.rowRef}>
            {lotsHtml}
          </div>
        </div>
      </div>
    );

    return html;
  }
}

export default Page;
