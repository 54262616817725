import React from "react"

class Page extends React.Component {
  constructor(props) {
    super(props)

    this.handleInputClicked = this.handleInputClicked.bind(this)
    this.handleInputChanged = this.handleInputChanged.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
  }

  handleInputClicked(){
    let model = this.props.model
    let filters = model.filters
    filters.themes.on = !filters.themes.on
    filters.sort_by.on = false
    filters.departments.on = false
    filters.details.on = false
    filters.orgs.on = false
    if(!filters.themes.on){
      model.filters.themes.query = ""
    }
    this.props.changeState(model)
  }

  handleInputChanged(event){
    let v = event.currentTarget.value.trim()
    let model = this.props.model
    model.filters.themes.query = v
    if(!_.isEmpty(v)){
      model.filters.themes.on = true
    }
    this.props.changeState(model)
  }

  handleSelect({event, item, locked}){
    if(locked){
      return
    }
    let model = this.props.model
    let selected = model.filters.themes.selected
    selected[item] = !selected[item]
    model.filters.pagination.page = 1
    this.props.changeState(model)
    this.props.search({})
  }

  render(){
    const self = this
    let list = []
    const model = self.props.model
    const filters = model.filters
    const results = model.results.results

    if(results){
      let groups = results.groups
      if(groups){
        let themes = groups.themes
        if(themes){
          for(const name in themes){
            if(name.toLowerCase().includes(filters.themes.query.toLowerCase())){
              const count = themes[name]
              let itemHtml = (
                <div key={name}>
                  <div className={`ic-item grid-x grid-padding-x ${filters.themes.locked[name] ? 'disabled' : ''}`} onClick={(e)=>{self.handleSelect({event: e, item: name, locked:filters.themes.locked[name] })}}  disabled={filters.themes.locked[name] ? true : false}>
                    <div className="cell small-2 text-right">
                      <i className={`ic-check fal ${filters.themes.selected[name] ? 'fa-check-square' : 'fa-square'}`}></i>
                    </div>
                    <div className="cell small-8">
                      {name}
                    </div>
                    <div className="cell small-2">
                      ({count})
                    </div>
                  </div>
                </div>
              )
              list.push(itemHtml)
            }
          }
        }
      }
    }

    let html = (
      <div>
        <div className="filter-title">
          Collections
        </div>
        <div className="search-sel-wrapper">
          <div className="input-list-wrapper" onClick={self.handleInputClicked}>
            <input className="input-list" type="text" placeholder="Collection"
              value={filters.themes.query} onChange={self.handleInputChanged} />
            <i className="ico-search fal fa-search"></i>
            <i className={`ico-down fal fa-chevron-${filters.themes.on ? 'up' : 'down'}`}></i>
          </div>
          <div className={`${filters.themes.on ? 'shown' : 'hidden'}`}>
            <div className="indent-list">
              {list}
            </div>
            <div className="list-action text-center hidden">
              Show more themes
            </div>
          </div>
        </div>
      </div>
    )
    return html
  }
}

export default Page
