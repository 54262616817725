import React from "react"
import BaseComponent from "../__application/BaseComponent"
import CardList from "./CardList"
import ShippingList from "./ShippingList"

class Page extends BaseComponent {
  constructor(props) {
    super(props)
    this.state = {
      cards: [],
      addresses: [],
      selected: {
        card: {
          id: -1
        },
        address: {
          id: -1
        }
      },
      show_shipping: false
    }

    this.fetchCards = this.fetchCards.bind(this)
    this.fetchAddresses = this.fetchAddresses.bind(this)
    this.changeState = this.changeState.bind(this)
  }

  componentDidMount(){
    this.fetchCards()
    this.fetchAddresses()

    if(document) document.title = "Charitybuzz: Checkout"
  }

  changeState(newState){
    this.setState(newState)
  }

  fetchCards(){
    const self = this
    return axios.get(`/account/credit_cards.json`).then(res => {
      const data = res.data
      self.state.cards = data
      for(let c of data){
        if(c.active){
          self.state.selected.card = c
        }
      }
      self.setState(self.state)
    })
  }

  fetchAddresses(){
    const self = this
    return axios.get(`/account/addresses.json`).then(res => {
      const data = res.data
      self.state.addresses = data
      for(let c of data){
        if(c.active){
          self.state.selected.address = c
          self.state.show_shipping = true
          window.shippingAddressId = c.id
        }
      }
      self.setState(self.state)

      // window.BINRecalculate()
    })
  }

  invertShowShipping = () => this.setState({show_shipping: !this.state.show_shipping})

  render() {
    const billingSection = (
      <div className="cart-step step2">
        <h2 className="step-group">
          <span className="step-number">2</span>
          <span className="step-name">Payment Method</span>
        </h2>
        <div className="step-opt">
          <CardList model={this.state} changeState={this.changeState} fetchCards={this.fetchCards} fetchAddresses={this.fetchAddresses} geo_data={this.props.geo_data} invertShowShipping={this.invertShowShipping} />
        </div>
      </div>
    )

    const shippingSection =  (
      <div className="cart-step step3">
        <h2 className="step-group">
          <span className="step-number">3</span>
          <span className="step-name">Shipping Method</span>
        </h2>
         <div className="step-opt">
           <ShippingList model={this.state} changeState={this.changeState} fetchAddresses={this.fetchAddresses} geo_data={this.props.geo_data} />
         </div>
       </div>
     )
    return (
        <React.Fragment>
          {billingSection}
          <hr />
          {this.state.show_shipping ? shippingSection : ""}
        </React.Fragment>
      )
  }
}

export default Page
