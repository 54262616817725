import React from "react";
import Lot from "../../services/Lot";
import Tracker from "../../services/Tracker";
import { motion } from "framer-motion";
import IconLive from "../../../svg/IconLive";
import { DiscountTag } from "./components/DiscountTag/DiscountTag";
import { useItemCard } from "./ItemCard.controller";
import styles from "./ItemCard.module.scss";
import classNames from "classnames";
import {getHtmlTextValue} from "../../../../shared/utils/getHtmlTextValue";

const buyNowCTA = GlobalConfig.buyNowCTA;

class ItemCard extends React.Component {
  constructor(props) {
    super(props);
    this.trackerService = new Tracker();
    this.lotService = new Lot();

    this.onLotClicked = this.onLotClicked.bind(this);
    this.onWatchClicked = this.onWatchClicked.bind(this);

    const lot = this.props.item;
    const watched = window.lotIsWatched(lot.id);
    this.state = {
      watched,
    };
  }

  componentDidMount() {
    // if(this.props.meta.pos == 1){
    //   const lotCardShown = amplify.store('introjs-lotcard')
    //   if(!lotCardShown){
    //     var waypoint = new Waypoint({
    //       element: document.getElementsByClassName('card')[0],
    //       handler: function(direction) {
    //         introJs().setOptions({
    //           tooltipClass: 'customTooltip',
    //           highlightClass: 'customHelperLayer',
    //           scrollToElement: false
    //         }).start()
    //         amplify.store('introjs-lotcard', true);
    //         waypoint.destroy()
    //       },
    //       offset: 'bottom-in-view'
    //     })
    //   }
    // }
  }

  onLotClicked({ event, lot, meta, url }) {
    const isFavClicked =
      $(event.target).parents(".add-to-watchlist").length > 0;
    if (isFavClicked) {
      event.preventDefault();
      return;
    }

    const v = meta.v;
    if (v == "homepage2") {
      const { pos, row_type, row_title, row_rank, lot_count } = meta;
      this.trackerService.trackLotClickV2({
        pos,
        row_type,
        row_title,
        row_rank,
        lot_count,
      });

      this.trackerService.trackLotClickUnfied({
        page_type: 'HomePage', 
        page_version: 'v2'
      })
    } else {
      const { page, pos, lot_count, keyword } = meta;
      this.trackerService.trackLotClick({ keyword, pos, lot, page, lot_count });
      this.trackerService.trackLotClickUnfied({
        page_type: keyword, 
        page_version: 'v1'
      })
    }
    // location.href = url
  }

  onWatchClicked({ event, lot }) {
    const self = this;
    if (window.CharityBuzz.is_logged_in) {
      const id = lot.id;
      const watched = this.state.watched;
      const watching = !watched;

      this.lotService
        .saveWatch({
          lot_id: id,
          watch: watching,
        })
        .done((data) => {
          if (data.watching) {
            self.setState({ watched: true });
            // const item = {
            //   item_id: id,
            //   attributes: {
            //     item_id: id,
            //     watching: true
            //   }
            // }
            window.addWatching(data);
            window.ga("send", "event", "watch", id);
            dataLayer.push({
              'event': 'ua_event',
              'event_category': 'watch',
              'event_action': id
            });
          } else {
            self.setState({ watched: false });
            window.removeWatching(id);
            window.ga("send", "event", "unwatch", id);
            dataLayer.push({
              'event': 'ua_event',
              'event_category': 'unwatch',
              'event_action': id
            });
          }

          // check notification settings
          const checkNotifSettings = new CustomEvent(
            "check_notification_settings",
            { detail: { event_action: "watch", last_action_id: data.id } }
          );
          document.dispatchEvent(checkNotifSettings);
        });
    } else {
      window.sendLoginEventToGa("Watch", lot.id);
      window.showLoginPopup();
    }
  }

  getUrlForLot({ lot }) {
    // let category = null
    // const pathName = location.pathname.toLocaleLowerCase()
    // if(pathName.startsWith("/categories/")){
    //   category = p.split("/")[2]
    // }

    const category = (CharityBuzz || {}).currentCategory;

    let url = `/catalog_items/${lot.pretty_id || lot.id}`;
    if (category) {
      if (category == "0") {
        // category = 'view-all'
      } else {
        url = `/categories/${category}${url}`;
      }
    }

    return url;
  }

  render() {
    const self = this;
    const lot = this.props.item;
    const meta = this.props.meta;

    const title = { __html: htmlDecode(lot.title) };
    const current_price = accounting.formatMoney(lot.current_price);
    const est_price = accounting.formatMoney(lot.estimated_price);
    const is_bin = lot.buying_format == "Buy It Now";
    //Need logic to test if a lot is CB Live
    const url = self.getUrlForLot({ lot });
    const buying_format = is_bin ? buyNowCTA : "Auction";
    const watched = this.state.watched;
    const is_hidden_gem = lot.status && lot.status.includes("Hidden Gems");

    const is_closing_now = lot.status && lot.status.includes("Closing Now");
    const is_closing_today = lot.status && lot.status.includes("Closing Today");
    const is_closing_soon = lot.status && lot.status.includes("Closing Soon");
    const is_flash_sale = lot.status && lot.status.includes("Flash Sale");

    let on_sale_details = lot.on_sale_details
    if(!on_sale_details){
      if(lot.discount_type && lot.discount_type == 'on-sale'){
        on_sale_details = {}
        on_sale_details['original_price'] = lot.discount_original_price
        on_sale_details['discount_percent'] = lot.discount_percent
        on_sale_details['discount_amount'] = lot.discount_amount
      }
    }
  

    const { renderDiscountTag,
            renderOnSaleTag,
            discountAmount,
            discountPercent,
            originalPrice } = useItemCard({
      is_bin,
      current_price: lot.current_price,
      est_price: lot.estimated_price,
      on_sale_details: on_sale_details
    });

    let closing_badge = "";
    if (is_bin){
      if (is_flash_sale) {
        closing_badge = "Flash Sale";
      }
    }
    else{
      if (is_flash_sale) {
        closing_badge = "Flash Sale";
      } else if (is_closing_now) {
        closing_badge = "Closing Now";
      } else if (is_closing_today) {
        closing_badge = "Closing Today";
      } else if (is_closing_soon) {
        closing_badge = "Closing Soon";
      }
    }

    let buying_format_html = "";
    if (is_bin) {
      buying_format_html = (
        <span className="ico-circle c-bin">
          <i className="fal fa-tag"></i>
        </span>
      );
    }
    // Need to add the logic for this and the label text
    // Also consider if we want to check if a CB Live is currently in progress if we are going to display it when it's not yet active
    else {
      if (lot.is_live) {
        buying_format_html = (
          <span className="ico-circle c-live">
            <IconLive />
          </span>
        );
      } else {
        buying_format_html = (
          <span className="ico-circle c-bid">
            <i className="fal fa-gavel"></i>
          </span>
        );
      }
    }

    let shipping_html = "";
    if (false) {
      shipping_html = (
        <div className="meta-group__meta-data">
          <i className="fal fa-truck fa-fw"></i> Premium shipping
        </div>
      );
    }

    let dates_html = "";
    if (false) {
      dates_html = (
        <div className="meta-group__meta-data">
          <i className="fal fa-calendar-alt fa-fw"></i> Multiple dates available
        </div>
      );
    }

    let watchers_html = "";
    if (lot.watch_count > 0) {
      watchers_html = (
        <span className="tertiary-container__watchers">
          <i className="fal fa-users fa-fw"></i> &nbsp;
          {lot.watch_count} watchers
        </span>
      );
    }

    let stats_html = "";
    if (is_bin) {
      let qtyHtml = "";
      if (lot.quantity == 0) {
        qtyHtml = "Sold Out";
      } else {
        if (lot.quantity && lot.quantity <= 10) {
          qtyHtml = lot.quantity;
        } else {
          qtyHtml = "More than 10";
        }
      }

      stats_html = (
        <div
          data-title={meta.showIntro ? "ℹ️ More details! ℹ️" : null}
          data-intro={
            meta.showIntro
              ? "We've added more at-a-glance info about each item, including current bid amount or price, and value, so you have what you need instantly to take the next step."
              : null
          }
          className="card__stats-group"
        >
          <div className="stats-group__stat">&nbsp;</div>
          <div className="stats-group__stat">
            <b>Remaining:</b> {qtyHtml}
          </div>
          <div className="stats-group__stat">
            <span className={styles.label}>{renderOnSaleTag ? 'Sale' : 'Our'} Price:</span>
            <div
              className={classNames("stat__price", {
                [styles.binPriceCentered]: renderDiscountTag || renderOnSaleTag,
              })}
            >
              {current_price}

              {renderDiscountTag && (
                <span className={styles.discountPercentage}>
                  -{discountPercent}%
                </span>
              )}
            </div>
          </div>
          {renderDiscountTag && (
            <div className={styles.marketPrice}>
              <b>Market Price:</b>{" "}
              <span className={styles.lineThrough}>{est_price}</span>
            </div>
          )}
          {renderOnSaleTag && (
            <div className={styles.marketPrice}>
              <b>{buyNowCTA} Price:</b>{" "}
              <span className={styles.lineThrough}>{accounting.formatMoney(originalPrice)}</span>
              <span className={styles.discountPercentage}>
                -{discountPercent}%
              </span>
            </div>
          )}
        </div>
      );
    } else {
      stats_html = (
        <div
          data-title={meta.showIntro ? "ℹ️ More details! ℹ️" : null}
          data-intro={
            meta.showIntro
              ? "We've added more at-a-glance info about each item, including current bid amount or price, and value, so you have what you need instantly to take the next step."
              : null
          }
          className="stats-wrapper"
          className="card__stats-group"
        >
          <div className="stats-group__stat">
            <b>Bids:</b> {lot.bid_count}
          </div>
          <div className="stats-group__stat">
            <b>Watchers:</b> {lot.watch_count}
          </div>
          {!renderDiscountTag && (
            <div className="stats-group__stat">
              <b>Estimate:</b> {est_price}
            </div>
          )}
          <div className="stats-group__stat">
            <span className={styles.label}>Current Bid:</span>
            <div className="stat__price">{current_price}</div>
          </div>
        </div>
      );
    }

    let gem_html = "";
    if (is_hidden_gem) {
      gem_html = (
        <span
          title="Hidden Gem"
          alt="Hidden Gem"
          className="optional-group__icon gem"
        >
          <i className="fal fa-gem fa-fw"></i>
        </span>
      );
    }

    const translateCard = {
      rest: {
        x: 0,
        y: 0,
      },
      hover: {
        x: 0,
        y: -4,
        transition: {
          duration: 0.4,
          delay: 0,
          delayChildren: 0,
          type: "spring",
          bounce: 0.25,
        },
      },
    };

    const scaleImage = {
      rest: { scale: 1 },
      hover: {
        scale: 1.1,
        transition: {
          duration: 0.4,
          type: "spring",
        },
      },
    };

    const lotCardHtml = (
      <motion.a
        data-title={
          meta.showIntro
            ? "👋🏻 Hi there! 👋🏻  We've made some improvements to your shopping experience."
            : null
        }
        data-intro={
          meta.showIntro
            ? "We'll quickly walk you through the updates to make sure you get the most out of your shopping experience."
            : null
        }
        aria-label={getHtmlTextValue(lot.title)}
        className="card small-12 medium-6 cell"
        onClick={(event) => self.onLotClicked({ event, lot, meta, url })}
        href={url}
        initial="rest"
        whileHover="hover"
        variants={translateCard}
      >
        <motion.figure className="card__image">
          <motion.img src={lot.lot_image} alt="" variants={scaleImage} />
          <motion.div
            className="add-to-watchlist"
            data-title={meta.showIntro ? "❤️ A new way to watch! ❤️" : null}
            data-intro={
              meta.showIntro
                ? "We've made watching any item a lot simpler. Just click or tap the heart icon and you're all set! We'll notify you about this item and any similar items we think you might like. You can always update your notification preferences in your account settings."
                : null
            }
          >
            <button
                onClick={(event) => self.onWatchClicked({ event, lot })}>
            <span
              className="ico-circle"
              ico_action="fav"
            >
              {watched ? (
                <i className="fas fa-heart watched" ico_action="fav"></i>
              ) : (
                <motion.i className="fal fa-heart" ico_action="fav"></motion.i>
              )}
            </span>
            </button>
          </motion.div>
        </motion.figure>
        <motion.div className="card__info-container">
          <div
            data-title={meta.showIntro ? "🔎 Clearer labels! 🔍" : null}
            data-intro={
              meta.showIntro
                ? "As our marketplace grows, we will help indicate the types of items we're offering. These icons show whether you're looking at an Auction or a Buy-Now item that you can purchase instantly."
                : null
            }
            className="info-container__label"
          >
            {buying_format_html}
            <span className="label__main"> {buying_format} </span>

            {closing_badge == "Flash Sale" ? (
              <span className="label__extra flash-sale">
                <i className="fal fa-bolt fa-fw"></i> Flash Sale!
              </span>
            ) : (
              ""
            )}

            {closing_badge == "Closing Now" ||
            closing_badge == "Closing Today" ||
            closing_badge == "Closing Soon" ? (
              <span className="label__extra closing-soon">
                <i className="fal fa-clock"></i> {closing_badge}!
              </span>
            ) : (
              ""
            )}
          </div>
          <h3
            data-title={meta.showIntro ? "✏️ Better titles! ✏️" : null}
            data-intro={
              meta.showIntro
                ? "Your time is valuable, so we are working with our partners to improve item titles to make your life easier while browsing."
                : null
            }
            className="card__title"
          >
            <span dangerouslySetInnerHTML={title}></span>
          </h3>
          <div className="card__meta-group">
            {shipping_html}
            {dates_html}
          </div>
          {renderDiscountTag && <DiscountTag tag_type="discount-tag" />}
          {renderOnSaleTag && <DiscountTag tag_type="on-sale-tag" />}
          {stats_html}
          <div
            data-title={meta.showIntro ? "🎉 Last one! 🎉" : null}
            data-intro={
              meta.showIntro
                ? "In this final section, you'll see how many people are watching this auction, you'll be alerted to any rewards you have to redeem, and we'll highlight if an item might be a \"hidden gem.\" We'll be launching even more exciting updates in the coming months that we hope you love! Thanks for being a Charitybuzz customer and helping to generate the most impact for cause."
                : null
            }
            className="card__tertiary-container"
          >
            {/*{watchers_html}*/}
            <span className="tertiary-container__optional-group">
              {gem_html}
              {/*
                <span
                  title="Polular Item"
                  alt="Popular Item"
                  className="optional-group__icon fire"
                >
                  <i className="fal fa-fire fa-fw"></i>
                </span>
                <span
                  title="Reward Available"
                  alt="Reward Available"
                  className="optional-group__icon gift"
                  >
                    <i className="fal fa-gift fa-fw"></i>
                </span>
              */}
            </span>
          </div>
        </motion.div>
      </motion.a>
    );

    return lotCardHtml;
  }
}

export default ItemCard;
