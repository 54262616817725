import React from "react";
import ItemCard from "./ItemCard/ItemCard";

class Page extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const lot_count = 1;
    const pos = 1;
    const v = "homepage2";
    const entity = this.props.entity;
    const row_title = entity.title;
    const row_rank = entity.rank;
    const row_type = entity.row_type;
    const lot = this.props.lot;
    const bidText = lot.buying_format == "Auction" ? "Bid Now >" : "Buy It Now";
    const rowCls = lot.buying_format == "Auction" ? "green" : "blue";
    const meta = { pos, row_type, row_title, row_rank, lot_count, v };

    let html = (
      <div className={`staff-pick-row ${rowCls}`}>
        <div className={`sp-header show-for-small-only`}>
          <div className={`sp-bar ${rowCls}`}></div>
          <div className="sp-title">
            <b>{entity.title}:</b> {lot.buying_format}
          </div>
          <div className="sp-desc">{entity.description}</div>
          <div className="sp-button">
            <a href={`/catalog_items/${lot.pretty_id}`}>{bidText}</a>
          </div>
        </div>
        <div
          className={`sp-header grid-x grid-padding-x ${rowCls} show-for-medium align-middle`}
        >
          <div className="cell small-4">
            <div className={`sp-bar ${rowCls}`}></div>
            <div className="sp-title">
              <b>{entity.title}:</b> {lot.buying_format}
            </div>
            <div className="sp-button">
              <a href={`/catalog_items/${lot.pretty_id}`}>{bidText}</a>
            </div>
          </div>
          <div className="cell small-8">
            <div className="sp-desc">{entity.description}</div>
          </div>
        </div>
        <div className="sp-item">
          <ItemCard item={lot} meta={meta} key={lot.id} />
        </div>
      </div>
    );

    return html;
  }
}

export default Page;
