import React from "react"
import BaseComponent from "../__application/BaseComponent"
import WidgetPostItem from "./_WidgetPostItem"
import WidgetPostList from "./_WidgetPostList"
import ErrorPage from "./_ErrorPage"

class Page extends BaseComponent {

  constructor(props) {
    super(props)
    this.handleNotifIconClick = this.handleNotifIconClick.bind(this)
    this.handlePostClick = this.handlePostClick.bind(this)
    this.handleOutsideClick = this.handleOutsideClick.bind(this)
    this.handleReadMoreClick = this.handleReadMoreClick.bind(this)
    this.handleTabClick = this.handleTabClick.bind(this)

    this.myRef = React.createRef()

    this.state = {
      posts: {},
      shown: false,
      show_page: null,
      error: false
    }
    console.log("")
  }

  handleNotifIconClick(){
    let state = this.state
    state.shown = !state.shown
    this.setState(state)

    if(state.shown){
      this.getList()
      this.markAllUnreadPostsRead()
    }

    // GA
    if(state.shown){
      let pageId = window.lotId || ""
      let cusId = window.currentCustomerData.id || "guest"
      let evLabel = `${pageId} | ${cusId} |`
      ga('send', {
        'hitType': 'event',
        'eventCategory': 'Header Activity',
        'eventAction': 'Open Notification Center',
        'eventLabel': evLabel
      })

      dataLayer.push({
        'event': 'ua_event',
        'event_category': 'Header Activity',
        'event_action': 'Open Notification Center',
        'event_label': evLabel
      })
    }
  }

  handleTabClick(e, tag){
    if(tag=='updates'){
      let pageId = window.lotId || ""
      let cusId = window.currentCustomerData.id || "guest"
      let evLabel = `${pageId} | ${cusId} |`
      ga('send', {
        'hitType': 'event',
        'eventCategory': 'Header Activity',
        'eventAction': 'Click Updates Tab',
        'eventLabel': evLabel
      })

      dataLayer.push({
        'event': 'ua_event',
        'event_category': 'Header Activity',
        'event_action': 'Click Updates Tab',
        'event_label': evLabel
      })
    }
  }

  handlePostClick(p){
    let s = this.state

    let post = p ? p : s.show_page
    if(post){
      let pageId = window.lotId || ""
      let pinned = post.pinned ? "true" : "false"
      let cusId = window.currentCustomerData.id || "guest"
      let pid = post.id
      let evLabel = `${pageId} | ${cusId} | ${pid} | ${pinned} |`

      ga('send', {
        'hitType': 'event',
        'eventCategory': 'Header Activity',
        'eventAction': 'Click Post Preview',
        'eventLabel': evLabel
      })

      dataLayer.push({
        'event': 'ua_event',
        'event_category': 'Header Activity',
        'event_action': 'Click Post Preview',
        'event_label': evLabel
      })
    }

    s.show_page = p
    if(p) p.read = true
    this.setState(s)
  }

  handleReadMoreClick(e, p){
    let post = p
    let pageId = window.lotId || ""
    let pinned = post.pinned ? "true" : "false"
    let cusId = window.currentCustomerData.id || "guest"
    let pid = post.id
    let evLabel = `${pageId} | ${cusId} | ${pid} | ${pinned} |`

    ga('send', {
      'hitType': 'event',
      'eventCategory': 'Header Activity',
      'eventAction': 'Click Read More',
      'eventLabel': evLabel
    })

    dataLayer.push({
      'event': 'ua_event',
      'event_category': 'Header Activity',
      'event_action': 'Click Read More',
      'event_label': evLabel
    })
  }

  componentWillMount(){
    document.addEventListener('mousedown', this.handleOutsideClick, false)
  }

  componentWillUnmount(){
    document.removeEventListener('mousedown', this.handleOutsideClick, false)
  }

  componentDidMount(){
    let imVisible = $(this.myRef.current).is(':visible')
    if(imVisible){
      this.getList()
    }
  }

  getList(filters){
    const self = this
    if(this.state.posts.posts){
      return
    }
    $.ajax({
      url: '/posts.json',
      type: 'GET',
      complete: function(jqXHR, status){
        if(status == 'success'){
          let resp = JSON.parse(jqXHR.responseText)
          self.setState({
            posts: resp,
            error: false
          })
        }
        else{
          self.setState({
            posts: {},
            error: true
          })
        }
      }
    })
  }

  markAllUnreadPostsRead(){
    let posts = this.state.posts.posts
    if(!posts){
      return
    }
    let unreadIds = []
    for(let i = 0; i<posts.length; i++){
      let p = posts[i]
      if(!p.read){
        unreadIds.push(p.id)
      }
    }


    if(unreadIds.length > 0){
      let toPost = {
        post_ids: unreadIds
      }
      $.ajax({
        url: '/posts/read.json',
        type: 'POST',
        data: toPost,
        complete: function(jqXHR, status){
          let resp = JSON.parse(jqXHR.responseText)

        }
      })
    }
  }

  handleOutsideClick(e){
    let parent = $(".widget-notif-wrapper")
    let child = $(e.target)
    let isInside = parent.find(child).length > 0

    if(!isInside){
      let state = this.state
      state.shown = false
      this.setState(state)
    }
  }

  errorHtml(){
    let error = {
      icon_cls: "fal fa-engine-warning ic-error",
      title: "Oops! Something went wrong on our end.",
      description: `There was an error when we tried to find your newest updates.
                    If refreshing the page doesn’t fix the problem, please try again later.<br/><br/>
                    To view all past updates, head to our <a class='link-red' href='/posts'>Updates page</a>.`
      }
    let html = (<ErrorPage error={error} />)
    return html
  }

  notFoundHtml(){
    let error = {
      icon_cls: "fal fa-snooze ic-info",
      title: "All quiet here!",
      description: `We have no new updates for you at the moment.
                    We’ll let you know when we’ve got something new!<br/><br/>
                    To view all past updates, head to our <a class='link-red' href='/posts'>Updates page</a>.`
      }
    let html = (<ErrorPage error={error} />)
    return html
  }

  postHtml(){
    const self = this
    let html = ""
    if(self.state.error){
      html = <self.errorHtml />
      return html
    }

    const p = self.state.show_page
    const posts = self.state.posts
    if(p){
      html = (<WidgetPostItem settings={posts.settings} post={p} handlePostClick={self.handlePostClick} handleReadMoreClick={self.handleReadMoreClick} />)
    }
    else{
      if(posts.posts && posts.posts.length > 0){
        html = (<WidgetPostList posts={posts} handlePostClick={self.handlePostClick} />)
      }
      else{
        html = <self.notFoundHtml />
      }
    }
    return html
  }

  tabsHtml(){
    const self = this
    const posts = self.state.posts
    if(!posts || !posts.settings){
      return (
        <div className="widget-tabs">
          <div className={`w00 w-tab show`}>&nbsp;</div>
        </div>
      )
    }

    let tabs = posts.settings.enabled_tabs
    let tabsCls = ""
    if(tabs.length == 0){
      tabsCls = "w00"
    }
    else if(tabs.length == 1){
      tabsCls = "w11"
    }
    else if(tabs.length == 2){
      tabsCls = "w12"
    }
    else if(tabs.length == 3){
      tabsCls = "w13"
    }

    let clsTabUpdates = tabsCls + " w-tab"
    let clsTabFeedback = tabsCls + " w-tab"
    let clsTabMessages = tabsCls + " w-tab"
    for(let i=0; i<tabs.length; i++){
      if(tabs[i] == 'updates') clsTabUpdates += " show"
      if(tabs[i] == 'feedback') clsTabFeedback += " show"
      if(tabs[i] == 'messages') clsTabMessages += " show"
    }

    if(tabs[0] == 'updates') clsTabUpdates += " active"
    else if(tabs[0] == 'feedback') clsTabFeedback += " active"
    else if(tabs[0] == 'messages') clsTabMessages += " active"

    let html = (
      <div className="widget-tabs">
        <div className={`${clsTabUpdates}`} onClick={function(e){self.handleTabClick(e, "updates")}}>Updates</div>
        <div className={`${clsTabFeedback}`} onClick={function(e){self.handleTabClick(e, "feedback")}}>Feedback</div>
        <div className={`${clsTabMessages}`} onClick={function(e){self.handleTabClick(e, "messages")}}>Messages</div>
      </div>
    )

    return html
  }

  render() {
    const self = this
    const settings = self.state.posts.settings
    const notifCls = self.state.shown ? "shown" : "hidden"
    const icCls = self.state.shown ? "active" : ""
    let postHtml = self.postHtml()
    let tabsHtml = self.tabsHtml()

    const footerCls = self.state.show_page || (settings && !settings.page_enabled) ? "hidden" : "shown"
    const posts = self.state.posts

    let numberCurcleHtml = ""
    if(posts.unread > 0){
      numberCurcleHtml = (<div className="number_circle"><span>{posts.unread}</span></div>)
    }


    return (
        <div ref={self.myRef}>
          <div className="ic-notif-num">
            <i className={`fal fa-bell fa-2x ic-notif ${icCls}`} onClick={self.handleNotifIconClick}></i>
            {numberCurcleHtml}
          </div>

          <div className={`widget-posts-fb-wrapper ${notifCls}`}>
            <div className="post-fb-inner-wrapper">
              <div className="content-wrapper">
                {tabsHtml}

                {postHtml}
              </div>
              <div className={`widget-footer ${footerCls}`}>
                <a href="/posts">View All Updates</a>
              </div>
            </div>
          </div>

        </div>
      )
  }
}

export default Page
