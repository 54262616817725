import React from "react"

class Page extends React.Component {
  constructor(props) {
    super(props)

    this.onFilterItemClicked = this.onFilterItemClicked.bind(this)
    this.filtersHtml = this.filtersHtml.bind(this)
  }

  onFilterItemClicked({group, f}){
    let model = this.props.model
    let filters = model.filters
    if(group == 'Department'){
      delete(filters.departments.selected[f])
    }
    else if(group == 'Collection'){
      if(filters.themes.locked[f]){
        return
      }
      delete(filters.themes.selected[f])
    }
    else if(group == 'Charity'){
      delete(filters.orgs.selected.orgs[f])
    }
    else if(group == 'Cause'){
      delete(filters.orgs.selected.charities[f])
    }
    else if(group == 'Person'){
      delete(filters.details.selected.people[f])
    }
    else if(group == 'Location'){
      delete(filters.details.selected.place[f])
    }
    else if(group == 'Activity'){
      delete(filters.details.selected.activity[f])
    }
    else if(group == 'Donation Type'){
      filters.donation_type.code = ''
    }
    else if(group == 'Lot Type'){
      filters.buying_format.code = ''
    }
    this.props.changeState(model)
    this.props.search({})
  }

  filtersHtml(){
    const self = this
    const model = self.props.model
    const genHtmlForSelected = (selected, locked, group) => {
      let list = []
      for(const f in selected){
        if(selected[f]){
          list.push((
            <div className="f-list-item" key={`${group}-${f}`} onClick={()=>{self.onFilterItemClicked({group:group, f:f})}}>
              <div className="f-key">
                <span className="f-group">{group}: </span>
                <span className="f-item">{f}</span>
              </div>
              {
                locked[f] ?
                 ''
                 :
                 <div className="f-exit"><i className="fal fa-times"></i></div>
               }
            </div>
          ))
        }
      }
      return list
    }

    let departmentList = genHtmlForSelected(model.filters.departments.selected, {}, 'Department')
    let themeList = genHtmlForSelected(model.filters.themes.selected, model.filters.themes.locked, 'Collection')
    let orgList = genHtmlForSelected(model.filters.orgs.selected.orgs, {}, 'Charity')
    let causeList = genHtmlForSelected(model.filters.orgs.selected.charities, {}, 'Cause')
    let personList = genHtmlForSelected(model.filters.details.selected.people, {}, 'Person')
    let locationList = genHtmlForSelected(model.filters.details.selected.place, {}, 'Location')
    let activityList = genHtmlForSelected(model.filters.details.selected.activity, {}, 'Activity')

    let donationTypeSelected = {}
    const donationTypeCode = model.filters.donation_type.code
    if(donationTypeCode != ''){
      donationTypeSelected[donationTypeCode] = true
    }
    let donationTypeList = genHtmlForSelected(donationTypeSelected, {}, 'Donation Type')

    let buyingFormatSelected = {}
    const buyingFormatCode = model.filters.buying_format.code
    if(buyingFormatCode != ''){
      buyingFormatSelected[buyingFormatCode] = true
    }
    let lotTypeList = genHtmlForSelected(buyingFormatSelected, {}, 'Lot Type')

    let html = (
      <div>
        {departmentList}
        {orgList}
        {causeList}
        {themeList}
        {personList}
        {locationList}
        {activityList}
        {donationTypeList}
        {lotTypeList}
      </div>
    )

    return html
  }

  render(){
    const self = this
    const model = self.props.model
    const filters = model.filters

    const filterList = this.filtersHtml()

    let html = (
      <div>
        {filterList}
      </div>
    )

    return html
  }
}

export default Page
