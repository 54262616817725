import React from "react"
import Tracker from '../services/Tracker'
import {getHtmlTextValue} from "../../../shared/utils/getHtmlTextValue";

class Page extends React.Component {
  constructor(props) {
    super(props)

    this.trackerService = new Tracker()

    this.matchLotsHtml = this.matchLotsHtml.bind(this)
    this.goToLot = this.goToLot.bind(this)
  }

  goToLot({item, pos}){
    const keyword = this.props.model.filters.query_temp
    const lot = item
    this.trackerService.trackTopAutoSuggestClick({keyword, lot, pos})
    location.href = item.path
  }

  matchLotsHtml(){
    const model = this.props.model
    const filters = model.filters
    let results = model.results_temp || model.results || {}
    results = results.results
    const lotsData = this.parseData(model)
    const orgsData = results.orgs

    const hasLots = !_.isEmpty(results.dropdown_catalog_items)
    const hasOrgs = !_.isEmpty(results.orgs)
    const noResults = (!hasLots && !hasOrgs)

    const query = filters.query_temp
    let list = []
    if(hasLots){
      for (var i=0; i<lotsData.list.length; i++) {
        const pos = i+1
        const item = lotsData.list[i]
        const current_price = accounting.formatMoney(item.current_price);
        list.push(
          <div className="ml-lot" key={i} onClick={(e) => {this.goToLot({item, pos})}}>
            <div className="lot-img">
              <img src={item.img} alt={getHtmlTextValue(item.title)} />
            </div>
            <div className="lot-detail">
              <div className="lot-title" dangerouslySetInnerHTML={{__html: htmlDecode(item.title)}}>
              </div>
              <div className="lot-price show-for-medium">
                <span className="desc-title">
                  {item.buying_format=='Buy It Now' ? 'Price' : 'Current Bid:'}
                  &nbsp;
                </span>
                <span>
                  {current_price}
                </span>
              </div>
            </div>
          </div>
        )
      }
    }
    else{
      let orgsMsg = ""
      if(hasOrgs){
        orgsMsg = (
          <div className="nf-text">
            We did find some charitieis matching your search, though.
          </div>
        )
      }
      list = (
        <div className={`search-not-found-msg text-center ${hasOrgs ? 'with-box-shadow' : ''}`} role="status">
          <div className="nf-title">
            Hmmm 🤔&nbsp; We couldn't find any results.
          </div>
          <div className="nf-text">
            Did you mean to search for <b>{query}</b>? You can try adjusting or broadening your search for better results.
          </div>
          {orgsMsg}
        </div>
      )
    }

    let html = (
      <div id="search-output" className="match-lots" aria-live="polite">
        <div className="ml-title">
          {noResults ? '' : 'TOP MATCHES'}
        </div>
        <div className="ml-list">
          {list}
        </div>
      </div>
    )

    return html
  }

  parseData(data){
    const maxLotsToShow = 3
    let results = {}
    let data_results = data.results_temp || data.results
    if(data_results){
      if(data_results.results){
        results = data_results.results
      }
    }
    let json  = {
      list: []
    }

    let lots = results.dropdown_catalog_items
    if(lots){
      for(var i=0; i<lots.length; i++){
        if(i < maxLotsToShow){
          let item = lots[i]
          json['list'].push({
            id: item.id,
            img: item.lot_image,
            title: item.title,
            current_price: item.current_price,
            path: `/catalog_items/${item.pretty_id}`,
            buying_format: item.buying_format
          })
        }
      }
    }

    return json
  }

  render(){
    let html = this.matchLotsHtml()
    return html
  }
}

export default Page
