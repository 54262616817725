import React from "react"

class Page extends React.Component {
  constructor(props) {
    super(props)

    this.handleInputClicked = this.handleInputClicked.bind(this)
    this.handleInputChanged = this.handleInputChanged.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.groupDetailHtml = this.groupDetailHtml.bind(this)
  }

  handleInputClicked(){
    let model = this.props.model
    let filters = model.filters
    filters.orgs.on = !filters.orgs.on
    filters.sort_by.on = false
    filters.departments.on = false
    filters.details.on = false
    filters.themes.on = false
    if(!filters.orgs.on){
      model.filters.orgs.query = ""
    }
    this.props.changeState(model)
  }

  handleInputChanged(event){
    let v = event.currentTarget.value.trim()
    let model = this.props.model
    model.filters.orgs.query = v
    if(!_.isEmpty(v)){
      model.filters.orgs.on = true
    }
    this.props.changeState(model)
  }

  handleSelect({event, group, item}){
    let model = this.props.model
    let selected = model.filters.orgs.selected
    selected[group][item] = !selected[group][item]
    model.filters.pagination.page = 1
    this.props.changeState(model)
    this.props.search({})
  }

  groupDetailHtml({list, group, group_name}){
    const self = this
    const model = self.props.model
    const filters = model.filters

    if(group){
      for(const name in group){
        if(name.toLowerCase().includes(filters.orgs.query.toLowerCase())){
          const count = group[name]
          let itemHtml = (
            <div key={name} className="ic-item grid-x grid-padding-x"
              onClick={(e)=>{self.handleSelect({event: e, group: group_name, item: name})}}>
              <div className="cell small-2 text-right">
                <i className={`ic-check fal ${filters.orgs.selected[group_name][name] ? 'fa-check-square' : 'fa-square'}`}></i>
              </div>
              <div className="cell small-8">
                {name}
              </div>
              <div className="cell small-2">
                ({count})
              </div>
            </div>
          )
          list.push(itemHtml)
        }
      }
    }
  }

  render(){
    const self = this
    let list = []
    const model = self.props.model
    const filters = model.filters
    const results = model.results.results

    if(results){
      let groups = results.groups
      if(groups){
        let orgs = {
          orgs: groups.org_names,
          causes: groups.causes
        }

        self.groupDetailHtml({list: list, group: orgs.orgs, group_name: 'orgs'})
        self.groupDetailHtml({list: list, group: orgs.causes, group_name: 'causes'})
      }
    }

    let html = (
      <React.Fragment>
        <div className="filter-title">
          Charity and Cause
        </div>
        <div className="search-sel-wrapper">
          <div className="input-list-wrapper" onClick={self.handleInputClicked}>
            <input className="input-list" type="text" value={filters.orgs.query} onChange={self.handleInputChanged} placeholder="Charities and causes" />
            <i className="ico-search fal fa-search"></i>
            <i className={`ico-down fal fa-chevron-${filters.orgs.on ? 'up' : 'down'}`}></i>
          </div>
          <div className={`${filters.orgs.on ? 'shown' : 'hidden'}`}>
            <div className="indent-list">
              {list}
            </div>
            <div className="list-action text-center hidden">
              Show more charities and causes
            </div>
          </div>
        </div>
      </React.Fragment>
    )
    return html
  }
}

export default Page
