import React from "react"

class Page extends React.Component {
  constructor(props) {
    super(props)

    this.handleTabClicked = this.handleTabClicked.bind(this)
  }

  getTypeData(){
    const self = this
    let list = []
    const model = self.props.model
    const filters = model.filters
    const code = filters.donation_type.code

    let allTypes = {
      'All': {
        'available': true,
        'selected': true,
        'code': ''
      },
      'Items': {
        'available': false,
        'selected': false,
        'code': 'Physical Item'
      },
      'Experiences': {
        'available': false,
        'selected': false,
        'code': 'Experience'
      }
    }
    try{
      let responseTypes = model.results.results.groups.lot_type
      for(let name in allTypes){
        let type = allTypes[name]
        if(code == type['code']){
          type['selected'] = true
        }
        for(const rt in responseTypes){
          if(rt == type['code']){
            type['available'] = true
          }
        }
      }
    }catch(err){
    }

    let hasSelected = allTypes['Items']['selected'] || allTypes['Experiences']['selected']
    if(hasSelected){
      allTypes['All']['selected'] = false
    }

    return allTypes
  }

  handleTabClicked({tab, available}){
    // if(!available){
    //   return
    // }
    let model = this.props.model
    let filters = model.filters
    let donation_type = filters.donation_type
    let n = event.currentTarget.value
    donation_type.code = tab
    model.filters.pagination.page = 1
    filters.sort_by.on = false
    filters.departments.on = false
    filters.details.on = false
    filters.orgs.on = false
    filters.themes.on = false
    this.props.changeState(model)
    this.props.search({})
  }

  render(){
    const self = this
    const allTypes = self.getTypeData()

    let html = (
      <div>
        <div className="filter-title">
          Donation Type
        </div>
        <div className="tab-sel-wrapper grid-x text-center">
          <button className={`tab-item left-end cell small-3
              ${allTypes['All']['selected'] ? 'active' : ''}
              ${allTypes['All']['available'] ? '' : 'unavailable-temp'}
              `}
              onClick={()=>{self.handleTabClicked({tab:'', available: allTypes['All']['available']})}}>
            All
          </button>
          <button className={`tab-item cell small-4
              ${allTypes['Items']['selected'] ? 'active' : ''}
              ${allTypes['Items']['available'] ? '' : 'unavailable-temp'}
              `}
              onClick={()=>{self.handleTabClicked({tab:'Physical Item', available: allTypes['Items']['available']})}}>
            Items
          </button>
          <button className={`tab-item right-end cell small-5
              ${allTypes['Experiences']['selected'] ? 'active' : ''}
              ${allTypes['Experiences']['available'] ? '' : 'unavailable-temp'}
              `}
              onClick={()=>{self.handleTabClicked({tab:'Experience', available: allTypes['Experiences']['available']})}}>
            Experiences
          </button>
        </div>
      </div>
    )
    return html
  }
}

export default Page
