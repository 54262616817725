import React from "react"
import BaseComponent from "../__application/BaseComponent"
import PostTagLabel from "./_PostTagLabel"

class Page extends BaseComponent {

  constructor(props) {
    super(props)
  }

  postListHtml(opts){
    const self = opts.self
    const list = self.props.posts.posts
    let html = []
    if(list){
      for(let i = 0; i < list.length; i++){
        let p = list[i]
        let label = p.labels[0]
        let time = moment(p.publish_date*1000).fromNow()
        let description = {__html: p.description};
        let postTagLabel = (<PostTagLabel tag={label} />)
        let pinned = p.pinned
        let pinnedHtml = ""
        let pinnnedCls = ""
        if(pinned){
          pinnedHtml = (
            <div className="post-pinned">
              <span>Pinned Post &nbsp;</span>
              <i className="fal fa-thumbtack"></i>
            </div>
          )
          pinnnedCls = "pinned"
        }

        let postTimeHtml = (
          <div className={`post-time unread`}><i className="fas fa-circle ic-dot fa-fw"></i> {time}</div>
        )
        if(p.read){
          postTimeHtml = (
            <div className={`post-time read`}>{time}</div>
          )
        }

        let phtml = (
          <div key={p.id} className={`post-item list-view ${pinnnedCls}`} onClick={function(){self.props.handlePostClick(p)}}>
            <div className="post-header">
              <div className="post-label">{postTagLabel}{label}</div>
              {postTimeHtml}
            </div>
            <div>
              <span className="post-title">{p.title} - </span>
              <span className="post-description" dangerouslySetInnerHTML={description}></span>
            </div>
            {pinnedHtml}
          </div>
        )
        html.push(phtml)
      }
    }
    return html
  }


  render() {
    const self = this
    return (
      <div className="widget-posts-wrapper">
        <self.postListHtml self={self} />
      </div>
    );
  }
}

export default Page
