import React from "react"
import BaseComponent from "../__application/BaseComponent"

class Page extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = {
      remain: 0
    }

    // This binding is necessary to make `this` work in the callback
    this.checkTimer = this.checkTimer.bind(this)
  }

  componentWillMount() {
    let self = this
    self.intervalCheckTimer = setInterval(() => {
      self.checkTimer()
    }, 1000);
  }

  startTimer() {
    let self = this

    if(self.intervalTimer){
      return null
    }

    self.intervalTimer = setInterval(() => {
      let remain = self.state.remain
      if(remain > 0){
        self.setState({
          remain: remain - 1
        })
      }
      else{
        self.stopTimer()
      }
    }, 1000);
  }

  stopTimer() {
    clearInterval(this.intervalTimer)
    this.intervalTimer = null
  }

  checkTimer() {
    try{
      let fromTime = parseInt(localStorage.timer)
      let endTime = new Date(fromTime + (5 * 60 * 1000));
      let now = new Date().getTime()
      if(now < endTime){
        let diffSec = parseInt((endTime - now) / 1000)
        this.setState({remain: diffSec})
        this.startTimer()
      }
    }catch(ex){
      //
    }
  }


  render() {
    let remain = this.state.remain
    let time = ""
    if(remain > 0){
      let min = parseInt(remain / 60)
      let sec = parseInt(remain % 60)
      let timeText = `${min}:${sec}`
      time = (
        <div className="reg2-reward-timer">
          <div>Complete your account in the next <span className="timer-min">{timeText}</span> minutes and we'll give you $100 off your first winning bid!</div>
        </div>
      )
    }

    return (
      <div>
        {time}
      </div>
    );
  }
}

export default Page
