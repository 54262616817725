import React from 'react'
import DonationItemDetail from './components/DonationItemDetail'
import DonationService from './services/Donation'

class Page extends React.Component {
  constructor(props){
    super(props)
    this.donationService = new DonationService()
    this.state = {
      donations: {
        data: [],
        pagination: {}
      },
      itemOnDisplay: {},
      loading: true
    }

    this.showItemDetail = this.showItemDetail.bind(this)
  }

  componentDidMount(){
    const self = this
    // const url = new URL(location.href)
    // const email = url.searchParams.get("email")
    setTimeout(()=>{
      self.donationService.getDonationsForCustomer({page: 1, per_page: 20}).then((resp)=>{
        self.setState({donations: resp.data, loading: false})

        $(document).foundation()
      })
    }, 2000)

  }

  showItemDetail({item}){
    let state = this.state
    state.itemOnDisplay = item
    this.setState(state)
  }

  accordionListItemHtml({item}){
    const time = moment.unix(item.created_at).format('L')
    let html = (
      <li className="accordion-item" data-accordion-item>

        <a className="accordion-title">
          <table>
            <tbody>
              <tr>
                <td className="item-thumb">
                  <div>
                    <img src={item.thumb} alt={item.title} />
                  </div>
                </td>
                <td>
                  <div className="item-title">
                    <b>{item.title}</b>
                    <br/>
                    {time}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </a>

        <div className="accordion-content" data-tab-content>
          <DonationItemDetail item={item} />
        </div>
      </li>
    )

    return html
  }

  accordionListHtml({list}){
    let html = []
    for(let i = 0; i < list.length; i++){
      const item = list[i]
      html.push(this.accordionListItemHtml({item: item}))
    }

    return (
      <ul className="accordion" data-accordion data-allow-all-closed="true">
      {html}
      </ul>
    )
  }


  render(){
    let html = ""
    if(this.state.loading){
      html = (
        <div className='donations-page'>
          <h1 className="align-center">My Submissions</h1>
          <div className="grid-x align-center">
            <div className="ico-loading la-ball-clip-rotate-multiple la-2x" aria-hidden="true"><div></div><div></div></div>
          </div>
        </div>
      )
    }
    else{
      const hasData = (this.state.donations.data && this.state.donations.data.length>0)
      let listHtml = (<div>No activities found</div>)

      if(hasData){
        listHtml = this.accordionListHtml({list: this.state.donations.data})
      }
      html = (
        <div className='donations-page'>
          <h1 className="align-center">My Submissions</h1>
          {listHtml}  
        </div>
      )
    }


    return html
  }
}

export default Page
