import React from "react"
import Search from '../services/Search'
import Tracker from '../services/Tracker'

class Page extends React.Component {
  constructor(props) {
    super(props)

    this.searchService = new Search()
    this.trackerService = new Tracker()

    this.handleSearchItemClicked = this.handleSearchItemClicked.bind(this)
    this.handleSearchItemKeyPress = this.handleSearchItemKeyPress.bind(this)
  }

  handleSearchItemClicked({item, pos}){
    const keyword = item
    this.trackerService.trackRecentSearchClicked({keyword, pos})
    location.href = `/search?keywords=${keyword}`
  }

  handleSearchItemKeyPress({item, pos}){
    const keyword = item
    this.trackerService.trackRecentSearchClicked({keyword, pos})
    if(event.key === 'Enter') {
      location.href = `/search?keywords=${keyword}`
    }
  }

  render(){
    const self = this
    const history = self.searchService.getSearchHistory()
    let list = []
    for (var i=0; i<history.length; i++) {
      const item = history[i]
      const pos = i+1
      list.push(
        <a key={i} className="rs-item" tabIndex="0" 
          onClick={()=>self.handleSearchItemClicked({item, pos})} 
          onKeyPress={()=>self.handleSearchItemKeyPress({item, pos})} >
          <i className="fal fa-search"></i>&nbsp;&nbsp;{item}
        </a>
      )
    }
    if (list.length > 0) {

      let html = (
        <div className="recent-searches">
        <div className="rs-title">
          RECENT SEARCHES
        </div>
        <div className="rs-list">
          {list}
        </div>
      </div>
    )
    return html
  } else {
    let html = (
      <div className="recent-searches">
        <div className="rs-title">
          RECENT SEARCHES
        </div>
        <div className="rs-list">
          You have no recent searches
        </div>
      </div>
    )
    return html
  }
  }
}

export default Page
