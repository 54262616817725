import React, {useState, useMemo, useEffect, useRef, useCallback} from "react";
import Slider from "react-slick";

import Tracker from "../search/services/Tracker";
import Homepage from "../search/services/Homepage";
import styles from "./ListingCarousel.module.scss";
import classNames from "classnames";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 10000,
  adaptiveHeight: true,
  arrows: false,
  pauseOnFocus: true,
  customPaging: () => (
      <div
          className='banner__dot-wrapper'
          style={{
            width: "auto",
            color: "#d4d9dd",
          }}
      >
        <div className='dot-wrapper__dot'/>
      </div>
  ),
};

const PlayButton = ({onClick}) => (<button
    className='banner-slick-control-btn'
    onClick={onClick}
    aria-labelledby='button-label-play'
>
            <span id='button-label-play' hidden>
              play
            </span>
  <i
      className='fa-sharp fa-solid fa-play'
      aria-hidden='true'
      focusable='false'
  />
</button>)

const PauseButton = ({onClick}) => (<button
    className='banner-slick-control-btn'
    onClick={onClick}
    aria-labelledby='button-label-pause'
>
            <span id='button-label-pause' hidden>
              pause
            </span>
  <i
      className='fa-sharp fa-solid fa-pause'
      aria-hidden='true'
      focusable='false'
  />
</button>)

const Slide = ({banner, index}) => {
  const {image_url, image_wide_url, bg_color, header_bg, footer_bg, footer_text_color, text_color, landing_page_url, header,
    short_header, details, footer
  } = banner;

  const bannerStyle = {
    background: bg_color,
  };

  const headerStyle = {
    background: header_bg,
  };

  const footerStyle = {
    background: footer_bg,
    color: footer_text_color,
  };

  const textColor = {
    color: text_color,
  };

  return (
      <div
          key={`banner-item-${index}`}
          onClick={(event) => {
            itemClicked({ event: event, item: banner, pos: index + 1 });
          }}
      >
        <div
            className='grid-x align-middle banner-wrapper'
            style={bannerStyle}
        >
          <div className='cell medium-4 banner-body show-for-medium'>
            <div className="banner-body-wrapper">
              <div className='banner-badge' style={headerStyle}>
                <a href={landing_page_url}>{header}</a>
              </div>
              <div className='banner-title' style={textColor}>
                <a href={landing_page_url}>{short_header}</a>
              </div>
              <p className='banner-desc' style={textColor}>
                <a href={landing_page_url}>{details}</a>
              </p>
              <a
                  className='banner-btn'
                  style={footerStyle}
                  href={landing_page_url}
                  
              >{footer}
              </a>
            </div>
          </div>
          <div className='cell medium-8 banner-img show-for-medium'>
            <a href={landing_page_url}>
              <img data-src={image_wide_url} alt={short_header} />
            </a>
          </div>
          <div className='cell medium-8 banner-img show-for-small-only'>
            <a href={landing_page_url}>
              <img data-src={image_url} alt={short_header} />
            </a>
          </div>
          <div className='cell medium-4 banner-body show-for-small-only'>
            <div className="banner-body-wrapper">
              <div className='banner-badge' style={headerStyle}>
                <a href={landing_page_url}>{header}</a>
              </div>
              <div className='banner-title' style={textColor}>
                <a href={landing_page_url}>{short_header}</a>
              </div>
              <p className='banner-desc' style={textColor}>
                <a href={landing_page_url}>{details}</a>
              </p>
              <a
                  className='banner-btn'
                  style={footerStyle}
                  href={landing_page_url}
              >
                {footer}
              </a>
            </div>
          </div>
        </div>
      </div>
  )
}

const ListingCarousel = () => {
  const [banners, setBanners] = useState([]);
  const [isPlaying, setIsPlaying] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);
  const slider = useRef(null);

  const trackerService = useMemo(()=>new Tracker(), []);

  useEffect(()=>{
    (
      async ()=> {
        const {data} = await new Homepage().loadBanner();
        setBanners(data)
        $(".banner-img img").lazyload();
      }
    )()
  }, [])

  const itemClicked = ({ pos }) => {
    trackerService.trackBannerClick({ pos: pos });
  }

  const play = () => {
    slider.current.slickPlay();
    setIsPlaying(true)
  }

  const pause = () => {
    slider.current.slickPause();
    setIsPlaying(false)
  }

  const slickGoTo = (index) => {
    slider.current.slickGoTo(index);
  }

  const getDots = useCallback((dots) => (
          <div>
              <ul className={styles.dotsWrapper} style={{ margin: "0px" }}> {dots.map((li, index) => {
                  return <button key={`hpcarousel-${index}`} onClick={()=>slickGoTo(index)} className={styles.dotWrapper}>
                      <div className={classNames(styles.dot, {
                          [styles.activeDot]: currentIndex === index
                      })}/>
                  </button>;
              })}
              </ul>
          </div>)
  , [currentIndex])

  const beforeChange = useCallback((_, newIndex) => {
    setCurrentIndex(newIndex)
  }, [setCurrentIndex])

  if(!banners || banners.length === 0) return null;

  const list = banners.map((banner, index) => {
    return <Slide key={`hpcarousel-slide-${index}`} banner={banner} index={index} isVisible={index === currentIndex} />
  })

  return (
      <div>
        <Slider ref={slider} {...settings} beforeChange={beforeChange} appendDots={getDots}>
          {list}
        </Slider>
        <div className="control-btn-wrapper">
          {isPlaying ? <PauseButton onClick={pause} /> : <PlayButton onClick={play} />}
        </div>
      </div>
  )
}



export default ListingCarousel;
