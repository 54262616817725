import React from "react"

import OrgSel from './OrgSel'
import DetailSel from './DetailSel'
import ThemeSel from './ThemeSel'
import DonationTypeSel from './DonationTypeSel'
import DepartmentSelNested from './DepartmentSelNested'
import PriceSel from './PriceSel'
import FiltersApplied from './FiltersApplied'

class Page extends React.Component {
  constructor(props) {
    super(props)
  }

  render(){
    const self = this
    let list = []
    const model = self.props.model
    const filters = model.filters
    const results = model.results.results
    const hideFilters = !_.isEmpty(filters.themes.locked)

    let html = ""

    html = (
      <div className="component drawer slide-up">
        <div className="drawer__content">
          <div className="drawer__content-header grid-x">
            <div className="cell small-4 text-left">
              &nbsp;
            </div>
            <div className="cell small-4 text-center">
              <h3 className="drawer__content-label">Filter By</h3>
            </div>
            <div className="cell small-4 text-right btn-apply" onClick={self.props.onCancel}>
              <button className="drawer__content-label close confirm">Done</button>
            </div>
          
          </div>
        
          <div className="lot-filters">
            <div className="grid-x">
              <div className="f-title cell medium-12">
                <h4 className="drawer__content-sub-label">Filters applied:</h4>
              </div>
              <div className="f-list cell medium-7 large-8">
                <FiltersApplied model={self.props.model} changeState={self.props.changeState} search={self.props.search} />
              </div>
            </div>
          </div>

          <div className="pu-body grid-x grid-padding-x">

            <div className="filter-item price-range cell medium-4">
              <PriceSel model={self.props.model} changeState={self.props.changeState} search={self.props.search} />
            </div>

            <div className="filter-item departments cell medium-4">
              <DepartmentSelNested model={self.props.model} changeState={self.props.changeState} search={self.props.search} />
            </div>

            <div className="filter-item details cell medium-4">
              <DetailSel model={self.props.model} changeState={self.props.changeState} search={self.props.search} />
            </div>

            <div className="filter-item orgs cell medium-4">
              <OrgSel model={self.props.model} changeState={self.props.changeState} search={self.props.search} />
            </div>

            <div className="filter-item themes cell medium-4">
              <ThemeSel model={self.props.model} changeState={self.props.changeState} search={self.props.search} />
            </div>

          </div>
        </div>
        <button className="cell small-4 text-right btn-apply bottom" onClick={self.props.onCancel}>
            Done
        </button> 
      </div>
    )
    return html
  }
}

export default Page
