import React from "react"
import Search from '../services/Search'

class Page extends React.Component {
  constructor(props) {
    super(props)

    this.initList = this.initList.bind(this)
    this.openSubItem = this.openSubItem.bind(this)

    this.initList()
  }

  initList(){
    const self = this
    let search = new Search()
    let list = search.getCategoreis().then(res => {
      let model = self.props.model
      model.departments = res.data.data.categories
      self.props.changeState(model)
    })
  }

  openSubItem(item){
    let model = this.props.model
    for(var _item of model.departments){
      if(_item.id == item.id){
        _item.open_sub = !_item.open_sub
      }
    }
    this.props.changeState(model)
  }


  render(){
    const self = this
    let list = []
    let departments = self.props.model.departments
    for(var i=0; i<departments.length; i++){
      let item = departments[i]
      let subHtml = []
      if(item.open_sub){
        for(var si of item.sub_categories){
          let html = (
            <div className="grid-x grid-padding-x sub-item" key={si.id}>
              <div className="cell small-1">
                &nbsp;
              </div>
              <div className="cell small-1 text-right">
                <i className="ic-check fal fa-square"></i>
              </div>
              <div className="cell small-9">
                {si.category_name}
              </div>
            </div>
          )
          subHtml.push(html)
        }
      }

      let itemHtml = (
        <div key={item.id}>
          <div className="grid-x grid-padding-x">
            <div className="cell small-2 text-right">
              <i className="ic-check fal fa-square"></i>
            </div>
            <div className="cell small-8">
              {item.category_name}
            </div>
            <div className="cell small-2">
              <i className={`ic-expand fal ${item.open_sub ? 'fa-minus' : 'fa-plus'}`} onClick={(e)=>{self.openSubItem(item)}}></i>
            </div>
          </div>
          {subHtml}
        </div>
      )
      list.push(itemHtml)
    }

    let html = (
      <div className="search-sel-wrapper">
        <div className="input-list-wrapper">
          <input className="input-list" type="text" placeholder="Departments" />
        </div>
        <div className="indent-list">
          {list}
        </div>
        <div className="list-action text-center">
          Show more departments
        </div>
      </div>
    )
    return html
  }
}

export default Page
