import React from "react"
import BaseComponent from "../__application/BaseComponent"
import StatusNav from "./_StatusNav"
import Done from "./_Done"

class Page extends BaseComponent {
  constructor(props) {
    super(props)
  }

  render() {
    return (
        <div className="reg2 new-reg done-step grid-x grid-margin-x">

          <div className="reg2-page-title cell medium-12">Your account has been created!</div>

          <div className="cell medium-6">
            <StatusNav step="done"/>
          </div>

          <div className="cell medium-6">
            <Done />
          </div>
        </div>
      )
  }
}

export default Page
