import React from 'react'
import App from './components/SearchBar'
import Search from './services/Search'
import Tracker from './services/Tracker'

class Page extends React.Component {
  constructor(props){
    super(props)
    this.searchService = new Search()
    this.trackerService = new Tracker()

    this.state = {
      show_full_bar: props.show_full_bar,
      is_on: false,
      is_suggests_on: false,
      is_tips_on: false,
      is_searching: false,
      filters: {
        query: props.search_term || '',
        query_temp: props.search_term || '',
        pagination: {
          page: 1,
          per_page: 5
        }
      },
      results: {}
    }

    this.changeState = this.changeState.bind(this)
    this.search = this.search.bind(this)
  }

  changeState(newState){
    this.setState(newState)
  }

  componentDidUpdate(prevProps){
    if(this.state.is_suggests_on){
      $("body").addClass("no-scroll-for-search")
    }
    else{
      $("body").removeClass("no-scroll-for-search")
    }
  }

  search(){
    const self = this
    let state = self.state
    let tempFilters = {
      query: state.filters.query_temp || '',
      pagination: {
        page: 1,
        per_page: 5
      }
    }

    self.state.is_searching = true
    self.changeState(self.state)
    return self.searchService.queryApi({filters: tempFilters}).then(res => {
      let state = self.state
      state.results = res.data
      state.is_searching = false
      self.changeState(state)

      const keyword = state.results.term
      self.trackerService.trackAutoSuggests({results: state.results, keyword})
    })
  }

  render(){
    return (
      <App model={this.state} changeState={this.changeState} searchDropdown={this.search}/>
    )
  }
}

export default Page
