import React from "react"
import _ from "lodash"

const allCTA = 'All'
const auctionCTA = 'Auction'
const buyNowCTA = GlobalConfig.buyNowCTA

class Page extends React.Component {
  constructor(props) {
    super(props)

    this.handleTabClicked = this.handleTabClicked.bind(this)
  }

  getTypeData(){
    const self = this
    let list = []
    const model = self.props.model
    const filters = model.filters
    const code = filters.buying_format.code

    let allTypes = {}
    allTypes[allCTA] = {
      'available': true,
      'selected': false,
      'code': ''
    }
    allTypes[auctionCTA] = {
      'available': false,
      'selected': true,
      'code': 'Auction'
    }
    allTypes[buyNowCTA] = {
      'available': false,
      'selected': false,
      'code': 'Buy It Now'
    }
    
    for(let name in allTypes){
      let type = allTypes[name]
      if(code == type['code']){
        type['selected'] = true
      }
    }

    try{
      let responseTypes = model.results.results.groups.buying_format
      for(let name in allTypes){
        let type = allTypes[name]
        for(const rt in responseTypes){
          if(rt == type['code']){
            type['available'] = true
          }
        }
      }
    }catch(err){
    }

    let hasSelected = allTypes[auctionCTA]['selected'] || allTypes[buyNowCTA]['selected']
    if(hasSelected){
      allTypes[allCTA]['selected'] = false
    }

    return allTypes
  }

  handleTabClicked({tab, available, lockType}){
    // if(!available){
    //   return
    // }
    if(lockType){
      return
    }
    let model = _.cloneDeep(this.props.model)
    let filters = model.filters
    let buying_format = filters.buying_format
 
    if (tab == "Buy It Now"){
      window.location = window.universal_variable.replatform_endpoint
    }

    const prev_bf = buying_format.code
    if(prev_bf != tab){
      // if(window.current_page && window.current_page.version==2){
      //   if(tab == "Buy It Now"){
      //     filters.sort_by.query.code = "estimated_price:desc"
      //   }
      //   else if(tab == "Auction"){
      //     filters.sort_by.query.code = "close_date:asc"
      //   }
      //   else{
      //     // all
      //     filters.sort_by.query.code = "best_content:desc"
      //   }
      // }
      // else{
      //   if(tab == "Buy It Now"){
      //     filters.sort_by.query.code = "estimated_price:desc"
      //   }
      //   else if(tab == "Auction"){
      //     filters.sort_by.query.code = "close_date:asc"
      //   }
      //   else{
      //     // all
      //     filters.sort_by.query.code = "close_date:asc"
      //   }
      // }
      
      // if(window.current_page && window.current_page.version==2){
      //   filters.sort_by.query.code = "best_content:desc"
      // }else{
      //   filters.sort_by.query.code = "close_date:asc"
      // }

      if (this.props.model.is_relevancy_filter_enabled == true) {
        filters.sort_by.query.code = "_text_match:desc"
      } else if(tab == "Buy It Now"){
        filters.sort_by.query.code = "current_price:asc"
        filters.ab = 1
      }
      else{
        filters.sort_by.query.code = "close_date:asc"
        delete(filters.ab)
      }

      if(filters.custom_sort_enabled){
        filters.sort_by.query.code = "pos:asc"
      }
    }
    buying_format.code = tab
    filters.pagination.page = 1
    filters.sort_by.on = false
    filters.departments.on = false
    filters.details.on = false
    filters.orgs.on = false
    filters.themes.on = false
    this.props.changeState(model)
    this.props.search({m: model})

    // filters showing up in different parts of page, use this hack to sync them
    var event = new CustomEvent('update-listing-filters',{detail: {filters: filters}});
    document.dispatchEvent(event);
  }

  render(){
    const self = this
    const allTypes = self.getTypeData()
    const lockType = self.props.model.filters.buying_format.lock_type
    const endpoint = window.universal_variable ? window.universal_variable.replatform_endpoint : '';

    let html = (
      <React.Fragment>
        <div className="filter-title">
          Lot Type
        </div>
        <div className="tab-sel-wrapper grid-x text-center">
          <button className={`tab-item left-end cell small-6
              ${allTypes[auctionCTA]['selected'] ? 'active' : ''}
              ${allTypes[auctionCTA]['available'] ? '' : 'unavailable-temp'}
              ${lockType ? 'locked' : ''}
              `}
              onClick={()=>{self.handleTabClicked({tab:'Auction', available: allTypes[auctionCTA]['available'], lockType: lockType})}}>
            Auction
          </button>
          <a href={endpoint}   className={`right-end cell small-6`}>
            {buyNowCTA}
          </a>
        </div>
      </React.Fragment>
    )
    return html
  }
}

export default Page
