import React from "react";
import Search from "./services/Search";
import ItemCard from "./components/ItemCard/ItemCard";

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.searchService = new Search();

    const data = props.data || {};
    this.state = {
      row_type: data.row_type,
      rank: data.rank,
      lots: data.lots || [],
      title: data.title,
      text: data.text,
    };
  }

  render() {
    const lots = this.state.lots;
    let lotList = [];

    const lot_count = lots.length;
    const row_title = this.state.title;
    const row_rank = this.state.rank;
    const row_type = this.state.row_type;
    const v = "homepage2";
    for (let i = 0; i < lot_count; i++) {
      const pos = i + 1;
      const lot = lots[i];
      const meta = { pos, row_type, row_title, row_rank, lot_count, v };
      let lotCardHtml = <ItemCard item={lot} meta={meta} key={lot.id} />;
      lotList.push(lotCardHtml);
    }

    const title = { __html: this.state.title };
    const text = { __html: this.state.text };

    return (
      <div className="top-bin-row">
        <div className="tbr-mask"></div>
        <div className="tbr-wrapper">
          <div className="tbr-bar"></div>
          <div className="tbr-text-wrapper">
            <div className="tbr-title-link-wrapper">
              <div className="tbr-title">
                <span className="" dangerouslySetInnerHTML={title}></span>
              </div>
              <a
                href="/theme/buy-now/lots"
                className="tbr-all-link"
              >
                All Buy Now <i class="fal fa-angle-right"></i>
              </a>
            </div>
            <div className="tbr-text">
              <span className="" dangerouslySetInnerHTML={text}></span>
            </div>
          </div>
          <div className="lot-cards grid-x grid-margin-x">{lotList}</div>
        </div>
      </div>
    );
  }
}

export default Page;
