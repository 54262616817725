import React from "react"
import BaseComponent from "../__application/BaseComponent"

class Page extends BaseComponent {

  constructor(props) {
    super(props)
  }

  errorHtml(opts){
    const self = opts.self
    const error = self.props.error
    const title =  {__html: htmlDecode(error.title)}
    const description = {__html: htmlDecode(error.description)}

    let html = (
      <div>
        <div className="state-icon">
          <i className={`${error.icon_cls}`}></i>
        </div>
        <div className="err-title">
          <span dangerouslySetInnerHTML={title}></span>
        </div>
        <div className="err-desc">
          <span dangerouslySetInnerHTML={description}></span>
        </div>
      </div>
    )
    return html
  }


  render() {
    const self = this
    return (
      <div className="widget-posts-wrapper post-error">
        <self.errorHtml self={self} />
      </div>
    );
  }
}

export default Page
