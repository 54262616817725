import React from "react"
import Tracker from '../services/Tracker'

class Page extends React.Component {
  constructor(props) {
    super(props)
    this.trackerService = new Tracker()
  }

  onItemClicked({event, lot, meta}){
    const v = meta.v
    const {pos, row_type, row_title, row_rank, lot_count} = meta
    this.trackerService.trackLotClickV2({pos, row_type, row_title, row_rank, lot_count})
  }

  onViewAllClicked({event, pos, row_type, row_title, row_rank, lot_count}){
    this.trackerService.trackLotClickV2({pos, row_type, row_title, row_rank, lot_count})
  }

  themeRowHtml(){
    const self = this
    const entity = self.props.entity
    const lots = entity.catalog_items
    const lot_count = lots.length
    const row_title = entity.title
    const row_rank = entity.rank
    const row_type = entity.row_type
    const v = 'homepage2'

    let list = []

    for(let i=0; i<lot_count; i++){
      const pos = i+1
      const lot = lots[i]

      // use prod img
      let imgUrl = lot.lot_image
      imgUrl = imgUrl.replace("//s3.amazonaws.com/images-dev.charitybuzz.com/","//s3.amazonaws.com/images.charitybuzz.com/")
      const meta = {pos, row_type, row_title, row_rank, lot_count, v}
      list.push(
        <div className="theme-item" key={lot.id}>
          <a href={`/catalog_items/${lot.pretty_id}`} style={{"backgroundImage": `url(${imgUrl})`}} onClick={(event)=>self.onItemClicked({event, lot, meta})} >
          </a>
        </div>
      )
    }
    return list
  }

  render(){
    const entity = this.props.entity
    const rowHtml = this.themeRowHtml()
    const url = `/theme/${entity.slug}`

    const lot_count = entity.pagination.total_objects
    const row_title = entity.title
    const row_type = entity.row_type
    const row_rank = entity.rank


    let html = (
      <div className="theme-row">
        <div className="head-bar grid-x grid-margin-x">
          <div className="theme-title cell small-4">
            {row_title}
          </div>
          <div className="row-action cell small-8 align-right">
            <span className="btn-view-all" onClick={(e)=>{self.onViewAllClicked({event:e, pos:'all', row_type, row_title, row_rank, lot_count})}}>
              <a href={url}>
                View All >
              </a>
            </span>
          </div>
        </div>
        <div className="theme-items-wrapper">
          {rowHtml}
        </div>
      </div>
    )

    return html
  }
}

export default Page
