import React from "react"
import BaseComponent from "../__application/BaseComponent"

class Page extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = {
      model: {
        first_name: "",
        last_name: "",
        email_primary: "",
        ct_password: "",
        ct_password_confirmation: "",
        user_agreement: true,
        gdpr_agreement: true,
        errors: {}
      },
      classNames: {
        first_name: "",
        last_name: "",
        email_primary: "",
        ct_password: ""
      },
      labels: {
        first_name: "",
        last_name: "",
        email_primary: "",
        ct_password: ""
      },
      formStatus: "filling"
    }

    // This binding is necessary to make `this` work in the callback
    this.handleChange = this.handleChange.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.handleFocusOut = this.handleFocusOut.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.reCaptchaExecute = this.reCaptchaExecute.bind(this)
    this.reCaptchaCallback = this.reCaptchaCallback.bind(this)

    this.widgetId = null
  }

  handleChange(event) {
    let key = event.target.name
    let val
    if(event.target.type == "checkbox"){
      val = event.target.checked
    }
    else{
      val = event.target.value
    }

    let modelChanges = this.state.model
    modelChanges[key] = val

    this.setState({
      model:modelChanges
    })
  }

  handleFocus(event){
    BaseComponent.prototype.setDisplayForItem.call(this, {
      key: event.target.name,
      val: event.target.value,
      name: event.target.placeholder || event.target.attributes.placeholder.value,
      action: "focus in"
    })
  }

  handleFocusOut(event){
    BaseComponent.prototype.setDisplayForItem.call(this, {
      key: event.target.name,
      val: event.target.value,
      name: event.target.placeholder || event.target.attributes.placeholder.value,
      action: "focus out"
    })
  }

  handleKeyPress(event){
    if (event.key == 'Enter') {
      this.reCaptchaExecute(event)
    }
  }

  handleAdditionalCustomerAttrs(customer){
    //CBZPersisted is for chase
    let client_memory = amplify.store("CBZPersisted")
    if(client_memory.chase_partnership){

      customer.chase = true
      customer.chase_entrance_date = client_memory.chase_entrance_date_est
      if (client_memory.campaign_name){
        customer.utm_campaign = client_memory.campaign_name
      }
    }
    //CBZPersisted is for gray card
    let gray_client_memory = amplify.store("CBZPersistedGray")
    if(gray_client_memory.gray_partnership){
      customer.gray = true
      customer.gray_entrance_date = gray_client_memory.gray_entrance_date
    }

    //auto_reward_code is client side memory from any page that stores a reward code.
    // after reg we check for this code and add it
    let auto_reward_code = amplify.store("auto_reward_code")
    if(auto_reward_code){
      customer.auto_reward_code = auto_reward_code
    }

    //ga utm
    const utm = window.CB_GA_UTM
    if(utm){
      customer.customer_acquisition = utm
    }
  }

  submitForm(){
    // prefent double submit
    if(this.state.formStatus == "submitting"){
      return
    }

    let self = this
    let customer = self.state.model

    customer.ct_password_confirmation = customer.ct_password
    customer.user_agreement = true
    self.handleAdditionalCustomerAttrs(customer)

    customer.errors = {}
    self.setState({
      model: customer,
      formStatus: "submitting"
    })

    let dataToSend = {customer: customer}
    let bannerSeenTime = localStorage.getItem("stime")
    if(bannerSeenTime){
      dataToSend.banner_time = bannerSeenTime
    }
    dataToSend.recaptcha_token = self.reCaptchaToken

    $.ajax({
      url: '/registration.json',
      type: 'POST',
      data: dataToSend,
      complete: (jqXHR, status)=>{
        let resp = $.parseJSON(jqXHR.responseText)

        if(_.isEmpty(resp.customer.errors)){
          try{
            if(window.inRegPage()){
              // optmizely
              // window.pushToOptimizely({
              //   type: "event",
              //   eventName: "reg_r03_profile_submitted"
              // });

              // ga
              // window.GAprocessRegEvent({
              //   'hitType': 'event',
              //   'eventCategory': 'Registration Steps',
              //   'eventAction': 'Profile Submitted'
              // })
            }
          } catch(ex){}

          location.href = resp.success_path
        }
        else{
          let cus = resp.customer
          let classNames = self.state.classNames
          let labels = self.state.labels

          cus.gdpr_agreement = customer.gdpr_agreement
          cus.user_agreement = customer.user_agreement
          cus.ct_password = customer.ct_password

          let err = cus.errors
          if(err){
            BaseComponent.prototype.normalizeErrorMessages.call(this, err)

            if(err.first_name)
              classNames.first_name = "error"
            else
              classNames.first_name = ""

            if(err.last_name)
              classNames.last_name = "error"
            else
              classNames.last_name = ""

            if(err.email_primary)
              classNames.email_primary = "error"
            else
              classNames.email_primary = ""

            if(err.password || err.ct_password){
              err.ct_password = (err.password || err.ct_password)
              classNames.ct_password = "error"
            }
            else{
              classNames.ct_password = ""
            }
          }

          self.setState({
            model: cus,
            classNames: classNames,
            formStatus: "filling"
          })
        }
      }
    })
  }

  reCaptchaReset() {
    grecaptcha.reset(this.widgetId);
  }

  reCaptchaExecute(event) {
    let self = this
    event.preventDefault()
    if(!SystemProperty.ReCaptcha){
      self.submitForm()
    }
    else{
      self.reCaptchaSetup()
      if(self.reCaptchaToken){
        self.submitForm()
      }
      else{
        grecaptcha.execute(self.widgetId);
      }
    }

  }

  reCaptchaSetup(){
    let self = this
    if(self.widgetId == null){
      self.widgetId = grecaptcha.render(
        "g-recaptcha",
        {
          callback: self.reCaptchaCallback,
          sitekey: window.RECAPTCHA_SITE_KEY,
          size: "invisible"
        }
      )
    }
  }

  reCaptchaCallback(token){
    let self = this
    // if it is comming form recaptrue, it will have a token
    if(!token || typeof(token)!="string") return false;
    self.reCaptchaToken = token

    self.submitForm()
  }


  render() {
    let submitButton = (<button className={`action-btn ${this.state.formStatus=="submitting" ? "disabled" : ""}`} onClick={this.reCaptchaExecute}>SIGN UP</button>)

    let customer_has_memory  = amplify.store("auto_reward_code");
    if (customer_has_memory){
      $(".hidden-reward-ui input").val(customer_has_memory)
      $(".hidden-reward-ui").removeClass("hidden")

    }

    return (
      <form onSubmit={this.reCaptchaExecute} autoComplete="off">
        <div className="reg2-form">
          <div className="form-info">
            <div className="form-info-title">Basic Information</div>
            <div className="form-info-text">
              First, we need some information to get your account setup. After completing this step you'll be able to add lots to your Watchlist.
            </div>
          </div>
          <div className="fb-btn">
            <a href="/auth/facebook">
              <div className="fb-icon">
                <i className="fab fa-facebook-square"></i>
              </div>
              <div className="fb-text">
                SIGN UP WITH FACEBOOK
              </div>
            </a>
          </div>
          <div className="or-divider">
            <div className="or-text">or</div>
          </div>
          <div className="form-body">
            <div className="form-row">
              <div className={`form-input-wrapper half-row ${this.state.classNames.first_name}`}>
                <label className="input-legend">{this.state.model.errors.first_name || this.state.labels.first_name}</label>
                <input autoComplete="off" name="first_name" className="form-input" type="text" value={this.state.first_name} onChange={this.handleChange} onKeyPress={this.handleKeyPress} onFocus={this.handleFocus} onBlur={this.handleFocusOut}  placeholder="First Name" />
              </div>
              <div className={`form-input-wrapper half-row right ${this.state.classNames.last_name}`}>
                <label className="input-legend">{this.state.model.errors.last_name || this.state.labels.last_name}</label>
                <input autoComplete="off" name="last_name" className="form-input" type="text" value={this.state.last_name} onChange={this.handleChange} onKeyPress={this.handleKeyPress} onFocus={this.handleFocus} onBlur={this.handleFocusOut} placeholder="Last Name" />
              </div>
            </div>
            <div className="form-row">
              <div className={`form-input-wrapper ${this.state.classNames.email_primary}`}>
                <label className="input-legend">{this.state.model.errors.email_primary || this.state.labels.email_primary}</label>
                <input autoComplete="off" name="email_primary" className="form-input" type="email" value={this.state.email_primary} onKeyPress={this.handleKeyPress} onChange={this.handleChange} onFocus={this.handleFocus} onBlur={this.handleFocusOut} placeholder="Email" />
              </div>
            </div>
            <div className="form-row">
              <div className={`form-input-wrapper ${this.state.classNames.ct_password}`}>
                <label className="input-legend">{this.state.model.errors.ct_password || this.state.labels.ct_password}</label>
                <input autoComplete="off" name="ct_password" className="form-input" type="password" value={this.state.ct_password} onKeyPress={this.handleKeyPress} onChange={this.handleChange} onFocus={this.handleFocus} onBlur={this.handleFocusOut} placeholder="Password" />
              </div>
            </div>


            <div className="form-row hidden-reward-ui hidden">
              <div>
                <label className="input-legend form-input-wrapper">Reward</label>
                <input  readOnly="readOnly" autoComplete="off" name="auto_reward_code" className="form-input" type="text"  />
              </div>
            </div>



            <div>
              <div id="g-recaptcha"></div>
              {submitButton}
            </div>

            <br />

            <div className="form-row form-helper-text">
              <label className="grid-x">
                <div className="cell small-1">
                  <input autoComplete="off" name="gdpr_agreement" className="form-input" type="checkbox" checked={this.state.model.gdpr_agreement} onChange={this.handleChange}/> &nbsp;
                </div>
                <div className="cell small-11">
                  Email me about the best Charitybuzz auctions!
                </div>
              </label>
            </div>

            <div className="form-row form-helper-text">
              Unchecking this removes you from our marketing emails only. You’ll still receive important email updates about auctions you’re watching or bidding on.
              <br /><br />
              When you sign up, you agree to the Charitybuzz <a className="link-privacy" href="/privacy_policy" target="_blank">Privacy Policy</a> and the <a className="link-terms" href="/terms" target="_blank">Terms of Use</a>.
            </div>





          </div>
        </div>

      </form>
    )
  }
}

export default Page
