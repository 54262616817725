import React from "react"

class Page extends React.Component {
  constructor(props) {
    super(props)

    this.onPopularItemClicked = this.onPopularItemClicked.bind(this)
  }

  onPopularItemClicked(q){
    let model = this.props.model
    model.filters.query = q
    this.props.changeState(model)
    this.props.search({})
  }

  render(){
    const self = this
    let list = []
    const model = self.props.model
    const filters = model.filters
    const query = filters.query
    const results = model.results_temp || model.results
    const popular = results.results.suggested_terms || []

    let popularList= []
    for(let i of popular){
      popularList.push((
        <div className="item" key={i} onClick={(e)=>{self.onPopularItemClicked(i)}}>
          <i className="fal fa-search"></i>
          <div className="i-text">{i}</div>
        </div>
      ))
    }

    const seeClosedLotsToolTip = "<b>Looking for closed lots?</b> <br/>Closed lots aren’t quite ready for our new search experience, just yet. Click this link to view closed lots in the old experience!"

    let html = (
      <div>
        <div className="text-center no-res-hero">
          <div className="nr-title">
            We couldn't find any results!
          </div>
          <div className="nr-title2">
            Your search for <b>{query}</b> came up empty.
            <div className="">
              <a href={`/search?keywords=${query}&v=1&closed=1`}>View Closed Lots</a>
              &nbsp;
              <i className="tooltip-for-link fal fa-info-circle has-tip bottom" data-tooltip aria-haspopup="true" data-disable-hover="false" data-allow-html="true" title={seeClosedLotsToolTip}></i>
            </div>
          </div>
          <div className="nr-title3">
            Having trouble? Give us a shout!
          </div>
          <div className="">
            <span className="">
              <a className="link" href="#">212.243.3900</a>
            </span>
            &nbsp; | &nbsp;
            <span className="">
              <a className="link" href="mailto:help@charitybuzz.com">help@charitybuzz.com</a>
            </span>
          </div>
        </div>

        <div className="grid-x grid-padding-x">
          <div className="browse-by cell medium-4">
            <div className="title">
              <b>
                Try browsing by:
              </b>
            </div>
            <div className="list">
              <div className="item">
              <i className="fal fa-search"></i>
                <span className="divider">|</span>
                <a href="/categories/causes-all/catalog_items?hide=carousel">Course</a>
              </div>
            </div>
          </div>
          <div className="popular-searches cell medium-4">
            <div className="title">
              <b>
                Popular searches
              </b>
            </div>
            <div className="list">
              {popularList}
            </div>
          </div>
        </div>
      </div>
    )
    return html
  }
}

export default Page
