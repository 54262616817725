class Impact{

  sendUserRegistration({customerId, emailSHA1}){
    ire('trackConversion', 33526, {
      orderId: `cus-${customerId}`,
      customerId: customerId,
      customerEmail: emailSHA1 
    },
    {
      verifySiteDefinitionMatch:true
    });
  }

  sendFirstBid({customerId, bidId, emailSHA1, promoCode, discount, lotId, itemName, subTotal, category, qty}){
    ire('trackConversion', 33374, {
      orderId: `bid-${bidId}`,
      customerId: customerId,
      customerEmail: emailSHA1, 
      customerStatus: "", 
      currencyCode: "USD",
      orderPromoCode: promoCode, 
      orderDiscount: discount,
      items: [{
        subTotal: subTotal,
        category: category, 
        sku: lotId,
        quantity: qty,
        name: itemName
      }] 
    },
    { 
      verifySiteDefinitionMatch:true 
    });
  }

  sendOnlineSale({customerId, orderId, emailSHA1, promoCode, discount, lotId, itemName, subTotal, category, qty}){
    ire('trackConversion', 33375, {
      orderId: `stripe-${orderId}`,
      customerId: customerId,
      customerEmail: emailSHA1, 
      customerStatus: "", 
      currencyCode: "USD",
      orderPromoCode: promoCode, 
      orderDiscount: discount,
      items: [{
        subTotal: subTotal,
        category: category, 
        sku: lotId,
        quantity: qty,
        name: itemName
      }] 
    },
    { 
      verifySiteDefinitionMatch:true 
    });
  }



}


export default Impact