import React from "react"
import BaseComponent from "../__application/BaseComponent"
import AddressFields from "./_AddressFields"
import Input from "../../shared/components/form/Input";
import styles from "./Billing.module.scss";
import classNames from "classnames";
import Checkbox from "../../shared/components/form/Checkbox";
import {getCardNumberForUpdate} from "../../shared/utils/getCardNumberForUpdate";

class Page extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = {
      model: {
        holder_name: "",
        ct_card_number: "",
        cvv: "",
        exp: "",
        exp_year: "",
        exp_month: "",
        address1: "",
        address2: "",
        country_name: "US",
        state: "",
        city: "",
        zip: "",
        primary_phone: "",
        errors: {}
      },
      classNames: {
        holder_name: "",
        credit_card: "",
        ct_card_number: "",
        cvv: "",
        exp: "",
        exp_year: "",
        exp_month: "",
        address1: "",
        address2: "",
        country_name: "",
        state: "",
        city: "",
        zip: "",
        primary_phone: ""
      },
      labels: {
        holder_name: "",
        credit_card: "",
        ct_card_number: "",
        cvv: "",
        exp: "",
        exp_year: "",
        exp_month: "",
        address1: "",
        address2: "",
        country_name: "",
        state: "",
        city: "",
        zip: "",
        primary_phone: ""
      },
      clientSupportsApplePay: false,
      addManuallyWithoutApplePay: false,
      formStatus: "filling"
    }
    this.checkIfClientSupportsApplePay()

    // This binding is necessary to make `this` work in the callback
    this.submitForm = this.submitForm.bind(this)
    this.toggleApplePayForm = this.toggleApplePayForm.bind(this)

    this.CCValidator = new validateCreditCard();
  }

  UNSAFE_componentWillMount() {
    let modelChanges = this.state

    Stripe.setPublishableKey(window.StripePublishablekey);
    Stripe.applePay.checkAvailability((available) => {
      let supported = available ? true : false
      if(supported){
        modelChanges.clientSupportsApplePay = supported
        this.setState(modelChanges)
      }
    });
  }

  handleChangeNew = (event) => {
    const mapping = {
      "credit_card[primary_phone]": "primary_phone",
      "credit_card[holder_name]": "holder_name",
      "credit_card[ct_card_number]": "ct_card_number",
      "credit_card[cvv]": "cvv",
      "credit_card[exp]": "exp",
      "credit_card[address1]": "address1",
      "credit_card[address2]": "address2",
      "credit_card[city]": "city",
      "credit_card[zip]": "zip",
      "credit_card[country_name]": "country_name",
      "credit_card[state]": "state",
    };
    const errorsOverride = {};
    let value = event.target.type === "checkbox" ? event.target.checked: event.target.value;
    let cardType = this.state.model.card_type
    if (event.target.name === "credit_card[exp]"){
      value = BaseComponent.prototype.ensureInputTypeExp.call(this, event)
      errorsOverride["exp_year"] = null;
      errorsOverride["exp_month"] = null;
      errorsOverride["expiration_date"] = null;
    }else if(event.target.name === 'credit_card[ct_card_number]'){
      let result = this.CCValidator.validate(value);
      if(result.card_type){
        cardType = result.card_type.name
      }

      value = getCardNumberForUpdate(event.target.value).cardNumber
    }

    this.setState({
      model: {
        ...this.state.model,
        errors: {
          ...this.state.model.errors,
          ...errorsOverride,
          [mapping[event.target.name]]: null,
        },
        [mapping[event.target.name]]: value,
        card_type: cardType,
      },
    });
  };

  checkIfClientSupportsApplePay(){
    Stripe.setPublishableKey(window.StripePublishablekey);
    Stripe.applePay.checkAvailability((available) => {
      let supported = available ? true : false
      this.state.clientSupportsApplePay = supported
    });
  }

  toggleApplePayForm(){
    let stateChange = this.state
    this.state.addManuallyWithoutApplePay = !this.state.addManuallyWithoutApplePay
    this.setState(stateChange)
  }

  handleApplePayClick(){
  }

  validateCVC(){
    let self = this;
    $('[name=ct_card_number]').validateCreditCard(function(result){
      if(result.card_type){
        let cardType = result.card_type.name;
        let stateChange = self.state
        stateChange.model.card_type = cardType
        self.setState(stateChange)
      }
    });
  }

  validateCC(cc, cvv, exp){
    let cc_pass = Stripe.card.validateCardNumber(cc)
    let cvv_pass = Stripe.card.validateCVC(cvv)
    let exp_pass = Stripe.card.validateExpiry(exp)
    let cc_type = Stripe.card.cardType(cc)

    let res = {
      ct_card_number: (cc_pass||_.isEmpty(cc)) ? null : "Your card number is invalid.",
      cvv: (cvv_pass||_.isEmpty(cvv)) ? null : "Your card's security code is incomplete.",
      exp: (exp_pass||_.isEmpty(exp)) ? null : "Your card's expiration date is incomplete."
    }

    return res
  }

  submitForm(event){
    event.preventDefault()
    // prefent double submit
    if(this.state.formStatus == "submitting"){
      return
    }

    let self = this
    let credit_card = self.state.model
    let address = {}

    // adjust exp
    let exp
    try{
      exp = credit_card.exp.split("/")
    } catch(e){
      exp = ["",""]
    }
    credit_card.exp_month = exp[0]
    credit_card.exp_year = `20${exp[1]}`


    // map fields
    let data = {
      credit_card: {
        holder_name:  credit_card.holder_name,
        ct_card_number: credit_card.ct_card_number,
        cvv: credit_card.cvv,
        exp_year: credit_card.exp_year,
        exp_month: credit_card.exp_month,
        address1: credit_card.address1,
        address2: credit_card.address2,
        country_name: credit_card.country_name,
        state: credit_card.state,
        city: credit_card.city,
        zip: credit_card.zip
      }
    }

    // adjust phone
    if(this.props.show_shipping){
      data.same_as_billing = false
    } else {
      data.same_as_billing = true
      if(!_.isEmpty(credit_card.primary_phone)){
        address['primary_phone'] = credit_card.primary_phone
        data.address = address
      }
    }

    // clear errors
    credit_card.errors = {}
    self.setState({
      model: credit_card,
      formStatus: "submitting"
    })


    $.ajax({
      url: '/registration/billing.json',
      type: 'POST',
      data: data,
      complete: (jqXHR, status)=>{
        let resp = $.parseJSON(jqXHR.responseText)

        let cc = resp.credit_card
        let err = cc.errors
        credit_card.errors = err

        // success
        if(_.isEmpty(err)){
          // location.href = resp.redirect_to
          self.props.fetchCards().then(()=>{
            self.props.fetchAddresses().then(() => {
              window.BINRecalculate()
            })
          })
          return
        }
        else{
          BaseComponent.prototype.normalizeErrorMessages.call(this, err)
          let classNames = self.state.classNames
          let labels = self.state.labels


          _.map(err, function(v,k){
            if(["holder_name", "address1", "address2", "city", "state", "zip", "primary_phone"].includes(k)){
              if(err[k]){
                classNames[k] = "error"
              }
              else{
                classNames[k] = ""
              }
            }
          })

          // CC error handling
          if(err.ct_card_number || err.cvv || err.exp_year || err.exp_month || err.expiration_date || err.auth_failure){
            classNames.credit_card = "error"
          }
          else{
            classNames.credit_card = ""
          }

          if(err.ct_card_number){
            labels.credit_card = "Please enter correct card number"
          }else{
            if(err.cvv){
              labels.credit_card = "Please enter correct CVV"
            }
            else{
              if(err.exp_year || err.exp_month){
                labels.credit_card = "Please enter correct expiration date"
              }else{
                if(err.expiration_date){
                  labels.credit_card = "Please enter a future expiration date"
                }
              }
            }
          }
          if(err.auth_failure){
            labels.credit_card = "Auth Failure"
          }


          self.setState({
            model: credit_card,
            classNames: classNames,
            labels: labels,
            formStatus: "filling"
          })

        }

      }
    })
  }

  render() {
    let shippingBtnText = "Save Credit Card"

    let submitButton = (<button className={`action-btn ${this.state.formStatus=="submitting" ? "disabled" : ""}`} onClick={this.submitForm}>{shippingBtnText}</button>)

    let formBody
    let hasApplePayButEnterManually = (this.state.clientSupportsApplePay && this.state.addManuallyWithoutApplePay)
    if(hasApplePayButEnterManually || !this.state.clientSupportsApplePay){
      formBody = (
        <div className="form-body">
            <div className={styles.inputWrapper}>
              <Input
                  type="text"
                  label="Name on Card"
                  name="credit_card[holder_name]"
                  autoComplete="cc-name"
                  value={this.state.model.holder_name}
                  error={this.state.model.errors.holder_name}
                  onChange={this.handleChangeNew}
              />
            </div>
            <div className={styles.inputWrapper}>
              <Input
                  label="Card number"
                  type="text"
                  inputMode="tel"
                  name="credit_card[ct_card_number]"
                  pattern="[0-9]*"
                  maxLength="19"
                  autoComplete="cc-number"
                  onChange={this.handleChangeNew}
                  value={this.state.model.ct_card_number}
                  error={this.state.model.errors.ct_card_number}
              />
            </div>
          <div className={classNames(styles.inputWrapper, styles.inputMultipleLine)}>
              <Input
                  name="credit_card[cvv]"
                  label="CVV"
                  type="text"
                  inputMode="tel"
                  autoComplete="cc-csc"
                  maxLength={this.state.model.card_type=='amex' ? 4 : 3}
                  value={this.state.model.cvv}
                  onChange={this.handleChangeNew}
                  error={this.state.model.errors.cvv}
                  width="48%"
              />

              <Input
                  name='credit_card[exp]'
                  label="MM/YY"
                  inputMode="tel"
                  type="text"
                  autoComplete="cc-exp"
                  value={this.state.model.exp}
                  onChange={this.handleChangeNew}
                  error={this.state.model.errors.expiration_date||this.state.model.errors.exp_month||this.state.model.errors.exp_year}
                  width="48%"
              />
          </div>

          <AddressFields geo_data={this.props.geo_data} state={this.state} handleChange={this.handleChangeNew} namespace={"credit_card"}/>

          <div className="form-row row">
            <div className="form-helper-text">
              <div className={styles.inputWrapper}>
                <Checkbox
                    name="same_as_billing"
                    className="same-as-billing"
                    checked={!this.props.model.show_shipping}
                    label="My shipping address is same as my billing address."
                    onChange={this.props.invertShowShipping}
                />
              </div>

              <br/>

              <div className={styles.inputWrapper}>
                <Input
                    type="text"
                    label="Primary Phone"
                    name="credit_card[primary_phone]"
                    autoComplete="tel"
                    value={this.state.model.primary_phone || ""}
                    onChange={this.handleChangeNew}
                />
              </div>
            </div>
          </div>

          <div>
            {submitButton}
          </div>


        </div>
      )
    }
    else{
      formBody = (
        <div className="form-body">
          <div className="apple-pay-toggle-wrapper">
            <div className="apple-pay-toggle-text">Add card manually</div>
            <div>
              <button className="apple-pay-toggle-btn" onClick={this.toggleApplePayForm}>ADD CARD</button>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="">
        <div className={`form-info show`}>
          <div className="form-info-title">Billing Information</div>
          <div className={`form-info-text ${this.state.clientSupportsApplePay ? "" : "hide"}`}>
          </div>
        </div>

        {formBody}

      </div>
    );
  }
}

export default Page
