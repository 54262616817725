import React from 'react';
import IconLive from "../svg/IconLive"

class BannerCBLive extends React.Component {
  render() {
    return (
      <div className="banner__cb-live">
        <div className="wrapper">
          <IconLive />
          <p>
            Charitybuzz LIVE is happening now! 
            &nbsp;
            <a href="/live">Join us in the auction room
              <div className="icon-wrapper"><i className="fal fa-angle-right"> </i></div>
            </a>
          </p>
        </div>
      </div>
    )
  }
}

export default BannerCBLive;