import React from 'react'
import Search from './services/Search'
import Tracker from './services/Tracker'
import SearchBar from './components/SearchBar'
import DepartmentSel from './components/DepartmentSelNested'
import DetailSel from './components/DetailSel'
import BuyingFormatSel from './components/BuyingFormatSel'
import AdvancedFilters from './components/AdvancedFilters'
import PullUpFilters from './components/PullUpFilters'
import NoResults from './components/NoResults'
import FoundLots from './components/FoundLots'
import FiltersApplied from './components/FiltersApplied'
import SortBy from './components/SortBy'

class Page extends React.Component {
  constructor(props){
    super(props)
    this.searchService = new Search()
    this.trackerService = new Tracker()

    let filters = this.searchService.initFilters({props: props})
    filters.sort_by.query.code="_text_match:desc"

    this.state = {
      show_full_bar: true,
      is_on: false,
      is_suggests_on: false,
      is_tips_on: false,
      is_searching: false,
      is_relevancy_filter_enabled: true,

      is_advanced_filter_on: props.is_advanced_filter_on || false,
      save_search_btn_txt: 'Create Search Alert',
      filters: filters,
      results: {},
      results_temp: null
    }

    this.updateFiltersFromUrl = this.updateFiltersFromUrl.bind(this)
    this.changeState = this.changeState.bind(this)
    this.search = this.search.bind(this)
    this.searchDropdown = this.searchDropdown.bind(this)
    this.handleAdvFiltersClicked = this.handleAdvFiltersClicked.bind(this)
    this.handleMouseClick = this.handleMouseClick.bind(this)
    this.foundHtml = this.foundHtml.bind(this)
    this.notFoundHtml = this.notFoundHtml.bind(this)
    this.listenGlobalEvents = this.listenGlobalEvents.bind(this)

    this.updateFiltersFromUrl(filters)
    this.listenGlobalEvents()
    this.search({track_page_view: false, track_results: true})
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    if(this.state.is_on){
      $(".main-yield").addClass("with-search-full-screen")
      $(".mobile-navigation-bar1").addClass("with-search-full-screen")
      $(".mobile-navigation.nav-2").addClass("with-search-full-screen")
      $(".nav-search").addClass("with-search-full-screen")
      $("body").addClass("no-scroll-for-search")
    }
    else{
      $(".main-yield").removeClass("with-search-full-screen")
      $(".mobile-navigation-bar1").removeClass("with-search-full-screen")
      $(".mobile-navigation.nav-2").removeClass("with-search-full-screen")
      $(".nav-search").removeClass("with-search-full-screen")
      $("body").removeClass("no-scroll-for-search")
    }

    // history.pushState(null, null, `/search?keywords=${this.state.filters.query}`)
    $(document).foundation()
  }

  listenGlobalEvents(){
    const self = this
    window.onpopstate = function(event) {
      console.log("pop", location.search)
      let filters = self.state.filters
      self.updateFiltersFromUrl(filters)
      self.search({update_url: false})
    }
  }

  updateFiltersFromUrl(filters){
    let urlParams = new URLSearchParams(window.location.search)
    let queryStr = urlParams.toString()
    this.searchService.applyQueryStrToFilters({filters: filters, queryStr: queryStr})
  }

  componentDidMount() {
  	window.addEventListener("click", this.handleMouseClick)
  }

  componentWillUnmount() {
  	window.removeEventListener("click", this.handleMouseClick)
  }

  changeState(newState){
    this.setState(newState)
  }

  handleMouseClick(e){
    const wrapper1 = $(e.target).closest(".filter-item")
    const wrapper2 = $(e.target).closest(".filter-sort")
    const withinFilterItem = (wrapper1.length>0 || wrapper2.length>0)
    if(!withinFilterItem){
      let state = this.state
      let filters = state.filters
      filters.sort_by.on = false
      filters.departments.on = false
      filters.details.on = false
      filters.orgs.on = false
      filters.themes.on = false
      this.changeState(state)
    }
  }

  handleAdvFiltersClicked(){
    this.state.is_advanced_filter_on = !this.state.is_advanced_filter_on
    this.changeState(this.state)

    const isMobileView = window.innerWidth < 640
    const body = document.querySelector("body")
    if(this.state.is_advanced_filter_on){
      if(isMobileView){
        let elem = this.popupFilter
        if(!elem){
          elem = document.querySelector(".component.drawer.slide-up")
          this.popupFilter = elem
        }
        elem.classList.add('open')
        body.classList.add('prevent-scroll')
      }
    }
    else{
      const elem = this.popupFilter
      if(elem){
        elem.classList.remove('open')
        body.classList.remove('prevent-scroll')
      }
    }
  }

  searchDropdown(){
    const self = this
    let state = self.state
    let tempFilters = {
      query: state.filters.query_temp || '',
      pagination: {
        page: 1,
        per_page: 5
      }
    }
    state.is_searching = true
    return self.searchService.queryApi({filters: tempFilters}).then(res => {
      // let state = self.state
      state.results_temp = res.data
      // state.results = res.data
      // self.searchService.adjustFiltersFromResults(state.results_temp, state.tempFilters)
      state.is_searching = false
      self.changeState(state)

      const keyword = state.results_temp.term
      self.trackerService.trackAutoSuggests({results: state.results, keyword})
    })
  }

  search({track_page_view=true, track_results=true, update_url=true, m}){
    const self = this
    let state = m || self.state
    let filters = state.filters
    state.is_searching = true
    return self.searchService.queryApi({filters: filters, updateUrl: update_url, searchPath: self.props.search_path}).then(res => {
      // let state = self.state
      state.results = res.data
      self.searchService.adjustFiltersFromResults(state.results, state.filters)
      state.is_searching = false
      self.changeState(state)

      if(track_page_view){
        const url = `${self.props.search_path}?keywords=${filters.query||''}`
        self.trackerService.trackPageView({url})
      }
      if(track_results){
        self.trackerService.trackSearchResults({results: state.results})
      }
    })
  }

  foundHtml(){
    let found = 0
    try{
      found = this.state.results.pagination.total_objects
    }catch(err){
      found = 0
    }

    const filters = this.state.filters
    const hideFilters = !_.isEmpty(filters.themes.locked)

    const seeClosedLotsToolTip = "<b>Looking for closed lots?</b> <br/>Closed lots aren’t quite ready for our new search experience, just yet. Click this link to view closed lots in the old experience!"

    let html = (
      <div className={`search-results-container grid-x ${!this.props.hide_search_bar ? 'with-pt' : ''}`}>

        <div className="result-summary cell medium-12 grid-x grid-padding-x">
          { !this.props.hide_search_bar
            ?
            (
              <div className="found-count cell medium-12 text-center">
                <span>{found} search results</span>
                &nbsp;
                <span>
                  (
                    <a href={`/search?keywords=${filters.query||''}&v=1&closed=1`}>View Closed Lots</a>
                    &nbsp;
                    <i className="tooltip-for-link fal fa-info-circle has-tip bottom" data-allow-html="true" data-tooltip aria-haspopup="true" data-disable-hover="false" title={seeClosedLotsToolTip}></i>
                  )
                </span>
              </div>
            )
            :
            ''
          }

          <div className="result-showing cell small-12 medium-8">
            { !this.props.hide_search_bar
              ?
              (
                <span>
                  Showing <b>"{this.state.filters.query}"</b> lots with ...
                </span>
              )
              :
              (
                <span>
                  {found} search results
                </span>
              )
            }
          </div>

          <div className="cell small-12 medium-4 text-center">
            {
              hideFilters
              ?
                ''
              :
              (<button 
                className={"btn-adv-filters " + (this.state.is_advanced_filter_on ? 'active' : '')}
                onClick={this.handleAdvFiltersClicked}
                aria-label={this.state.is_advanced_filter_on ? 'Hide advanced search filters' : 'Show all search filters'}
              >
                <i className="fal fa-filter fa-align-center"></i>
                <span className="btn-adv-filters__text">{this.state.is_advanced_filter_on ? 'Hide advanced search filters' : 'Show all search filters'}</span>
              </button>)
            }
          </div>
        </div>

        <div className="simple-filters cell medium-12 grid-x grid-padding-x show-for-medium">
          <div className="filter-item departments cell medium-4">
            <DepartmentSel model={this.state} changeState={this.changeState} search={this.search} />
          </div>
          <div className="filter-item details cell medium-4">
            <DetailSel model={this.state} changeState={this.changeState} search={this.search} />
          </div>
          <div className="filter-item price-range cell medium-4">
            <BuyingFormatSel model={this.state} changeState={this.changeState} search={this.search} />
          </div>
        </div>

        <div className="simple-filters cell medium-12 grid-x grid-padding-x hide-for-medium">
          <div className="filter-item price-range cell medium-4">
            <BuyingFormatSel model={this.state} changeState={this.changeState} search={this.search} />
          </div>
        </div>


        <div className="adv-filters cell medium-12">
          <div className={`show-for-medium ${this.state.is_advanced_filter_on ? '' : 'hide'}`}>
            <AdvancedFilters model={this.state} changeState={this.changeState} search={this.search}/>
          </div>
          <div className="hide-for-medium">
            <PullUpFilters model={this.state} changeState={this.changeState} search={this.search} onCancel={this.handleAdvFiltersClicked}/>
          </div>
        </div>

        <div className="lot-results cell medium-12">
          <div className="lot-filters">

            <div className="grid-x">
              <div className="f-title cell medium-12">
                Filters applied:
              </div>
              <div className="f-list cell medium-7 large-8">
                <FiltersApplied model={this.state} changeState={this.changeState} search={this.search} />
              </div>
              <div className="filter-sort f-sort-list cell medium-5 large-4">
                <SortBy model={this.state} changeState={this.changeState} search={this.search} />
              </div>
            </div>
            
          </div>
          <FoundLots model={this.state} changeState={this.changeState} search={this.search}/>
        </div>

      </div>
    )

    return html
  }

  notFoundHtml(){
    let html = (
      <div className={`search-results-container no-results ${!this.props.hide_search_bar ? 'with-pt' : ''}`}>
        <NoResults model={this.state} changeState={this.changeState} search={this.search} />
      </div>
    )

    return html
  }

  render(){
    let found = 0
    let foundHtml = this.foundHtml()
    let notFoundHtml = this.notFoundHtml()

    const hasFilters = this.searchService.hasStrictFilters({filters: this.state.filters})
    try{
      found = this.state.results.pagination.total_objects
    }catch(err){
      found = 0
    }

    let resultHtml = ""
    if(!_.isEmpty(this.state.results)){
      if(this.state.is_searching){
        $('.search-no-results-extra-content').addClass('hidden')
        resultHtml = foundHtml
      }
      else{
        if(found > 0){
          $('.search-no-results-extra-content').addClass('hidden')
          resultHtml = foundHtml
        }
        else{
          if(hasFilters){
            $('.search-no-results-extra-content').addClass('hidden')
            resultHtml = foundHtml
          }
          else{
            $('.search-no-results-extra-content').removeClass('hidden')
            resultHtml = notFoundHtml
          }
        }
      }
    }

    let searchBarHtml = ""
    if(!this.props.hide_search_bar){
      searchBarHtml = (
        <div className="search-input-wrapper container grid-x">
          <SearchBar model={this.state} changeState={this.changeState} searchDropdown={this.searchDropdown} search={this.search} />
        </div>
      )
    }

    return (
      <React.Fragment>
        {searchBarHtml}
        {resultHtml}
      </React.Fragment>
    )
  }
}

export default Page
