import React from "react";
import Search from "./services/Search";
import ListingFilters from "./components/ListingFilters";
import ItemCard from "./components/ItemCard/ItemCard";

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.searchService = new Search();

    this.state = {
      is_searching: false,
      filters: props.filters || {},
      page_type: props.page_type,
      results: {
        catalog_items: [],
        pagination: {
          page: 1,
          per_page: 48,
          total_pages: 0,
          total_objects: 0,
        },
      },
    };

    this.changeState = this.changeState.bind(this);
    this.search = this.search.bind(this);
    this.listenGlobalEvents = this.listenGlobalEvents.bind(this);
    this.lotListHtml = this.lotListHtml.bind(this);

    // this.updateFiltersFromUrl(filters)
    this.listenGlobalEvents();
  }

  updateResultsFromUrl(filters) {
    let urlParams = new URLSearchParams(window.location.search);
    let queryStr = urlParams.toString();
    this.searchService.applyQueryStrToFilters({
      filters: filters,
      queryStr: queryStr,
    });
  }

  listenGlobalEvents() {
    const self = this;

    document.addEventListener("update-listing-cards", function (event) {
      const catalog_items = event.detail.data.catalog_items;
      const lots = self.parseCardsJson(catalog_items);
      const pagination = event.detail.pagination;
      let state = self.state;
      if (pagination.page > 1) {
        state.results.catalog_items = _.concat(
          state.results.catalog_items,
          lots
        );
      } else {
        state.results.catalog_items = lots;
        if (location.search.includes("hide=carousel")) {
          $("html, body").animate(
            { scrollTop: $(".lot-cards").offset().top - 200 },
            "fast"
          );
        }
      }
      state.results.pagination = pagination;
      self.setState(state);
    });

    window.addEventListener("popstate", function (event) {
      // self.updateFiltersFromUrl(self.state.filters)
      self.setState({ filters: self.state.filters });
    });
  }

  parseCardsJson(cards) {
    let newJson = [];
    for (let i = 0; i < cards.length; i++) {
      const card = cards[i];
      // const closeTime = card.close_time ? moment(card.close_time).unix() : null
      // const currentPrice = parseInt(card.current_price)
      // let item = {
      //   id: card.id,
      //   pretty_id: card.pretty_id,
      //   title: card.name,
      //   buying_format: "",
      //   close_time: closeTime,
      //   current_price: "",
      //   estimated_price: currentPrice,
      //   lot_image: card.primary_image_thumb,
      //   lot_type: "",
      //   quantity: card.bin_qty_available,
      //   status: []
      // }
      newJson.push(card.v2);
    }
    return newJson;
  }

  changeState(newState) {
    this.setState(newState);
  }

  search({ m }) {
    const model = m || this.state;
    const filters = model.filters;
    const low = filters.price.low || "";
    const high = filters.price.high || "";
    const bf = filters.buying_format.code || "";
    const sort_opt = filters.sort_by.query.code || "";
    const sort_by = sort_opt.split(":")[0] || "";
    const sort_direction = sort_opt.split(":")[1] || "";
    const current_path = location.pathname;
    const qs = `sort_direction=${sort_direction}&sort_by=${sort_by}&buying_format=${bf}&lr=${low}&ur=${high}`;
    const url = `${current_path}?${qs}`;

    let event = new CustomEvent("route-to", {
      detail: { url: url, options: { trigger: true } },
    });
    document.dispatchEvent(event);
  }

  lotListHtml() {
    const self = this;
    const model = self.state;
    const results = model.results;
    const lots = results.catalog_items;
    const keyword = model.page_type || 'ListingPage';

    const pagination = "";
    const page = results.pagination.page;
    let lotList = [];

    const lot_count = lots.length;
    for (let i = 0; i < lot_count; i++) {
      const pos = i + 1;
      const lot = lots[i];
      const meta = { lot_count, pos, page, keyword };
      meta.showIntro = pos == 1;
      let lotCardHtml = <ItemCard item={lot} meta={meta} key={lot.id} />;
      lotList.push(lotCardHtml);
    }

    let html = (
      <div>
        <div className="lot-cards grid-x grid-margin-x">{lotList}</div>
        <div className="lot-pagination text-center">{pagination}</div>
      </div>
    );

    return html;
  }

  render() {
    const state = this.state;
    const total = state.results.pagination.total_objects;

    const lotsHtml = this.lotListHtml();

    return (
      <div>
        <ListingFilters filters={state.filters} />
        <div className="">{lotsHtml}</div>
      </div>
    );
  }
}

export default Page;
