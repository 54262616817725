import React from "react"
import Tracker from '../services/Tracker'

class Page extends React.Component {
  constructor(props) {
    super(props)
    this.trackerService = new Tracker()
  }

  onItemClicked({event, category}){
    this.trackerService.trackCategoryClick({slug: category.slug})
  }

  render(){
    const self = this
    const cats = self.props.categories
    let list = []
    for(const c of cats){
      list.push(
        <div className="cat-item-wrapper" key={c.slug}>
          <div className="cat-item" onClick={(event)=>self.onItemClicked({event, category:c})}>
            <div className="cat-img">
              <a href={`/categories/${c.slug}`} style={{"backgroundImage": `url(${c.image})`}}>
              </a>
            </div>
            <div className="cat-body">
              <a href={`/categories/${c.slug}`}>
                <div className="cat-icon">
                  <i className={c.icon}></i>
                </div>
                <div className="cat-name">
                  {c.name}
                </div>
              </a>
            </div>
          </div>
        </div>
      )
    }

    let html = (
      <div className="cat-row">
        <div className="cat-row-title">
          Explore By Category
        </div>
        <div className="cat-row-body">
          {list}
        </div>
      </div>
    )

    return html
  }
}

export default Page
