import React from 'react';
import ListingFilters from '../search/components/ListingFilters';
import MegaMenu from '../header/MegaMenu';

class FixedHeader extends React.Component {
  constructor(props) {
    super(props)

    this.dataCategories = props.categories
    this.dataThemes = props.themes
    this.state = {
      hasScrolled: false,
      isVisible: false
    }

    this.setVisibility = this.setVisibility.bind(this)
    this.handleExploreClick = this.handleExploreClick.bind(this)
  }

  componentDidMount() {
    if(this.props.attachTo == 'sticky-top'){
      window.addEventListener('scroll', this.handleScroll)
    }
    else if(this.props.attachTo == 'nav-bar'){
      $(".nav-explore").bind("click", this.handleExploreClick)
      $(".explore-lots").bind("click", this.handleExploreClick)
    }
  }

  handleScroll = (event) => {
    const scrollTop = window.pageYOffset;
    const listingItemsTop = document.querySelector('.category-items-wrapper').getBoundingClientRect();
    //Show fixed header after user scrolls past top of lot cards section (beyond filters) or past 1000px from top of site for fallback
    if(listingItemsTop.top <= 0 || scrollTop >= 1000) {
      this.setState({ hasScrolled: true })
    } else {
      this.setState({ hasScrolled: false, isVisible: false })
    }
  }

  currentPageType(){
    const page = window.location.href
    const location_array = page.split('/')
    if (location_array[3] == '#'){
      return 'homepage'
    } else {
      return location_array[3]
    }
  }

  handleExploreClick = (event) => {
    event.stopPropagation();
    if(this.state.isVisible){
      // $(".nav-explore-up-down").removeClass("icon-chevron-up").addClass("icon-chevron-down");
      $(".nav-explore-up-down").removeClass("fa-angle-up").addClass("fa-angle-down");
      $(".up-down-symbol").removeClass("fa-angle-up").addClass("fa-angle-down");
      // $(".explore-lots").removeClass("up").addClass("down");
      // $(".mobile-navigation .explore-lots down").removeClass("up").addClass("down");
    }
    else{
      // $(".nav-explore-up-down").removeClass("icon-chevron-down").addClass("icon-chevron-up");
      $(".nav-explore-up-down").removeClass("fa-angle-down").addClass("fa-angle-up");
      $(".up-down-symbol").removeClass("fa-angle-down").addClass("fa-angle-up");
      // $(".explore-lots").removeClass("down").addClass("up");
      // $(".mobile-navigation .explore-lots up").removeClass("down").addClass("up");

      var eventLabel = this.currentPageType()
      ga('send', {
          'hitType': 'event',
          'eventCategory': 'Top Nav Explore Activity',
          'eventAction': 'Open Explore Click',
          'eventLabel': eventLabel
        });

      dataLayer.push({
          'event': 'ua_event',
          'event_category': 'Top Nav Explore Activity',
          'event_action': 'Open Explore Click',
          'event_label': eventLabel
        });
    }

    this.setState(prevState => ({
      isVisible: !prevState.isVisible
    }))

    // mobile window
    if(window.innerWidth < 640){
      if(this.state.isVisible){
          $("body").addClass("prevent-scroll")
      }
      else{
        $("body").removeClass("prevent-scroll")
      }
    }
  }

  setVisibility = (visible) => {
    this.setState({
      isVisible: visible
    })
  }

  render() {
    return (
      <React.Fragment>
        <div className={ this.state.hasScrolled ? 'fixed-header active' : 'fixed-header' }>
          <div
          id="mega-menu-open"
          className={'explore-lots-wrapper ' + (this.state.isVisible ? 'active' : '')}
          onClick={this.handleExploreClick}
          >
            <div 
            className="explore-lots-wrapper__explore-lots"
            >
            {/* Explore Lots <i className={this.state.isVisible ? 'fal fa-angle-up' : 'fal fa-angle-down' }></i> */}
            Explore Lots <i className="fal fa-angle-down"></i>
            </div>
          </div>
          <ListingFilters attachTo={this.props.attachTo}/>
        </div>
        <MegaMenu 
          isVisible={this.state.isVisible}
          setVisibility={this.setVisibility}
          categories={this.dataCategories}
          themes={this.dataThemes}
          attachTo={this.props.attachTo}
          navClass={this.props.navClass}
        />
      </React.Fragment>
    )
  }
}

export default FixedHeader;