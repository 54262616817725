import React from "react"
import BaseComponent from "../__application/BaseComponent"
import RewardTimer from "./_RewardTimer"
import StatusNav from "./_StatusNav"
import Profile from "./_Profile"

class Page extends BaseComponent {
  render() {
    let referCopy
    if(this.props.refer && this.props.refer.copy){
      referCopy = (
        <div className="reg-alert">{this.props.refer.copy}</div>
      )
    }

    return (
        <div>

          {referCopy}

          <div className="reg2 new-reg profile-step grid-x grid-margin-x">
            <RewardTimer />

            <div className="reg2-page-title cell medium-12">Create your Charitybuzz Account</div>

            <div className="cell medium-6">
              <StatusNav step="profile"/>
            </div>

            <div className="cell medium-6">
              <Profile refer={this.props.refer} />
            </div>
          </div>

        </div>
      )
  }
}

export default Page
