import React from "react";
import styles from "./Input.module.scss";

export const InputCheckout = ({
  label,
  name,
  value,
  onChange,
  onKeyPress,
  type = "text",
}) => {
  const onLabelClick = () => {};

  const id = "id-" + name;
  return (
    <div className={styles.wrapper}>
      <div className={styles.inputWrapper}>
        <label htmlFor={id} className={styles.label} onClick={onLabelClick}>
          {label}
        </label>
        <input
          id={id}
          name={name}
          type={type}
          value={value}
          onChange={onChange}
          onKeyPress={onKeyPress}
          className={styles.input}
        />
      </div>
      <div className={styles.error}>as</div>
    </div>
  );
};
