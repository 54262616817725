import React from "react"
import OrgSel from './OrgSel'
import ThemeSel from './ThemeSel'
import DonationTypeSel from './DonationTypeSel'
import PriceSel from './PriceSel'

class Page extends React.Component {
  constructor(props) {
    super(props)
  }

  render(){
    const self = this
    let list = []
    const model = self.props.model
    const filters = model.filters
    const results = model.results.results
    const hideFilters = !_.isEmpty(filters.themes.locked)

    let html = ""

    html = (
      <div className="grid-x grid-padding-x">
        {
          hideFilters
          ?
            ''
          :
          (<div className="filter-item orgs cell medium-4">
            <OrgSel model={self.props.model} changeState={self.props.changeState} search={self.props.search} />
          </div>)
        }

        {
          hideFilters
          ?
            ''
          :
          (<div className="filter-item themes cell medium-4">
            <ThemeSel model={self.props.model} changeState={self.props.changeState} search={self.props.search} />
          </div>)
        }

        {/* {
          hideFilters
          ?
            ''
          :
          (<div className="filter-item types cell medium-4">
            <DonationTypeSel model={self.props.model} changeState={self.props.changeState} search={self.props.search} />
          </div>)
        } */}

        {
          hideFilters
          ?
            ''
          :
          (<div className="filter-item types cell medium-4">
            <PriceSel model={self.props.model} changeState={self.props.changeState} search={self.props.search} />
          </div>)
        }

        
      </div>
    )
    return html
  }
}

export default Page
