import React from "react"

class Page extends React.Component {
  constructor(props) {
    super(props)
  }

  init(){
    // $('a.menu-link').bigSlide({
    //   "menuWidth": '50%',
    //   "callback": function() {
    //     // WHEN CATEGORY DRAWER OPEN, TOGGLE CHEVRON TO POINT LEFT OR RIGHT
    //     $( this ).toggleClass( "open" );
    //     var drawerOpen      = ($("a.menu-link.open").size()  > 0),
    //         chevLeft        = $(".icon-chevron-left"),
    //         chevRight       = $(".icon-chevron-right")

    //     if (drawerOpen) {
    //       chevLeft.addClass("icon-chevron-right")
    //       chevLeft.removeClass("icon-chevron-left")
    //     } else {
    //       chevRight.addClass("icon-chevron-left")
    //       chevRight.removeClass("icon-chevron-right")
    //     }
    //   }
    // });
  }
 
  componentDidMount() {
    // this.init()
  }

  render(){
    
    let html = (
      <div className="f-menu-btn hide-for-medium">
        <div className="toggle-left-nav">
          <a href="#menu" className="menu-link">
            <span className="category-name"></span>
          </a>
        </div>
      </div>
    )
    return html
  }
}

export default Page
