import React from "react"
import BaseComponent from "../__application/BaseComponent"
import Input from "../../shared/components/form/Input";
import stylesBillingBilling from "./Billing.module.scss"
import Select from "../../shared/components/form/Select";
import classNames from "classnames";

class Page extends BaseComponent {

  constructor(props) {
    super(props)
    let geo_data = JSON.parse(JSON.stringify(this.props.geo_data))
    geo_data.countries = _.filter(geo_data.countries, function(v){return v[1]!='US' && v[1]!='-' && v[1]!=''})
    geo_data.countries.unshift(["United States", "US"], ["--------", "-"])

    geo_data.states.us = _.filter(geo_data.states.us, function(v){return v[1]!=''})
    geo_data.states.us.unshift(["Select State", ""])
    geo_data.states.canada = _.filter(geo_data.states.canada, function(v){return v[1]!=''})
    geo_data.states.canada.unshift(["Select State", ""])
    this.state = {
      parent: this.props.state,
      geo_data: geo_data,
      states_list: []
    }

    this.state.states_list = this.adjustStatesList(false)

    this.handleChange = this.props.handleChange

    // This binding is necessary to make `this` work in the callback
    this.handleChangeCountry = this.handleChangeCountry.bind(this);

    this.handleChange = this.handleChange.bind(this);
  }


  handleChangeCountry(event){
    this.handleChange(event)
    this.adjustStatesList(true, event.target.value)
  }

  adjustStatesList(updateState=true, countryName = this.state.parent.model.country_name){
    let states_list_change;

    if(countryName === 'US'){
      states_list_change = this.state.geo_data.states.us
    }
    else{
      states_list_change = []
    }

    let parent = this.state.parent
    parent.model.state = ""

    if(updateState){
      this.setState({
        parent: parent,
        states_list: states_list_change
      })
    }
    return states_list_change
  }


  render() {
    let stateDom
    if(this.state.states_list.length === 0){
      stateDom = (
          <Input
              label="State"
              type="text"
              name={`${this.props.namespace}[state]`}
              onChange={this.handleChange}
              value={this.props.state.model.state}
              autoComplete="address-level1"
              width="48%"
          />
      )
    }
    else{
      stateDom = (
          <Select
              name={`${this.props.namespace}[state]`}
              label="State"
              value={this.props.state.model.state}
              values={this.state.states_list.map((val) => ({
                label: val[0],
                value: val[1],
              }))}
              autoComplete="address-level1"
              onChange={this.handleChange}
              width="48%"
          />)
    }

    return (
      <div>
        <div className={stylesBillingBilling.inputWrapper}>
          <Input
              label="Address"
              type="text"
              name={`${this.props.namespace}[address1]`}
              autoComplete="address-line1"
              onChange={this.handleChange}
              value={this.props.state.model.address1}
              error={this.props.state.model.errors.address1}
          />
        </div>
        <div className={stylesBillingBilling.inputWrapper}>
          <Input
              label="Apt, Suite, Bldg., etc."
              autoComplete="address-line2"
              type="text"
              name={`${this.props.namespace}[address2]`}
              onChange={this.handleChange}
              value={this.props.state.model.address2}
              error={this.props.state.model.errors.address2}
          />
        </div>
        <div className={classNames(stylesBillingBilling.inputWrapper, stylesBillingBilling.inputMultipleLine)}>
          <Select
              name={`${this.props.namespace}[country_name]`}
            label="Country"
            value={this.props.state.model.country_name}
            values={this.state.geo_data.countries.map((val) => ({
              label: val[0],
              value: val[1],
            }))}
            autoComplete="country"
            onChange={this.handleChangeCountry}
            width="48%"
        />
          {stateDom}
        </div>
        <div className={stylesBillingBilling.inputWrapper}>
          <Input
              label={"City"}
              type="text"
              name={`${this.props.namespace}[city]`}
              autoComplete="home city"
              onChange={this.handleChange}
              value={this.props.state.model.city}
              error={this.props.state.model.errors.city}
          />
        </div>
        <div className={stylesBillingBilling.inputWrapper}>
          <Input
              label={"Zip"}
              type="text"
              autoComplete="postal-code"
              name={`${this.props.namespace}[zip]`}
              onChange={this.handleChange}
              value={this.props.state.model.zip}
              error={this.props.state.model.errors.zip}
          />
        </div>
      </div>
    );
  }
}

export default Page
