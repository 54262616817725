const KeySearchHistory = 'SEARCH-SEARCH-HISTORY'
const KeyViewHistory = 'SEARCH-VIEW-HISTORY'
const PriceRangeLow = 0
const PriceRangeHigh = 1000000
const qs = require('qs')

class Search {
  getSearchHistory(){
    return amplify.store(KeySearchHistory) || []
  }

  getViewHistory(){
    return amplify.store(KeyViewHistory) || []
  }

  queryApi({filters, updateUrl=true, searchPath=false}){
    let f = {}
    const defaultPriceRange = [PriceRangeLow, PriceRangeHigh]

    if(filters.departments){
      const parseJsonToArray = (json, key) => {
        let arr = []
        if(!_.isEmpty(json)){
          for(let i in json){
            if(json[i]){
              arr.push(i)
            }
          }
          if(!_.isEmpty(arr)){
            f[key] = arr
          }
        }
      }
      parseJsonToArray(filters.departments.selected, 'categories')
      parseJsonToArray(filters.themes.selected, 'themes')
      parseJsonToArray(filters.orgs.selected.orgs, 'org_names')
      parseJsonToArray(filters.orgs.selected.causes, 'cause')
      parseJsonToArray(filters.details.selected.people, 'people')
      parseJsonToArray(filters.details.selected.place, 'place')
      parseJsonToArray(filters.details.selected.activity, 'activity')

      if(!_.isEmpty(filters.donation_type.code)){
        f.lot_type = [filters.donation_type.code]
      }

      if(!_.isEmpty(filters.buying_format.code)){
        f.buying_format = [filters.buying_format.code]
      }

      if(!_.isEmpty(filters.sort_by.query.code)){
        const sortOpts = filters.sort_by.query.code.split(":")
        f.sort_by = sortOpts[0]
        f.sort_direction = sortOpts[1]
      }

      f.current_price = [PriceRangeLow, PriceRangeHigh]
      if(filters.price){
        if(filters.price.low){
          try{
            f.current_price[0] = parseInt(filters.price.low)
          }catch(err){}
        }
        if(filters.price.high){
          try{
            f.current_price[1] = parseInt(filters.price.high)
          }catch(err){}
        }
        if(f.current_price[0] == defaultPriceRange[0] && f.current_price[1] == defaultPriceRange[1]){
          delete(f.current_price)
        }
      }
    }

    if(!_.isEmpty(filters.entity_type)){
      f.entity_type = filters.entity_type
    }
    if(!_.isEmpty(filters.entity_name)){
      f.entity_name = filters.entity_name
    }

    let params = {
      keywords: filters.query || "*",
      filters: f
    }

    if(filters.ab){
      params.default = 1
    }

    if(updateUrl && searchPath){
      this.updateUrlByFilters({filters: params, path: searchPath})
    }

    params.filter_type ="json"
    params.q = params.keywords
    params.page = filters.pagination.page
    params.per_page = filters.pagination.per_page
    delete(params.keywords)
    return axios.get(`/api/catalog_items/fullsearch.json`, {params: params})
  }

  updateUrlByFilters({filters, path}){
    const str = qs.stringify(filters)
    let url = `${path}?${str}`
    console.log("push", url)
    history.pushState(null, null, url)
  }

  hasStrictFilters({filters}){
    let selected = [
      filters.departments.selected,
      filters.details.selected.people,
      filters.details.selected.place,
      filters.details.selected.activity,
      filters.orgs.selected.orgs,
      filters.orgs.selected.causes,
      filters.themes.selected,
      filters.donation_type.code,
      filters.buying_format.code
    ]
    let hasSelected = false
    for(let f of selected){
      if(!_.isEmpty(f)){
        hasSelected = true
      }
    }
    if(!hasSelected){
      if(filters.price.low != PriceRangeLow || filters.price.high != PriceRangeHigh){
        hasSelected = true
      }
    }

    return hasSelected
  }

  initFilters({props={}}){
    let filters = {
      query: '',
      query_temp: '',
      pagination: {
        page: 1,
        per_page: 100
      },
      custom_sort_enabled: false,
      sort_by: {
        on: false,
        query: {
          // code: 'best_content:desc'
          code: 'close_date:asc'
        }
      },
      departments: {
        on: false,
        query: '',
        selected: {}
      },
      details: {
        on: false,
        query: '',
        selected: {
          people: {},
          place: {},
          activity: {}
        }
      },
      price: {
        low: PriceRangeLow,
        high: PriceRangeHigh
      },
      orgs: {
        on: false,
        query: '',
        selected: {
          orgs: {},
          causes: {}
        }
      },
      themes: {
        on: false,
        query: '',
        selected: {},
        locked: {}
      },
      donation_type: {
        code: ''
      },
      buying_format: {
        code: '',
        lock_type: null
      },
      entity_type: '',
      entity_name: ''
    }

    // if(window.current_page && window.current_page.version==2){
    //   filters.sort_by.query.code = 'best_content:desc'
    // }
    // else{
      filters.sort_by.query.code = 'close_date:asc'
    // }

    const pf = props.filters
    if(pf){
      if(pf.locked_themes){
        for(let i of pf.locked_themes){
          filters.themes.selected[i] = true
          filters.themes.locked[i] = true
        }
      }
      if(pf.sort_by){
        filters.sort_by.query.code = pf.sort_by
      }
      if(pf.custom_sort_enabled){
        filters.custom_sort_enabled = pf.custom_sort_enabled
      }
      if(pf.entity_type){
        filters.entity_type = pf.entity_type
      }
      if(pf.entity_name){
        filters.entity_name = pf.entity_name
      }
      if(pf.buying_format){
        filters.buying_format.lock_type = pf.buying_format.lock_type
      }

    }
    return filters
  }

  applyQueryStrToFilters({filters, queryStr}){
    const parsed = qs.parse(queryStr)
    filters.query = parsed.keywords || parsed.q
    filters.pagination.page = parsed.page || filters.pagination.page
    filters.pagination.per_page = parsed.per_page || filters.pagination.per_page

    const pf = parsed.filters || parsed
    if(!_.isEmpty(pf)){
      if(pf.sort_by && pf.sort_direction){
        filters.sort_by.query.code = `${pf.sort_by}:${pf.sort_direction}`
      }
      if(pf.categories){
        for(let c of pf.categories){
          filters.departments.selected[c] = true
        }
      }
      if(pf.people){
        for(let c of pf.people){
          filters.details.selected.people[c] = true
        }
      }
      if(pf.place){
        for(let c of pf.place){
          filters.details.selected.place[c] = true
        }
      }
      if(pf.activity){
        for(let c of pf.activity){
          filters.details.selected.activity[c] = true
        }
      }
      if(pf.current_price){
        filters.price.low = pf.current_price[0] || filters.price.low
        filters.price.high = pf.current_price[1] ||filters.price.high
      }
      if(!_.isEmpty(pf.lr)){
        filters.price.low = pf.lr
      }
      if(!_.isEmpty(pf.ur)){
        filters.price.high = pf.ur
      }

      if(pf.org_names){
        for(let c of pf.org_names){
          filters.orgs.selected.orgs[c] = true
        }
      }
      if(pf.cause){
        for(let c of pf.cause){
          filters.orgs.selected.causes[c] = true
        }
      }
      if(pf.themes){
        for(let c of pf.themes){
          filters.themes.selected[c] = true
        }
      }
      if(pf.lot_type){
        filters.donation_type.code = pf.lot_type[0] || filters.donation_type.code
      }

      if(pf.buying_format){
        let bf
        if(Array.isArray(pf.buying_format)){
          bf = pf.buying_format[0]
        }
        else{
          bf = pf.buying_format
        }
        filters.buying_format.code = bf || filters.buying_format.code
      }
      else{
        filters.buying_format.code = ""
      }
      if(!_.isEmpty(pf.entity_type)){
        filters.entity_type = pf.entity_type
      }
      if(!_.isEmpty(pf.entity_name)){
        filters.entity_name = pf.entity_name
      }
    }
    else{
      // reset filters
      const defaultFilters = this.initFilters({})
      for(const k in defaultFilters){
        filters[k] = defaultFilters[k]
      }
    }

  }

  adjustFiltersFromResults(results, filters){

    const checkForRemoval = (selected, key) => {
      for(const f in selected){
        let found = results.results.groups[key] && results.results.groups[key][f]
        if(!found){
          delete(selected[f])
        }
      }
    }

    // const checkForRemovalOrgs = (selected) => {
    //   for(const f in selected){
    //     let r_orgs = null
    //     try{
    //       r_orgs = results.results.orgs
    //     } catch(err){}
    //     let found = false
    //     if(r_orgs){
    //       for(const o of r_orgs){
    //         if(o.org_name == f){
    //           found = true
    //         }
    //       }
    //     }
    //     if(!found){
    //       delete(selected[f])
    //     }
    //   }
    // }

    checkForRemoval(filters.departments.selected, 'categories')
    checkForRemoval(filters.themes.selected, 'themes')
    checkForRemoval(filters.orgs.selected.orgs, 'org_names')
    checkForRemoval(filters.orgs.selected.causes, 'cause')
    checkForRemoval(filters.details.selected.people, 'people')
    checkForRemoval(filters.details.selected.place, 'place')
    checkForRemoval(filters.details.selected.activity, 'activity')

  }

  getCategoreis(){
    return axios.get(`/api/categories.json?onboarder=1`)
  }

  createSearchAlert(keyword){
    return axios.post(`/account/notifications/create_saved_search_alert.json`, {
      keyword: keyword
    })
  }
}

export default Search
