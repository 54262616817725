import React from "react"
import BaseComponent from "../__application/BaseComponent"
import Billing from "./Billing"

class Page extends BaseComponent {
  constructor(props) {
    super(props)

    this.savedCardsHtml = this.savedCardsHtml.bind(this)
    this.newCardHtml = this.newCardHtml.bind(this)
    this.handleSelectionChange = this.handleSelectionChange.bind(this)
  }

  savedCardsHtml(){
    const self = this
    const model = self.props.model
    const cards = model.cards
    const selected = model.selected.card || {}
    let list = []
    for(const c of cards){
      let item = (
        <div className="po-cc" key={c.id}>
          <label>
            <input type="radio" name="cc_id" value={c.id} checked={selected.id == c.id} onChange={(event)=>{this.handleSelectionChange({event: event, card:c})}}/>
            <i className={c.issuer.cls}></i> {c.issuer.name} ending in {c.card_hint}
          </label>
        </div>
      )
      list.push(item)
    }
    return list.length > 0 ? list : null
  }

  handleSelectionChange({event, card}){
    const cc_id = event.target.value
    let model = this.props.model
    model.selected.card = card
    model.show_shipping = (card.id != -1)
    this.props.changeState(model)
  }

  newCardHtml(){
    const self = this
    const model = self.props.model
    const cards = model.cards
    const selected = model.selected.card || {}
    let html = (
      <React.Fragment>
        <div className="po-title">
            Use a new payment method
        </div>
        <label>
          <input type="radio" name="cc_id" value="-1" checked={selected.id == -1} onChange={(event)=>{this.handleSelectionChange({event: event, card:{id: -1}})}}/>
          Add payment method
          <div className="po-new-item po-newcc medium-8">
            { model.selected.card.id == -1
              ?
              <Billing model={this.props.model} changeState={self.props.changeState} fetchCards={self.props.fetchCards} fetchAddresses={self.props.fetchAddresses} geo_data={this.props.geo_data} invertShowShipping={this.props.invertShowShipping} />
              :
              ""
            }
          </div>
        </label>
      </React.Fragment>
    )
    return html
  }

  render() {
    const cardList = this.savedCardsHtml()
    const newCard = this.newCardHtml()

    return (
      <React.Fragment>
        {!!cardList && <React.Fragment>
          <div className="po-title">
            Use my saved payment method
          </div>
          <div className="po-saved">
            {cardList}
          </div>
        </React.Fragment>}
        {newCard}
      </React.Fragment>
    )
  }
}

export default Page
