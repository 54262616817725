import React from "react"
import BaseComponent from "../__application/BaseComponent"
import AddressFields from "./_AddressFields"
import stylesBillingBilling from "./Billing.module.scss";
import Input from "../../shared/components/form/Input";
import classNames from "classnames";

class Page extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = {
      model: {
        name: "",
        firstname: "",
        lastname: "",
        address1: "",
        address2: "",
        country_name: "US",
        state: "",
        city: "",
        zip: "",
        primary_phone: "",
        errors: {}
      },
      classNames: {
        name: "",
        firstname: "",
        lastname: "",
        address1: "",
        address2: "",
        country_name: "",
        state: "",
        city: "",
        zip: "",
        primary_phone: ""
      },
      labels: {
        name: "",
        firstname: "",
        lastname: "",
        address1: "",
        address2: "",
        country_name: "",
        state: "",
        city: "",
        zip: "",
        primary_phone: ""
      },
      formStatus: "filling"
    }

    // This binding is necessary to make `this` work in the callback
    this.handleChange = this.handleChange.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.handleFocusOut = this.handleFocusOut.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  handleChangeNew = (event) => {
    const mapping = {
      "shipping_address[address1]": "address1",
      "shipping_address[address2]": "address2",
      "shipping_address[city]": "city",
      "shipping_address[zip]": "zip",
      "shipping_address[country_name]": "country_name",
      "shipping_address[state]": "state",
      "shipping_address[firstname]": "firstname",
      "shipping_address[lastname]": "lastname",
      "shipping_address[primary_phone]": "primary_phone",
    };
    const value = event.target.type === "checkbox" ? event.target.checked: event.target.value;
    this.setState({
      model: {
        ...this.state.model,
        errors: {
          ...this.state.model.errors,
          [mapping[event.target.name]]: null,
        },
        [mapping[event.target.name]]: value,
      },
    });
  };

  handleChange(event) {
    let key = event.target.getAttribute("keyname") || event.target.name
    let val
    if(event.target.type == "checkbox"){
      val = event.target.checked
    }
    else{
      val = event.target.value
    }

    let modelChanges = this.state.model
    modelChanges[key] = val

    this.setState({
      model:modelChanges
    })
  }

  handleFocus(event){
    let key = event.target.getAttribute("keyname") || event.target.name
    let name = event.target.placeholder || event.target.attributes.placeholder.value

    if(key == 'firstname' || key == 'lastname'){
      key = 'name'
      name = "Name"
    }

    BaseComponent.prototype.setDisplayForItem.call(this, {
      key: key,
      val: event.target.value,
      name: name,
      action: "focus in"
    })
  }

  handleFocusOut(event){
    let key = event.target.getAttribute("keyname") || event.target.name
    let name = event.target.placeholder || event.target.attributes.placeholder.value

    if(key == 'firstname' || key == 'lastname'){
      key = 'name'
      name = 'Name'
    }

    BaseComponent.prototype.setDisplayForItem.call(this, {
      key: key,
      val: event.target.value,
      name: name,
      action: "focus out"
    })
  }

  handleKeyPress(event){
    if (event.key === 'Enter') {
      this.submitForm()
    }
  }

  submitForm(){
    event.preventDefault()

    // prefent double submit
    if(this.state.formStatus == "submitting"){
      return
    }

    const self = this
    let address = self.state.model

    // map fields
    let data = {
      address: {
        firstname:  address.firstname,
        lastname: address.lastname,
        address1: address.address1,
        address2: address.address2,
        country_name: address.country_name,
        state: address.state,
        city: address.city,
        zip: address.zip,
        primary_phone: address.primary_phone
      }
    }

    // clear errors
    address.errors = {}
    self.setState({
      model: address,
      formStatus: "submitting"
    })

    $.ajax({
      url: '/registration/shipping.json',
      type: 'POST',
      data: data,
      complete: function(jqXHR, status){
        let resp = $.parseJSON(jqXHR.responseText)

        let ad = resp.address
        let err = ad.errors
        address.errors = err

        if(_.isEmpty(err)){
          // location.href = resp.redirect_to
          self.props.fetchAddresses().then(res => {
            window.BINRecalculate()
          })
          return
        }
        else{
          BaseComponent.prototype.normalizeErrorMessages.call(this, err)

          let classNames = self.state.classNames
          let labels = self.state.labels


          _.map(err, function(v,k){
            if(["primary_phone", "address1", "address2", "city", "state", "zip"].includes(k)){
              if(err[k]){
                classNames[k] = "error"
              }
              else{
                classNames[k] = ""
              }
            }
          })

          if(err.firstname || err.lastname){
            classNames.name = "error"
            labels.name = "Please complete name"
          }
          else{
            classNames.name = ""
            labels.name = "Name"
          }

          self.setState({
            model: address,
            classNames: classNames,
            labels: labels,
            formStatus: "filling"
          })

        }

      }
    })
  }


  render() {
    let submitButton = (<button className={`action-btn ${this.state.formStatus=="submitting" ? "disabled" : ""}`} onClick={this.submitForm}>Save Address</button>)

    return (
      <div className="">
        <div className="form-info">
          <div className="form-info-title">Shipping Information</div>
        </div>
        <div className="form-body">

          <div className={classNames(stylesBillingBilling.inputWrapper, stylesBillingBilling.inputMultipleLine)}>
            <Input
                label="First Name"
                type="text"
                name="shipping_address[firstname]"
                onChange={this.handleChangeNew}
                value={this.state.model.firstname}
                error={this.state.model.errors.name}
                width="48%"
            />
            <Input
                label="Last Name"
                type="text"
                name="shipping_address[lastname]"
                onChange={this.handleChangeNew}
                value={this.state.model.lastname}
                width="48%"
            />
          </div>

          <AddressFields geo_data={this.props.geo_data} state={this.state} handleChange={this.handleChangeNew} namespace={"shipping_address"}/>


          <div className={stylesBillingBilling.inputWrapper}>
            <Input
                label="Primary Phone"
                type="text"
                name="shipping_address[primary_phone]"
                onChange={this.handleChangeNew}
                value={this.state.model.primary_phone}
                error={this.state.model.errors.primary_phone}
            />
          </div>

          <div>
            {submitButton}
          </div>

        </div>
      </div>
    )
  }
}

export default Page
