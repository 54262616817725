import axios from"axios";var AUTH_ID="d3771130-3943-082a-b803-220eabcc5ad5";var AUTH_TOKEN="o7tg0VB9zmayoSPmdyND";var AUTH="auth-id=".concat(AUTH_ID,"&auth-token=").concat(AUTH_TOKEN);export var validateInternationalAddress=function validateInternationalAddress(_ref){var street=_ref.street,city=_ref.city,state=_ref.state,country=_ref.country,postalCode=_ref.postalCode;var BASE_ADDRESS="https://international-street.api.smartystreets.com";var params="address1=".concat(street,"&locality=").concat(city,"&administrative_area=").concat(state,"&postal_code=").concat(postalCode,"&country=").concat(country);return axios.get("".concat(BASE_ADDRESS,"/verify?").concat(AUTH,"&").concat(params));};export var autocompleteUSAddress=function autocompleteUSAddress(street){var BASE_ADDRESS="https://us-autocomplete-pro.api.smartystreets.com";var params="search=".concat(street,"&max_results=6");return axios.get("".concat(BASE_ADDRESS,"/lookup?").concat(AUTH,"&").concat(params));};/*
export const autocompleteInternationalAddress = ({
  street,
  city,
  state,
  country,
  postalCode,
}) => {
  const BASE_ADDRESS = "international-autocomplete.api.smartystreets.com";
  const params = `address1=${street}&locality=${city}&administrative_area=${state}&postal_code=${postalCode}&country=${country}`;

  return axios.get(`${BASE_ADDRESS}/lookup?${AUTH}&${params}`);
};

export const validateUSAddress = ({
  street,
  city,
  state,
  country,
  postalCode,
  apt,
}) => {
  const BASE_ADDRESS = "https://us-street.api.smartystreets.com";
  const params = `secondary=${apt}&street=${street}&city=${city}&state=${state}&zipcode=${postalCode}&country=${country}&candidates=5&license=us-core-cloud`;

  return axios.get(`${BASE_ADDRESS}/street-address?${AUTH}&${params}`);
};
*/