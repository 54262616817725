import React from "react"
import BaseComponent from "../__application/BaseComponent"

class Page extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = {
      parent: this.props.state
    }

    // This binding is necessary to make `this` work in the callback
    this.beginApplePay = this.beginApplePay.bind(this);

    this.handleChange = this.props.handleChange
    this.toggleApplePaySupportStatus = this.props.toggleApplePaySupportStatus
  }

  beginApplePay() {
    let paymentRequest = {
      countryCode: 'US',
      currencyCode: 'USD',
      requiredBillingContactFields: ['postalAddress'],
      requiredShippingContactFields: ['name', 'postalAddress', 'phone'],
      total: {
        label: 'Charitybuzz auth',
        amount: '1'
      }
    };

    let session = Stripe.applePay.buildSession(paymentRequest,
      function(result, completion) {

      completion(ApplePaySession.STATUS_SUCCESS);
      let token = result.token;
      let shippingAddress = result.shippingContact;
      let card = token.card;

      let obj = {
        holder_name: card.name,
        cvv: null,
        exp_month: card.exp_month,
        exp_year: card.exp_year,
        address1: card.address_line1,
        address2: card.address_line2,
        city: card.address_city,
        state: card.address_state,
        zip: card.address_zip,
        country_name: card.address_country,
        active: 1,
        stripe_card_id: card.id,
        ct_card_number: null,
        ct_issuer: card.brand,
        ct_last_4: card.last4,
        ct_token: token.id,
        from_apple_pay: true
      };

      if((!obj.address1 && !obj.address2) || !obj.city || !obj.state || !obj.country_name || !obj.zip){
        alert("Please fill out billing address and try again.");
        return;
      }

      let address = {
        firstname: shippingAddress.givenName,
        lastname: shippingAddress.familyName,
        address1: shippingAddress.addressLines[0],
        address2: shippingAddress.addressLines[1],
        city: shippingAddress.locality,
        state: shippingAddress.administrativeArea,
        country_name: shippingAddress.country,
        zip: shippingAddress.postalCode,
        primary_phone: shippingAddress.phoneNumber
      };

      if(!address.firstname || !address.lastname || (!address.address1 && !address.address2) ||
        !address.city || !address.state || !address.country_name || !address.zip){
        alert("Please fill out shipping address and try again.");
        return;
      }
      else if(!address.primary_phone || (address.primary_phone && address.primary_phone.replace(/\D/g,'').length<10)){
        alert("Phone number must be at least 10 digits, please correct phone number and try again.");
        return;
      }
      else {
        let data = {
          credit_card: obj,
          address: address
        }
        $.post('/registration/billing.json', data).done(function(resp) {
          if(resp && resp.redirect_to){
            window.location.href = resp.redirect_to;
          }
          else{
            window.location.href = "/";
          }
        }).fail(function() {
          completion(ApplePaySession.STATUS_FAILURE);
        });
      }
    }, function(error) {
      console.log(error.message);
    });

    session.oncancel = function() {
      console.log("User hit the cancel button in the payment window");
    };

    session.begin();
  }

  render() {
    return (
      <div className="reg2-apple-pay">
        <div className="apple-pay-btn-wrapper">
          <div className="apple-pay-text">
            Add card with Apple Pay
          </div>
          <button className="apple-pay-btn" onClick={this.beginApplePay}></button>
        </div>
        <div className="apple-pay-form">
          <div className="form-helper-text">
            <label className="row">
              <div className="columns small-1">
                <input name="same_as_billing" className="form-input" type="checkbox" checked={this.state.parent.model.same_as_billing} onChange={this.handleChange} placeholder="Same as Billing"/> &nbsp;
              </div>
              <div className="columns small-11">
                <b>Skip the last step!</b> Use my Apple Pay shipping address and phone number.
              </div>
            </label>

            <br/>

            <div className="row">
              <div className="columns small-1">
              </div>
              <div className="columns small-11">
                 A $1 authorization is necessary to use Apple Pay.  This is only to verify card information and is not a charge.
              </div>
            </div>
          </div>
        </div>

        <div className="or-divider">
          <div className="or-text">or</div>
        </div>
      </div>
    );
  }
}

export default Page
