import React from 'react'
import Search from '../services/Search'
import PriceSel from './PriceSel'
import BuyingFormatSel from './BuyingFormatSel'
import SortBy from './SortBy'
// import CategoryDrawer from './CategoryDrawer'


class Page extends React.Component {
  constructor(props){
    super(props)
    this.searchService = new Search()
    let filters = this.searchService.initFilters({props: props})

    this.state = {
      show_full_bar: false,
      is_searching: true,
      is_filter_on: false,
      hide_qty_sort: true,
      attach_to: this.props.attachTo,
      
      filters: filters,
      results: {
        pagination:{
          page: 1,
          per_page: 48,
          total_pages: 0,
          total_objects: 0
        }
      }
    }

    this.updateFiltersFromUrl = this.updateFiltersFromUrl.bind(this)
    this.changeState = this.changeState.bind(this)
    this.handleFilterToggleClicked = this.handleFilterToggleClicked.bind(this)
    this.search = this.search.bind(this)
    this.listenGlobalEvents = this.listenGlobalEvents.bind(this)

    this.updateFiltersFromUrl(filters)
    this.listenGlobalEvents()
  }

  listenGlobalEvents(){
    const self = this

    document.addEventListener("load-listing-cards", function(event){
      let state = self.state
      state.is_searching = true
      self.setState(state)
    })
    document.addEventListener("load-listing-cards-done", function(event){
      let state = self.state
      state.is_searching = false
      self.setState(state)
    })

    document.addEventListener("update-listing-pagination", function(event){
      const pagination = event.detail.pagination
      let state = self.state
      state.is_searching = false
      let results = state.results
      results.pagination = pagination
      self.setState(state)
    })

    document.addEventListener("update-listing-filters", function(event){
      const filters = event.detail.filters
      let state = self.state
      state.filters = filters
      self.setState(state)
    })


    window.addEventListener("popstate", function(event){
      self.updateFiltersFromUrl(self.state.filters)
      self.setState({filters: self.state.filters})
    })
  }

  updateFiltersFromUrl(filters){
    let urlParams = new URLSearchParams(window.location.search)
    let queryStr = urlParams.toString()
    this.searchService.applyQueryStrToFilters({filters: filters, queryStr: queryStr})
  }

  changeState(newState){
    this.setState(newState)
  }

  handleFilterToggleClicked(){
    this.setState({
      is_filter_on: !this.state.is_filter_on
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    if(this.state.is_suggests_on){
      $("body").addClass("no-scroll-for-search")
    }
    else{
      $("body").removeClass("no-scroll-for-search")
    }
  }

  search({m}){
    const model = m || this.state
    const filters = model.filters
    const low = filters.price.low||''
    const high = filters.price.high||''
    const bf = filters.buying_format.code||''
    const sort_opt = filters.sort_by.query.code||''
    const sort_by = sort_opt.split(":")[0]||''
    const sort_direction = sort_opt.split(":")[1]||''
    const current_path = location.pathname
    let qs = `sort_direction=${sort_direction}&sort_by=${sort_by}&buying_format=${bf}&lr=${low}&ur=${high}`
    if(filters.ab){
      qs += '&default=1'
    }
    const url = `${current_path}?${qs}`

    let event = new CustomEvent('route-to',{detail: {url: url, options:{trigger: true}}});
    document.dispatchEvent(event);
  }

  render(){
    const state = this.state
    const total = state.results.pagination.total_objects
    let resultsSummary = ""

    if(state.is_searching){
      resultsSummary = (
        <div className="loading filter-item small cell small-12 text-center">
          <div className="ico-loading la-ball-clip-rotate-multiple la-2x" aria-hidden="true">
            <div></div>
            <div></div>
          </div>
        </div>
      )
    }
    else{
      // if(state.attach_to != 'sticky-top'){
      //   if(total > 0){
      //     resultsSummary = (
      //       <div className="filter-item items-showing small cell small-6">
      //         <div className="filter-title large">
      //           Showing {total} items
      //         </div>
      //       </div>
      //     )
      //   }
      //   else{
      //     resultsSummary = (
      //       <div className="filter-item small cell small-6">
      //         <div className="filter-title large">
      //           No Results Found
      //         </div>
      //       </div>
      //     )
      //   } 
      // }
    }

    const inOrgPage = location.pathname.match(/\/support\//i)
    const inThemePage = location.pathname.match(/\/theme\//i)
    // const showDrawer = !(inOrgPage || inThemePage)
    let drawerHtml = ""
    // if(showDrawer){
    //   drawerHtml = (<div className="filter-item small cell small-6 hide-for-medium">
    //                   <CategoryDrawer />
    //                 </div>)
    // }

    return (
      <div className="grid-x simple-filters-wrapper">

        <div className={`simple-filters compact cell medium-12 grid-x grid-padding-x`}>
          <div className="filter-item small cell small-12 medium-7">
            <BuyingFormatSel model={this.state} changeState={this.changeState} search={this.search} />
          </div>
          <div className="filter-item small cell small-12 medium-4 large-offset-1">
            <div className="filter-sort f-sort-list">
              <SortBy model={this.state} changeState={this.changeState} search={this.search} />
            </div>
          </div>

          {drawerHtml}

          {resultsSummary}
        </div>

      </div>
    )
  }
}

export default Page