import React from 'react';

class Nav extends React.Component {
  constructor(props) {
    super(props)

    this.dataCategories = props.categories
    this.handleClickOutside = this.handleClickOutside.bind(this)

    this.state = {
      active_category_id: null
    }
  }


  

  
  render() {
    const categories = this.dataCategories
    const ViewAllHtml = (
      <ul className="subcategory-group view-all">TEST</ul>
    )
    return (
      <section className="main-nav">
        <ul className="li main-nav__item">Home</ul>
        <ul className="li main-nav__item mega-menu-button">Explore Lots </ul>
        <ul className="li main-nav__item"></ul>
        <ul className="li main-nav__item"></ul>
        <ul className="li main-nav__item"></ul>
      </section>
    )
  }
}

export default Nav;