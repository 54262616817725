
class Homepage {

  // params: {viewed_lot_ids, sort_direction, sort_by, buying_format}
  loadEntities(params={}){
    if(currentCustomerData.id){
      params.user_id = currentCustomerData.id
    }
    return axios.get(`/api/homepage/default_setting.json`, {
      params: params
    })
  }

  loadBanner(){
    return axios.get(`/api/homepage/get_carousel.json`)
  }

  loadCategories(){
    return axios.get(`/api/homepage/get_categories.json`)
  }

  parseEntityData({data}){
    let json = {
      "row_type": data.row_type,
      "rank": data.rank,
      "row_type": data.row_type,
      "id": data.inventory_profiler_id,
      "title": data.title,
      "entity_type": data.entity_type,
      "entity_name": data.entity_name,
      "catalog_items": data.catalog_items,
      "pagination": {
        "per_page": 0,
        "page": 1,
        "total_pages": 0,
        "total_objects": data.lot_count
      }
    }
    return json
  }

  parseCharityData({data}){
    let json = {
      "row_type": data.row_type,
      "rank": data.rank,
      "row_type": data.row_type,
      "id": data.org_id,
      "title": data.org_name,
      "slug": data.org_slug,
      "catalog_items": data.catalog_items,
      "pagination": {
        "per_page": 0,
        "page": 1,
        "total_pages": 0,
        "total_objects": data.lot_count
      }
    }
    return json
  }

  parseCategoryData({data}){
    let json = {
      "row_type": data.row_type,
      "rank": data.rank,
      "row_type": data.row_type,
      "id": data.category_id,
      "title": data.category_name,
      "slug": data.slug,
      "catalog_items": data.catalog_items,
      "parent": null,
      "pagination": {
        "per_page": 0,
        "page": 1,
        "total_pages": 0,
        "total_objects": data.lot_count
      }
    }

    if(data.parent_category_id){
      json["parent"]={
        "id": data.parent_category_id,
        "name": data.parent_category_name,
        "slug": data.parent_category_slug
      }
    }
    return json
  }

  parseThemeData({data}){
    let json = {
      "row_type": data.row_type,
      "rank": data.rank,
      "id": data.theme_id,
      "title": data.row_title,
      "slug": data.slug,
      "catalog_items": data.catalog_items,
      "pagination": {
        "per_page": 0,
        "page": 1,
        "total_pages": 0,
        "total_objects": data.lot_count
      }
    }
    return json
  }

  parseTopBinData({data}){
    let json = {
      "row_type": data.row_type,
      "rank": data.rank,
      "catalog_items": data.catalog_items,
      "pagination": {
        "per_page": 0,
        "page": 1,
        "total_pages": 0,
        "total_objects": data.lot_count
      }
    }
    return json
  }

  parseCustomRowData({data}){
    let json = {
      "row_type": data.row_type,
      "rank": data.rank,
      "title": data.row_title,
      "catalog_items": data.catalog_items,
      "pagination": {
        "per_page": 0,
        "page": 1,
        "total_pages": 0,
        "total_objects": data.lot_count
      }
    }
    return json
  }

  parseFeaturedData({data}){
    let json = {
      "row_type": data.row_type,
      "rank": data.rank,
      "title": data.row_title,
      "catalog_items": data.catalog_items,
      "description": data.custom_text,
      "pagination": {
        "per_page": 0,
        "page": 1,
        "total_pages": 0,
        "total_objects": data.lot_count
      }
    }
    return json
  }

  getURLParameters(url) {
    var result = {};
    var searchIndex = url.indexOf("?");
    
    if (searchIndex == -1 ) return result;
      var sPageURL = url.substring(searchIndex +1);
      var sURLVariables = sPageURL.split('&');
      for (var i = 0; i < sURLVariables.length; i++) {       
        var sParameterName = sURLVariables[i].split('=');      
        result[sParameterName[0]] = sParameterName[1];
      }
    return result;
  }

}


export default Homepage
