import React from "react"
import BaseComponent from "../__application/BaseComponent"
import AddressFields from "./_AddressFields"

class Page extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = {
      model: {
        name: "",
        firstname: "",
        lastname: "",
        address1: "",
        address2: "",
        country_name: "United States",
        state: "",
        city: "",
        zip: "",
        primary_phone: "",
        errors: {}
      },
      classNames: {
        name: "",
        firstname: "",
        lastname: "",
        address1: "",
        address2: "",
        country_name: "",
        state: "",
        city: "",
        zip: "",
        primary_phone: ""
      },
      labels: {
        name: "",
        firstname: "",
        lastname: "",
        address1: "",
        address2: "",
        country_name: "",
        state: "",
        city: "",
        zip: "",
        primary_phone: ""
      },
      formStatus: "filling"
    }

    // This binding is necessary to make `this` work in the callback
    this.handleChange = this.handleChange.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.handleFocusOut = this.handleFocusOut.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  handleChange(event) {
    let key = event.target.name
    let val
    if(event.target.type == "checkbox"){
      val = event.target.checked
    }
    else{
      val = event.target.value
    }

    let modelChanges = this.state.model
    modelChanges[key] = val

    this.setState({
      model:modelChanges
    })
  }

  handleFocus(event){
    let key = event.target.name
    let name = event.target.placeholder || event.target.attributes.placeholder.value

    if(key == 'firstname' || key == 'lastname'){
      key = 'name'
      name = "Name"
    }

    BaseComponent.prototype.setDisplayForItem.call(this, {
      key: key,
      val: event.target.value,
      name: name,
      action: "focus in"
    })
  }

  handleFocusOut(event){
    let key = event.target.name
    let name = event.target.placeholder || event.target.attributes.placeholder.value

    if(key == 'firstname' || key == 'lastname'){
      key = 'name'
      name = 'Name'
    }

    BaseComponent.prototype.setDisplayForItem.call(this, {
      key: key,
      val: event.target.value,
      name: name,
      action: "focus out"
    })
  }

  handleKeyPress(event){
    if (event.key === 'Enter') {
      this.submitForm()
    }
  }

  submitForm(){
    // prefent double submit
    if(this.state.formStatus == "submitting"){
      return
    }

    let self = this
    let address = self.state.model

    // map fields
    let data = {
      address: {
        firstname:  address.firstname,
        lastname: address.lastname,
        address1: address.address1,
        address2: address.address2,
        country_name: address.country_name,
        state: address.state,
        city: address.city,
        zip: address.zip,
        primary_phone: address.primary_phone
      }
    }

    // clear errors
    address.errors = {}
    self.setState({
      model: address,
      formStatus: "submitting"
    })

    $.ajax({
      url: '/registration/shipping.json',
      type: 'POST',
      data: data,
      complete: function(jqXHR, status){
        let resp = $.parseJSON(jqXHR.responseText)

        let ad = resp.address
        let err = ad.errors
        address.errors = err

        if(_.isEmpty(err)){

          try{
            if(window.inRegPage()){
              // optmizely
              // window.pushToOptimizely({
              //   type: "event",
              //   eventName: "reg_r09_shipping_submitted"
              // });
              // window.pushToOptimizely({
              //   type: "event",
              //   eventName: "reg_r10_registration_complete"
              // });

              // ga
              // window.GAprocessRegEvent({
              //   'hitType': 'event',
              //   'eventCategory': 'Registration Steps',
              //   'eventAction': 'Shipping Info Submitted'
              // })
              // window.GAprocessRegEvent({
              //   'hitType': 'event',
              //   'eventCategory': 'Registration Steps',
              //   'eventAction': 'Registration Complete'
              // })
            }
          } catch(ex){}

          location.href = resp.redirect_to
        }
        else{
          BaseComponent.prototype.normalizeErrorMessages.call(this, err)

          let classNames = self.state.classNames
          let labels = self.state.labels


          _.map(err, function(v,k){
            if(["primary_phone", "address1", "address2", "city", "state", "zip"].includes(k)){
              if(err[k]){
                classNames[k] = "error"
              }
              else{
                classNames[k] = ""
              }
            }
          })

          if(err.firstname || err.lastname){
            classNames.name = "error"
            labels.name = "Please complete name"
          }
          else{
            classNames.name = ""
            labels.name = "Name"
          }

          self.setState({
            model: address,
            classNames: classNames,
            labels: labels,
            formStatus: "filling"
          })

        }

      }
    })
  }


  render() {
    let submitButton = (<button className={`action-btn ${this.state.formStatus=="submitting" ? "disabled" : ""}`} onClick={this.submitForm}>COMPLETE AND START BIDDING</button>)

    return (
      <div className="reg2-form">
        <div className="form-info">
          <div className="form-info-title">Shipping Information</div>
        </div>
        <div className="form-body">
          <div className="form-row row">
            <div className={`form-input-wrapper ${this.state.classNames.name}`}>
              <label className="input-legend">{this.state.model.errors.name || this.state.labels.name}</label>
              <div className="columns small-6 no-padding">
                <input autoComplete="off" name="firstname" className="form-input row-input-left" type="text" value={this.state.model.firstname} onKeyPress={this.handleKeyPress} onChange={this.handleChange} onFocus={this.handleFocus} onBlur={this.handleFocusOut} placeholder="First Name" />
              </div>
              <div className="columns small-6 no-padding">
                <input autoComplete="off" name="lastname" className="form-input row-input-right" type="text" value={this.state.model.lastname} onKeyPress={this.handleKeyPress} onChange={this.handleChange} onFocus={this.handleFocus} onBlur={this.handleFocusOut} placeholder="Last Name" />
              </div>
            </div>
          </div>

          <AddressFields geo_data={this.props.geo_data} state={this.state} handleChange={this.handleChange} handleFocus={this.handleFocus} handleFocusOut={this.handleFocusOut} handleKeyPress={this.handleKeyPress}/>

          <div className="form-row row">
            <div className={`form-input-wrapper ${this.state.classNames.primary_phone}`}>
              <label className="input-legend">{this.state.model.errors.primary_phone || this.state.labels.primary_phone}</label>
              <input autoComplete="off" name="primary_phone" className="form-input" type="text" value={this.state.primary_phone} onChange={this.handleChange} onKeyPress={this.handleKeyPress} onFocus={this.handleFocus} onBlur={this.handleFocusOut}  placeholder="Primary Phone" />
            </div>
          </div>

          <div>
            {submitButton}
          </div>

          <div className="form-row form-helper-text row">
            <br />
            <p className="text-sm text-red text-center"><a href="/registration/skip"><b>Skip Billing and Shipping info for now.</b></a></p>
            <p className="text-sm text-center">You won't be able to bid on lots until you complete your shipping info.</p>
          </div>

        </div>
      </div>
    )
  }
}

export default Page
