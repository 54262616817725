import React from "react"

class BaseComponent extends React.Component {

  normalizeErrorMessages(errors){
    if(!errors) return

    for (let key in errors) {
      let errs = errors[key]
      for(let i in errs){
        let err_text = errs[i]
        errs[i] = err_text.charAt(0).toUpperCase() + err_text.slice(1)
      }
    }
  }

  // obj = {key, val, name, action}
  setDisplayForItem(obj){
    let key = obj.key
    let val = obj.val
    let name = obj.name

    let classChanges = this.state.classNames
    let labelChanges = this.state.labels
    let modelChanges = this.state.model

    if(obj.action == 'focus in'){
      classChanges[key] = `${classChanges[key]} focus`
      labelChanges[key] = name
    }
    else if(obj.action == 'focus out'){
      if(!_.isEmpty(val)){
        classChanges[key] = "has-content"
        labelChanges[key] = name
        modelChanges.errors[key] = null
      }
      else{
        classChanges[key] = classChanges[key].replace("focus", "")
        labelChanges[key] = ""
      }
    }
    else if(obj.action == 'change'){

    }
    else{

    }


    this.setState({
      model:modelChanges,
      classNames: classChanges,
      labels: labelChanges
    })
  }

  ensureInputTypeCC(event){
    let inputValue = event.target.value
    // cc only allow up to 16 digits

    // if(inputValue.length >= 16){
    //   if(event.which != 8 && event.which != 16){
    //     event.preventDefault();
    //   }
    // }

    // cc only allow numbers
    // if(event.which < 48 || event.which > 57){
    //   event.preventDefault();
    // }
  }

  ensureInputTypeCVV(event){
    let inputValue = event.target.value
    // cvv only allow up to 3-4 digits
    // if(inputValue.length >= 4){
    //   if(event.which != 8 && event.which != 16){
    //     event.preventDefault();
    //   }
    // }

    // cc only allow numbers
    // if(event.which < 48 || event.which > 57){
    //   event.preventDefault();
    // }
  }

  ensureInputTypeExp(event){
    let inputValue = event.target.value

    // remove all non numeric
    inputValue = inputValue.replace(/\D/g,'')
    // remove /
    inputValue = inputValue.replace(/\//g, "")
    // take the last 4
    inputValue = inputValue.slice(-4)

    let addSlash = false
    if(inputValue.length > 2){
      addSlash = true
    }

    if(inputValue.length == 2){
      if(event.keyCode != 8){
        addSlash = true
      }
    }

    if(addSlash){
      // add slash after 2nd digit
      inputValue = inputValue.splice(2, 0, "/")

    }

    return inputValue

    // // cc only allow numbers and slash
    // if((event.which < 48 || event.which) > 57 && event.key != '/'){
    //   event.preventDefault();
    // }
  }

}


export default BaseComponent
