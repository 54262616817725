import React from "react"
import BaseComponent from "../__application/BaseComponent"
import ApplePay from "./_ApplePay"
import AddressFields from "./_AddressFields"

class Page extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = {
      model: {
        holder_name: "",
        ct_card_number: "",
        cvv: "",
        exp: "",
        exp_year: "",
        exp_month: "",
        address1: "",
        address2: "",
        country_name: "United States",
        state: "",
        city: "",
        zip: "",
        primary_phone: "",
        same_as_billing: true,
        errors: {}
      },
      classNames: {
        holder_name: "",
        credit_card: "",
        ct_card_number: "",
        cvv: "",
        exp: "",
        exp_year: "",
        exp_month: "",
        address1: "",
        address2: "",
        country_name: "",
        state: "",
        city: "",
        zip: "",
        primary_phone: ""
      },
      labels: {
        holder_name: "",
        credit_card: "",
        ct_card_number: "",
        cvv: "",
        exp: "",
        exp_year: "",
        exp_month: "",
        address1: "",
        address2: "",
        country_name: "",
        state: "",
        city: "",
        zip: "",
        primary_phone: ""
      },
      clientSupportsApplePay: false,
      addManuallyWithoutApplePay: false,
      formStatus: "filling"
    }
    this.checkIfClientSupportsApplePay()

    // This binding is necessary to make `this` work in the callback
    this.handleChange = this.handleChange.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.handleFocusOut = this.handleFocusOut.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.toggleApplePayForm = this.toggleApplePayForm.bind(this)

    this.CCValidator = new validateCreditCard();
  }

  componentWillMount() {
    let modelChanges = this.state

    Stripe.setPublishableKey(window.StripePublishablekey);
    Stripe.applePay.checkAvailability((available) => {
      let supported = available ? true : false
      if(supported){
        modelChanges.clientSupportsApplePay = supported
        this.setState(modelChanges)
      }
    });
  }

  handleChange(event) {
    let self = this;
    let key = event.target.name
    let val
    if(event.target.type == "checkbox"){
      val = event.target.checked
    }
    else{
      val = event.target.value
    }

    let model = self.state.model
    model[key] = val


    if(key == 'ct_card_number'){
      let result = self.CCValidator.validate(val);
      if(result.card_type){
        let cardType = result.card_type.name
        model.card_type = cardType
      }
    }



    self.setState({
      model:model
    })
  }

  handleFocus(event){
    let key = event.target.name
    let name = event.target.placeholder || event.target.attributes.placeholder.value

    if(key == 'ct_card_number' || key == 'cvv' || key == 'exp'){
      key = 'credit_card'
      name = 'Credit Card'
    }

    BaseComponent.prototype.setDisplayForItem.call(this, {
      key: key,
      val: event.target.value,
      name: name,
      action: "focus in"
    })
  }

  handleFocusOut(event){
    let key = event.target.name
    let name = event.target.placeholder || event.target.attributes.placeholder.value

    if(key == 'ct_card_number' || key == 'cvv' || key == 'exp'){
      key = 'credit_card'
      name = 'Credit Card'
    }

    BaseComponent.prototype.setDisplayForItem.call(this, {
      key: key,
      val: event.target.value,
      name: name,
      action: "focus out"
    })


    // this.validateCVC()


    // cc
    // let model = this.state.model
    // let classNames = this.state.classNames
    // let labels = this.state.labels
    // if (key == "credit_card"){
    //   let cc = model["ct_card_number"]
    //   let cvv = model["cvv"]
    //   let exp = model["exp"]
    //   let res = this.validateCC(cc, cvv, exp)

    //   if(res.exp || res.cvv || res.ct_card_number){
    //     classNames.credit_card = "error"
    //     if(res.exp){
    //       labels.credit_card = res.exp
    //     }
    //     if(res.cvv){
    //       labels.credit_card = res.cvv
    //     }
    //     if(res.ct_card_number){
    //       labels.credit_card = res.ct_card_number
    //     }
    //   }else{
    //     classNames.credit_card = ""
    //     labels.credit_card = "Credit Card"
    //   }

    //   this.setState({
    //     labels: labels,
    //     classNames:classNames
    //   })
    // }
  }

  handleKeyPress(event){
    if (event.key === 'Enter') {
      this.submitForm()
      return
    }

    let key = event.target.name
    if (key == "ct_card_number"){
      return BaseComponent.prototype.ensureInputTypeCC.call(this, event)
    }
    if (key == "cvv"){
      return BaseComponent.prototype.ensureInputTypeCVV.call(this, event)
    }
    if (key == "exp"){
      let exp = BaseComponent.prototype.ensureInputTypeExp.call(this, event)

      let model = this.state.model
      model.exp = exp
      this.setState({model: model})
      return
    }
  }

  checkIfClientSupportsApplePay(){
    Stripe.setPublishableKey(window.StripePublishablekey);
    Stripe.applePay.checkAvailability((available) => {
      let supported = available ? true : false
      this.state.clientSupportsApplePay = supported
    });
  }

  toggleApplePayForm(){
    let stateChange = this.state
    this.state.addManuallyWithoutApplePay = !this.state.addManuallyWithoutApplePay
    this.setState(stateChange)
  }

  handleApplePayClick(){
  }

  validateCVC(){
    let self = this;
    $('[name=ct_card_number]').validateCreditCard(function(result){
      if(result.card_type){
        let cardType = result.card_type.name;
        let stateChange = self.state
        stateChange.model.card_type = cardType
        self.setState(stateChange)
      }
    });
  }

  validateCC(cc, cvv, exp){
    let cc_pass = Stripe.card.validateCardNumber(cc)
    let cvv_pass = Stripe.card.validateCVC(cvv)
    let exp_pass = Stripe.card.validateExpiry(exp)
    let cc_type = Stripe.card.cardType(cc)

    let res = {
      ct_card_number: (cc_pass||_.isEmpty(cc)) ? null : "Your card number is invalid.",
      cvv: (cvv_pass||_.isEmpty(cvv)) ? null : "Your card's security code is incomplete.",
      exp: (exp_pass||_.isEmpty(exp)) ? null : "Your card's expiration date is incomplete."
    }

    return res
  }


  submitForm(){
    // prefent double submit
    if(this.state.formStatus == "submitting"){
      return
    }

    let self = this
    let credit_card = self.state.model
    let address = {}

    // adjust exp
    let exp
    try{
      exp = credit_card.exp.split("/")
    } catch(e){
      exp = ["",""]
    }
    credit_card.exp_month = exp[0]
    credit_card.exp_year = `20${exp[1]}`


    // map fields
    let data = {
      credit_card: {
        holder_name:  credit_card.holder_name,
        ct_card_number: credit_card.ct_card_number,
        cvv: credit_card.cvv,
        exp_year: credit_card.exp_year,
        exp_month: credit_card.exp_month,
        address1: credit_card.address1,
        address2: credit_card.address2,
        country_name: credit_card.country_name,
        state: credit_card.state,
        city: credit_card.city,
        zip: credit_card.zip
      }
    }

    // adjust phone
    if(credit_card.same_as_billing){
      data.same_as_billing = true
      if(!_.isEmpty(credit_card.primary_phone)){
        address['primary_phone'] = credit_card.primary_phone
        data.address = address
      }
    }

    // clear errors
    credit_card.errors = {}
    self.setState({
      model: credit_card,
      formStatus: "submitting"
    })


    $.ajax({
      url: '/registration/billing.json',
      type: 'POST',
      data: data,
      complete: (jqXHR, status)=>{
        let resp = $.parseJSON(jqXHR.responseText)

        let cc = resp.credit_card
        let err = cc.errors
        credit_card.errors = err

        // success
        if(_.isEmpty(err)){
          try{
            if(window.inRegPage()){
              // optmizely
              // window.pushToOptimizely({
              //   type: "event",
              //   eventName: "reg_r06_billing_submitted"
              // });

              // ga
              // window.GAprocessRegEvent({
              //   'hitType': 'event',
              //   'eventCategory': 'Registration Steps',
              //   'eventAction': 'Billing Info Submitted'
              // })

              if(data.same_as_billing){
                // optmizely
                // window.pushToOptimizely({
                //   type: "event",
                //   eventName: "reg_r09_shipping_submitted"
                // });
                // window.pushToOptimizely({
                //   type: "event",
                //   eventName: "reg_r10_registration_complete"
                // });

                // ga
                // window.GAprocessRegEvent({
                //   'hitType': 'event',
                //   'eventCategory': 'Registration Steps',
                //   'eventAction': 'Shipping Info Submitted'
                // })
                // window.GAprocessRegEvent({
                //   'hitType': 'event',
                //   'eventCategory': 'Registration Steps',
                //   'eventAction': 'Registration Complete'
                // })

              }
            }
          } catch(ex){}

          location.href = resp.redirect_to
        }
        else{
          BaseComponent.prototype.normalizeErrorMessages.call(this, err)
          let classNames = self.state.classNames
          let labels = self.state.labels


          _.map(err, function(v,k){
            if(["holder_name", "address1", "address2", "city", "state", "zip", "primary_phone"].includes(k)){
              if(err[k]){
                classNames[k] = "error"
              }
              else{
                classNames[k] = ""
              }
            }
          })

          // CC error handling
          if(err.ct_card_number || err.cvv || err.exp_year || err.exp_month || err.expiration_date || err.auth_failure){
            classNames.credit_card = "error"
          }
          else{
            classNames.credit_card = ""
          }

          if(err.ct_card_number){
            labels.credit_card = "Please enter correct card number"
          }else{
            if(err.cvv){
              labels.credit_card = "Please enter correct CVV"
            }
            else{
              if(err.exp_year || err.exp_month){
                labels.credit_card = "Please enter correct expiration date"
              }else{
                if(err.expiration_date){
                  labels.credit_card = "Please enter a future expiration date"
                }
              }
            }
          }
          if(err.auth_failure){
            labels.credit_card = "Auth Failure"
          }




          self.setState({
            model: credit_card,
            classNames: classNames,
            labels: labels,
            formStatus: "filling"
          })

        }

      },
      error: ()=>{
        self.setState({
          formStatus: "filling"
        })
      }
    })
  }


  render() {
    let phoneDom
    let shippingBtnText = "CONTINUE TO SHIPPING INFO"
    if(this.state.model.same_as_billing){
      phoneDom = (
        <div className="form-row row">
          <div className={`form-input-wrapper ${this.state.classNames.primary_phone}`}>
            <label className="input-legend">{this.state.model.errors.primary_phone || this.state.labels.primary_phone}</label>
            <input autoComplete="off" name="primary_phone" className="form-input" type="text" value={this.state.primary_phone} onChange={this.handleChange} onKeyPress={this.handleKeyPress} onFocus={this.handleFocus} onBlur={this.handleFocusOut}  placeholder="Primary Phone" />
          </div>
        </div>
      )
      shippingBtnText = "COMPLETE AND START BIDDING"
    }

    let submitButton = (<button className={`action-btn ${this.state.formStatus=="submitting" ? "disabled" : ""}`} onClick={this.submitForm}>{shippingBtnText}</button>)


    let formBody
    let hasApplePayButEnterManually = (this.state.clientSupportsApplePay && this.state.addManuallyWithoutApplePay)
    if(hasApplePayButEnterManually || !this.state.clientSupportsApplePay){
      formBody = (
        <div className="form-body">
          <div className="form-row row">
            <div className={`form-input-wrapper ${this.state.classNames.holder_name}`}>
              <label className="input-legend">{this.state.model.errors.holder_name || this.state.labels.holder_name}</label>
              <input autoComplete="off" name="holder_name" className="form-input" type="text" value={this.state.holder_name} onChange={this.handleChange} onKeyPress={this.handleKeyPress} onFocus={this.handleFocus} onBlur={this.handleFocusOut}  placeholder="Name on Card" />
            </div>
          </div>
          <div className="form-row">
            <div className={`form-input-wrapper ${this.state.classNames.credit_card}`}>
              <label className="input-legend">{this.state.model.errors.credit_card || this.state.labels.credit_card}</label>
                <input autoComplete="off" name="ct_card_number" className={`form-input row-input-left cc ${this.state.model.errors.ct_card_number ? 'error' : ''}`} type="text" pattern="[0-9]*" value={this.state.model.ct_card_number} maxLength="16" onKeyPress={this.handleKeyPress} onChange={this.handleChange} onFocus={this.handleFocus} onBlur={this.handleFocusOut} placeholder="Card" />

                <input autoComplete="off" name="cvv" className={`form-input row-input-mid cc ${this.state.model.errors.cvv ? 'error' : ''}`} type="text" value={this.state.model.cvv} maxLength={this.state.model.card_type=='amex' ? 4 : 3} onKeyPress={this.handleKeyPress} onChange={this.handleChange} onFocus={this.handleFocus} onBlur={this.handleFocusOut} placeholder="CVV" />

                <input autoComplete="off" name="exp" className={`form-input row-input-right cc ${(this.state.model.errors.expiration_date||this.state.model.errors.exp_month||this.state.model.errors.exp_year) ? 'error' : ''}`} type="text" value={this.state.model.exp} onKeyUp={this.handleKeyPress} onChange={this.handleChange} onFocus={this.handleFocus} onBlur={this.handleFocusOut} placeholder="MM/YY" />
            </div>
          </div>

          <AddressFields geo_data={this.props.geo_data} state={this.state} handleChange={this.handleChange} handleFocus={this.handleFocus} handleFocusOut={this.handleFocusOut} handleKeyPress={this.handleKeyPress}/>

          <div className="form-row row">
            <div className="form-helper-text">
              <label className="grid-x">
                <div className="cell small-1">
                  <input autoComplete="off" name="same_as_billing" className="form-input" type="checkbox" checked={this.state.model.same_as_billing} onChange={this.handleChange} placeholder="Same as Billing"/> &nbsp;
                </div>
                <div className="cell small-11">
                  <b>Skip the last step!</b> My shipping address is same as my billing address.
                </div>
              </label>

              <br/>

              {phoneDom}
            </div>
          </div>

          <div>
            {submitButton}
          </div>

          <div className="form-row form-helper-text row">
            <br />
            <p className="text-sm text-red text-center"><a href="/registration/skip"><b>Skip Billing and Shipping info for now.</b></a></p>
            <p className="text-sm text-center">You won't be able to bid on lots until you complete your billing and shipping info.</p>
          </div>
        </div>
      )
    }
    else{
      formBody = (
        <div className="form-body">
          <div className="apple-pay-toggle-wrapper">
            <div className="apple-pay-toggle-text">Add card manually</div>
            <div>
              <button className="apple-pay-toggle-btn" onClick={this.toggleApplePayForm}>ADD CARD</button>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="reg2-form">
        <div className={`form-info show`}>
          <div className="form-info-title">Billing Information</div>
          <div className={`form-info-text ${this.state.clientSupportsApplePay ? "" : "hide"}`}>
            <ApplePay state={this.state} handleChange={this.handleChange}/>
          </div>
        </div>

        {formBody}

      </div>
    );
  }
}

export default Page
