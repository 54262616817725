import React, { useState, SyntheticEvent } from "react";
import Button from "../../shared/components/form/Button";
import Input from "../../shared/components/form/Input";
import DonationService from './services/Donation';

const Page = ({data}) => {
  const donationService = new DonationService();

  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [requested, setRequested] = useState(false);
  const [errorCode, setErrorCode] = useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }
  const handleCodeChange = (event) => {
    setCode(event.target.value);
  }

  const handleClickRequestCode = (event) => {
    event.preventDefault()
    setRequested(true)
    donationService.requestCode({email: email}).then(res => {
      
    }).catch(err => {
      setRequested(false)
    })
  }

  const handleClickLogin = (event) => {
    event.preventDefault()
    donationService.login({email: email, code: code}).then(res => {
      location.href='/submissions'
    }).catch(err => {
      setErrorCode('Invalid Code')
    })
  }
  
  let html = (
    <div className="submission-portal row column align-center medium-6 large-4 container-padded">
      <h1 className="text-center">Submissions Portal</h1>
      
      <form onSubmit={handleClickRequestCode}>
        <Input
          className="email"
          label="Email"
          type="email"
          autoComplete="Email"
          value={email}
          disabled={requested}
          onChange={handleEmailChange}
        />
      </form>

      <br/>

      <form onSubmit={handleClickLogin}>
        <div className={requested ? '' : 'hide'}>
          <Input
            className="code"
            label="Code"
            type="text"
            autoComplete="Verification Code"
            value={code}
            onChange={handleCodeChange}
            error={errorCode}
          />
        </div>
      </form>

      <br/>
    
      <div className={requested ? 'hide' : ''}>
        <Button type="submit" isDefault onClick={handleClickRequestCode}>
          Request Code
        </Button>
      </div>

      <div className={requested ? '' : 'hide'}>
        <Button type="submit" isDefault onClick={handleClickLogin}>
          Login
        </Button>
      </div>
    </div>
  )

  return html
}

export default Page
