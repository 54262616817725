import React from 'react'
import Search from './services/Search'
import Tracker from './services/Tracker'
import BuyingFormatSel from './components/BuyingFormatSel'
import SortBy from './components/SortBy'
import NoResults from './components/NoResults'
import FoundLots from './components/FoundLots'

class Page extends React.Component {
  constructor(props){
    super(props)
    this.searchService = new Search()
    this.trackerService = new Tracker()
    
    let filters = this.searchService.initFilters({props: props})

    this.state = {
      hide_search_bar: true,
      show_full_bar: true,
      is_searching: false,
      hide_qty_sort: true,

      filters: filters,
      results: {

      },
    }

    this.updateFiltersFromUrl = this.updateFiltersFromUrl.bind(this)
    this.changeState = this.changeState.bind(this)
    this.search = this.search.bind(this)
    this.foundHtml = this.foundHtml.bind(this)
    this.notFoundHtml = this.notFoundHtml.bind(this)
    this.listenGlobalEvents = this.listenGlobalEvents.bind(this)

    this.updateFiltersFromUrl(filters)
    this.listenGlobalEvents()
    this.search({track_page_view: false, track_results: true})
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    
  }

  listenGlobalEvents(){
    const self = this
    window.onpopstate = function(event) {
      let filters = self.state.filters
      self.updateFiltersFromUrl(filters)
      self.search({update_url: false})
    }
  }

  updateFiltersFromUrl(filters){
    let urlParams = new URLSearchParams(window.location.search)
    let queryStr = urlParams.toString()

    this.searchService.applyQueryStrToFilters({filters: filters, queryStr: queryStr})
  }

  componentDidMount() {
    window.addEventListener("click", this.handleMouseClick)
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleMouseClick)
  }

  changeState(newState){
    this.setState(newState)
  }

  search({track_page_view=true, track_results=true, update_url=true, m}){
    const self = this
    let state = m || self.state
    let filters = state.filters
    state.is_searching = true
    return self.searchService.queryApi({filters: filters, updateUrl: update_url, searchPath: self.props.search_path}).then(res => {
      // let state = self.state
      state.results = res.data
      self.searchService.adjustFiltersFromResults(state.results, state.filters)
      state.is_searching = false
      self.changeState(state)

      // if(track_page_view){
      //   const url = location.href
      //   self.trackerService.trackPageView({url})
      // }
      
      // if(track_results){
      //   self.trackerService.trackSearchResults({results: state.results})
      // }
    })
  }

  foundHtml(){
    let found = 0
    try{
      found = this.state.results.pagination.total_objects
    }catch(err){
      found = 0
    }

    const filters = this.state.filters
    const hideFilters = !_.isEmpty(filters.themes.locked)

    const seeClosedLotsToolTip = "<b>Looking for closed lots?</b> <br/>Closed lots aren’t quite ready for our new search experience, just yet. Click this link to view closed lots in the old experience!"

    let html = (
      <div
        className={`search-results-container grid-x ${
          !this.props.hide_search_bar ? "with-pt" : ""
        }`}
      >
        <div className='simple-filters cell medium-12 grid-x grid-padding-x'>
          <div className='filter-item small cell small-12 medium-6'>
            <BuyingFormatSel
              model={this.state}
              changeState={this.changeState}
              search={this.search}
            />
          </div>
          <div className='medium-2'></div>
          <div
            className='filter-item small cell small-12 medium-4'
            tabIndex='0'
          >
            <div className='filter-sort f-sort-list'>
              <SortBy
                model={this.state}
                changeState={this.changeState}
                search={this.search}
              />
            </div>
          </div>
        </div>

        <div className='cell medium-12 grid-x grid-padding-x'>
          <div className='found-count cell medium-12 text-left'>
            <strong>Showing {found} lots</strong>
          </div>
        </div>
        <div className='lot-results cell medium-12'>
          <FoundLots
            model={this.state}
            changeState={this.changeState}
            search={this.search}
          />
        </div>
      </div>
    );

    return html
  }

  notFoundHtml(){
    let html = (
      <div className={`search-results-container no-results ${!this.props.hide_search_bar ? 'with-pt' : ''}`}>
        <NoResults model={this.state} changeState={this.changeState} search={this.search} />
      </div>
    )

    return html
  }

  render(){
    let found = 0
    let foundHtml = this.foundHtml()
    let notFoundHtml = this.notFoundHtml()

    const hasFilters = this.searchService.hasStrictFilters({filters: this.state.filters})
    try{
      found = this.state.results.pagination.total_objects
    }catch(err){
      found = 0
    }

    let resultHtml = ""
    if(!_.isEmpty(this.state.results)){
      if(this.state.is_searching){
        $('.search-no-results-extra-content').addClass('hidden')
        resultHtml = foundHtml
      }
      else{
        if(found > 0){
          $('.search-no-results-extra-content').addClass('hidden')
          resultHtml = foundHtml
        }
        else{
          if(hasFilters){
            $('.search-no-results-extra-content').addClass('hidden')
            resultHtml = foundHtml
          }
          else{
            $('.search-no-results-extra-content').removeClass('hidden')
            resultHtml = notFoundHtml
          }
        }
      }
    }



    return (
      <React.Fragment>
        {resultHtml}
      </React.Fragment>
    )
  }
}

export default Page
