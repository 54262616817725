import React from "react"
import BaseComponent from "../__application/BaseComponent"
import PostTagLabel from "./_PostTagLabel"
import moment from "moment"

class Page extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = {
      post: this.props.post,
      settings: this.props.settings
    }
    // this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  postItemHtml(self){
    let settings = self.state.settings
    let p = self.state.post
    let label = p.labels[0]
    let time = moment(p.publish_date*1000).fromNow()
    let description = {__html: p.description};
    let body = {__html: p.body};
    let postTagLabel = (<PostTagLabel tag={label} />)
    const postLink = `/posts/${p.id}`

    let postTimeHtml = (
      <div className={`post-time unread`}><i className="fas fa-circle ic-dot fa-fw"></i> {time}</div>
    )
    if(p.read){
      postTimeHtml = (
        <div className={`post-time read`}>{time}</div>
      )
    }

    let postLinkHtml = ""
    if(settings.page_enabled){
      postLinkHtml = (
        <div>
          <a className="btn-post-read-detail" href={postLink} onClick={function(e){self.props.handleReadMoreClick(e, p)}}>Read more</a>
        </div>
      )
    }


    let html = (
      <div className="post-item detail-view">
        <div>
          <div className="btn-back-to-list" onClick={function(){self.props.handlePostClick(null)}}>
            <i className="fal fa-angle-left"></i> Back to All Updates
          </div>
        </div>
        <div className="post-header">
          <div className="post-label">{postTagLabel}{label}</div>
          {postTimeHtml}
        </div>
        <div>
          <span className="post-title">{p.title} - </span>
          <span className="post-description" dangerouslySetInnerHTML={description}></span>
          <span className="post-body" dangerouslySetInnerHTML={body}></span>
        </div>
        {postLinkHtml}
      </div>
    )

    return html
  }


  render() {
    const self = this
    const postHtml = self.postItemHtml(self)
    return (
      <div className="widget-posts-wrapper">
        {postHtml}
      </div>
    );
  }
}

export default Page
