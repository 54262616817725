class Donantion {
  getDonationsForCustomer({page, per_page}){
    const url = `/submissions.json`
    return axios.get(url)
  }

  requestCode({email}){
    const url = `/submissions/users/request_code.json`
    return axios.post(url, {
      email: email
    })
  }

  login({email, code}){
    const url = `/submissions/users/login.json`
    return axios.post(url, {
      email: email,
      code: code
    })
  }
}

export default Donantion