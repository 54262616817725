import React from "react"
import Tracker from '../services/Tracker'

class Page extends React.Component {
  constructor(props) {
    super(props)

    this.trackerService = new Tracker()

    this.handleEntityClicked = this.handleEntityClicked.bind(this)
    this.handleCharityClicked = this.handleCharityClicked.bind(this)
  }

  handleEntityClicked({group, item, pos}){
    const keyword = this.props.model.filters.query_temp
    const entity = item.name_str
    if(group == 'similar'){
      this.trackerService.trackSimilarAutoSuggestClick({keyword, entity, pos})
    }
    else if(group == 'related'){
      this.trackerService.trackRelatedAutoSuggestClick({keyword, entity, pos})
    } 

    location.href = item.path
  }

  handleCharityClicked({item, pos}){
    const keyword = this.props.model.filters.query_temp
    const org = item
    this.trackerService.trackCharityAutoSuggestClick({keyword, org, pos})

    location.href = item.path
  }

  suggestGroupHtml(group, data){
    const self = this
    let groupData = data
    let list = []
    for (var i=0; i<groupData.list.length; i++) {
      if(i >= 5){
        break
      }
      const pos = i+1
      let item = groupData.list[i]
      list.push(
        <div className="sg-item" key={i} 
          onClick={(event) => {self.handleEntityClicked({group, item, pos})}}>
          <i className="fal fa-search"> </i>
          <div className="sg-name" dangerouslySetInnerHTML={{__html: htmlDecode(item.name)}}></div>
          <div className="sg-qty">
            ({item.qty})
          </div>
        </div>
      )
    }

    let html = (
      <div className="suggest-group">
        <div className="sg-head">
          <div className="sg-title">{groupData.title}</div>
          <div className="sg-bar hidden"><hr/></div>
          <div className="sg-more hidden">MORE</div>
        </div>
        <div className="sg-list">
          {list}
        </div>
      </div>
    )

    return html
  }

  suggestCharitiesHtml(data){
    const self = this
    let groupData = data
    let list = []
    for (var i=0; i<groupData.list.length; i++) {
      if(i >= 3){
        break
      }
      const pos = i+1
      let item = groupData.list[i]
      list.push(
        <div className="sg-item" key={i} 
          onClick={(event) => {self.handleCharityClicked({item, pos})}}>
          <div className="sg-name" dangerouslySetInnerHTML={{__html: htmlDecode(item.name)}}>
          </div>
        </div>
      )
    }

    let html = (
      <div className="suggest-charities">
        <div className="sg-head">
          <div className="sg-icon"><i className="far fa-hands-heart"></i></div>
          <div className="sg-title">{groupData.title}</div>
        </div>
        <div className="sg-list">
          {list}
        </div>
      </div>
    )
    return html
  }

  parseData(data){
    let results = {}
    let data_results = data.results_temp || data.results
    if(data_results){
      if(data_results.results){
        results = data_results.results
      }
    }
    let json = {
      similar: {
        title: 'SIMILAR',
        list: []
      },
      related: {
        title: 'RELATED',
        list: []
      },
      charities: {
        title: 'CHARITIES',
        list: []
      }
    }

    if(results['aggregated']){
      const similar = results['aggregated']['similar']
      for(var i=0; i<similar.length; i++){
        json['similar']['list'].push({
          name: similar[i].highlight,
          name_str: similar[i].name,
          qty: similar[i].count,
          path: `/search?keywords=${similar[i].name}`
        })
      }

      const related = results['aggregated']['related']
      for(var i=0; i<related.length; i++){
        json['related']['list'].push({
          name: related[i].name,
          name_str: related[i].name,
          qty: related[i].count,
          path: `/search?keywords=${related[i].name}`
        })
      }
    }

    if(results['orgs']){
      const orgs = results['orgs']
      for(var i=0; i<orgs.length; i++){
        json['charities']['list'].push({
          id: orgs[i]['org_id'],
          name: orgs[i]['org_name'],
          path: `/support/${orgs[i]['org_id']}`
        })
      }
    }

    return json
  }

  render(){
    let data = this.parseData(this.props.model)
    const hasSimilar = data.similar.list.length>0
    const hasRelated = data.similar.list.length>0
    const hasOrgs = data.charities.list.length>0
    const similar = hasSimilar ? this.suggestGroupHtml("similar", data.similar) : ''
    const related = hasRelated ? this.suggestGroupHtml("related", data.related) : ''
    const orgs = hasOrgs ? this.suggestCharitiesHtml(data.charities) : ''

    let html = (
      <div className="suggest-searches">
        {similar}
        {related}
        {orgs}
      </div>
    )

    return html
  }
}

export default Page
