import React from "react"
import BaseComponent from "../__application/BaseComponent"

class Page extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = {}

    // This binding is necessary to make `this` work in the callback
    this.submitForm = this.submitForm.bind(this);
  }

  submitForm(){
    location.href = "/"
  }


  render() {
    return (
      <div className="reg2-form">
        <div className="form-info show">
          <div className="form-info-title">From everyone at Charitybuzz</div>
          <div className="form-info-text">
            <p>
              <b>Thank you</b> for being a part of our mission to change the world!
            </p>
            <p>
              You are now able to place bids on Charitybuzz that support some of the most
              amazing charities across the globe. You can update your information any
              time by visiting your account.
            </p>

            <div>
              <button className="action-btn" onClick={this.submitForm}>CHECK OUT OPEN LOTS</button>
            </div>

            <div className="form-helper-text">
              <p className="text-sm text-center text-red">
                <a href="/account">Go to My Account</a>
              </p>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default Page
