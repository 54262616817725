import React from "react"
import BaseComponent from "../__application/BaseComponent"
import Shipping from "./Shipping"

class Page extends BaseComponent {
  constructor(props) {
    super(props)

    this.savedAddressesHtml = this.savedAddressesHtml.bind(this)
    this.newAddressHtml = this.newAddressHtml.bind(this)
    this.handleSelectionChange = this.handleSelectionChange.bind(this)
  }

  savedAddressesHtml(){
    const self = this
    const model = self.props.model
    const addresses = model.addresses
    const selected = model.selected.address || {}
    let list = []
    for(const c of addresses){
      let item = (
        <div className="po-cc" key={c.id}>
          <label>
            <input type="radio" name="shipping_address_id" value={c.id} checked={selected.id == c.id} onChange={(event)=>{this.handleSelectionChange({event: event, address:c})}}/>
            <div className="address-item">
              <div className="address-line">
                {c.firstname} {c.lastname}
              </div>
              <div className="address-line">
                {c.address1}
              </div>
              <div className="address-line">
                {c.address2}
              </div>
              <div className="address-line">
                {c.city}, {c.state} {c.zip}
              </div>
              <div className="address-line">
                {c.primary_phone}
              </div>
            </div>
          </label>
        </div>
      )
      list.push(item)
    }
    return list
  }

  handleSelectionChange({event, address}){
    let model = this.props.model
    model.selected.address = address
    this.props.changeState(model)

    window.shippingAddressId = address.id

    window.BINRecalculate()
  }

  newAddressHtml(){
    const self = this
    const model = self.props.model
    const selected = model.selected.address || {}
    let html = (
      <React.Fragment>
        <div className="po-title">
            Use a new payment method
        </div>
        <label>
          <input type="radio" name="shipping_address_id" value="-1" checked={selected.id == -1} onChange={(event)=>{this.handleSelectionChange({event: event, address:{id: -1}})}}/>
          Add new shipping method
          <div className="po-new-item po-newcc medium-8">
            { model.selected.address.id == -1
              ?
              <Shipping model={self.props.model} changeState={self.props.changeState} fetchAddresses={self.props.fetchAddresses} geo_data={this.props.geo_data} />
              :
              ""
            }
          </div>
        </label>
      </React.Fragment>
    )
    return html
  }

  render() {
    const addressList = this.savedAddressesHtml()
    const newAddress = this.newAddressHtml()

    return (
      <React.Fragment>
        <div className="po-title">
          Use my saved shipping method
        </div>
        <div className="po-saved">
          {addressList}
        </div>
        {newAddress}
      </React.Fragment>
    )
  }
}

export default Page
