import React from "react"
import Search from '../services/Search'
import Tracker from '../services/Tracker'
import {getHtmlTextValue} from "../../../shared/utils/getHtmlTextValue";

class Page extends React.Component {
  constructor(props) {
    super(props)

    this.searchService = new Search()
    this.trackerService = new Tracker()

    this.handleLotClicked = this.handleLotClicked.bind(this)
  }

  handleLotClicked({lot, pos}){
    this.trackerService.trackRecentViewClicked({lot, pos})
  }

  render(){
    const self = this
    const history = self.searchService.getViewHistory()
    let list = []
    for (var i=0; i<history.length; i++) {
      const lot = history[i]
      const title = {__html: htmlDecode(lot.title)}
      const pos = i+1
      list.push(
        <div key={i} className="rv-item cell medium-4">
          <a className="" href={lot.pretty_url}
            onClick={() => {self.handleLotClicked({lot, pos})}}>
            <div className="lot-img">
              <img src={lot.img} alt={getHtmlTextValue(lot.title)}/>
            </div>
            <div className="lot-title">
              <span dangerouslySetInnerHTML={title}></span>
            </div>
          </a>
        </div>
      )
    }
    let html = (
      <div className="recent-viewed">
        <div className="rv-title">
          RECENTLY VIEWED
        </div>
        <div className="rv-list grid-x grid-margin-x">
          {list}
        </div>
      </div>
    )
    return html
  }
}

export default Page
