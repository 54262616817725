import React from "react"
import Search from '../services/Search'
import Tracker from '../services/Tracker'
import RecentSearches from './RecentSearches'
import RecentViewed from './RecentViewed'
import SearchSuggest from './SearchSuggest'
import SearchMatches from './SearchMatches'

class Page extends React.Component {
  constructor(props) {
    super(props)
    this.searchService = new Search()
    this.trackerService = new Tracker()

    this.handleSearchTextChanged = this.handleSearchTextChanged.bind(this)
    this.handleSearchInputFocused = this.handleSearchInputFocused.bind(this)
    this.handleCloseClicked = this.handleCloseClicked.bind(this)
    this.handleSearchTextEntered = this.handleSearchTextEntered.bind(this)
    this.search = this.search.bind(this)
    this.searchDropdown = this.searchDropdown.bind(this)

    this.searchInputHtml = this.searchInputHtml.bind(this)
    this.searchInputFullBarHtml = this.searchInputFullBarHtml.bind(this)
    this.searchBarHtml = this.searchBarHtml.bind(this)
    this.searchTipHtml = this.searchTipHtml.bind(this)
    this.searchTipDetailHtml = this.searchTipDetailHtml.bind(this)
    this.searchCloseHtml = this.searchCloseHtml.bind(this)
    this.searchDetailHtml = this.searchDetailHtml.bind(this)
    this.handleSearchTipsClicked = this.handleSearchTipsClicked.bind(this)
    this.handleCreateSearchAlertClicked = this.handleCreateSearchAlertClicked.bind(this)
  }

  handleSearchTextChanged(event){
    let query = event.currentTarget.value
    this.searchDropdown(query)
  }

  search(){
    const self = this
    if(self.timer){
      clearTimeout(self.timer)
      self.timer = null
    }

    let state = self.props.model
    let filters = state.filters
    filters.query = filters.query_temp
    filters.query_temp = ""
    self.props.changeState(state)
    self.props.search({})
  }

  searchDropdown(query){
    const self = this
    const interval = 300
    let state = self.props.model
    let filters = state.filters

    // filters = this.searchService.initFilters({query_temp: query})
    state.is_searching = true
    state.is_on = true
    state.is_suggests_on = (query && query.length > 0)
    if(state.save_search_btn_txt){
      state.save_search_btn_txt = 'CREATE SEARCH ALERT'
    }

    filters.query_temp = query

    self.props.changeState(state)
    if(self.timer){
      clearTimeout(self.timer)
      self.timer = null
    }
    self.timer = setTimeout(() => {
      self.props.searchDropdown(filters)
    }, interval)
  }

  handleSearchTextEntered(event){
    const show_full_bar = this.props.model.show_full_bar
    let state = this.props.model
    if(event.key == 'Enter'){
      let filters = state.filters
      const query = filters.query_temp
      SearchUtils.appendSearchHistory(query)
      if(!show_full_bar){
        location.href="/search?keywords="+query
      }
      else{
        this.search()
        if(show_full_bar){
          // state.is_on = false
          // this.props.changeState(state)
          this.handleCloseClicked()
        }
      }
    }
    else if(event.key == 'Escape'){
      this.handleCloseClicked()
    }
  }

  handleSearchInputFocused(e){
    let state = this.props.model
    const show_full_bar = state.show_full_bar

    if(!state.is_on){
      const query = state.filters.query
      state.is_on = true
      state.filters.query_temp = query
      this.props.changeState(state)

      const location = show_full_bar ? 'nav' : 'search page'
      const keyword = query
      this.trackerService.trackSearchBarOpen({location, keyword})

      if(query){
        this.searchDropdown(query)
      }
      this._input.focus()
    }
  }

  handleCloseClicked(){
    let state = this.props.model
    const keyword = state.filters.query_temp
    state.is_on = false
    state.filters.query_temp = ""
    state.is_suggests_on = false
    state.is_tips_on = false
    state.is_searching = false
    // state.results = {}
    this.props.changeState(state)

    this.trackerService.trackCloseSearch({keyword})
  }

  handleSearchTipsClicked(){
    let on = this.props.model.is_tips_on
    this.props.changeState({is_tips_on: !on})
    if(!on){
      this.trackerService.trackSearchTipClicked()
    }
  }

  handleCreateSearchAlertClicked(){
    const self = this
    const loggedIn = currentCustomerData.id ? true : false
    if(loggedIn){
      const keyword = self.props.model.filters.query
      self.searchService.createSearchAlert(keyword).then(res => {
        let r = res.data
        if(r.status == 'Item Found' || r.status == 'Created'){
          self.props.changeState({save_search_btn_txt: 'Email Alert Created'})
        }
        else{

        }
      })
      self.trackerService.trackSaveSearchAlertClick({keyword})
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot){
    if(prevProps.model.is_on){
      this._input.focus()
    }
    else{
      this._input.blur()
    }
  }

  searchInputHtml(){
    const state = this.props.model
    const show_full_bar = state.show_full_bar

    const placeholder_short = 'Search'
    const placeholder_long = 'Search for experiences, items, and charities'
    let cls = ""
    let searchIconCls = ""
    if(show_full_bar){
      cls += "full-bar"
      searchIconCls = "hidden"
    }
    else{
      cls += (state.is_on ? 'on' : 'off')
    }

    const query = state.is_on ? state.filters.query_temp : state.filters.query
    // const query = state.filters.query_temp
    let html = (
      <div className="search-query input-group" >
        <button className={`search-icon input-group-label ${cls} ${searchIconCls}`} onClick={this.handleSearchInputFocused} title={placeholder_long}>
          <i className="fal fa-search"></i>
          <span className="search-label show-for-large">{placeholder_long}</span>
          <span className="search-label hide-for-large">{placeholder_short}</span>
        </button>
        <div className={`input-group-field ${cls}`}>
          <i className="fal fa-search"></i>
          <input className={`search-input ${cls}`}
            placeholder={placeholder_long}
            value={query}
            ref={c => (this._input = c)}
            onKeyDown={this.handleSearchTextEntered}
            onFocus={this.handleSearchInputFocused}
            onChange={this.handleSearchTextChanged} />
          </div>
      </div>
    )
    return html
  }

  searchInputFullBarHtml(){
    let html = null
    const state = this.props.model

    if(state.is_on){
      html = (
        <div className="search-bar active">
          <div className="container grid-x">
            <div className="cell small-9">
              <this.searchInputHtml />
            </div>
            <div className="cell small-1">
              <this.searchCloseHtml />
            </div>
          </div>
        </div>
      )
    }
    else{
      html = (
        <div className="search-bar grid-x">
          <div className="cell small-12">
            <this.searchInputHtml />
          </div>
        </div>
      )
    }
    return html
  }

  searchTipHtml(){
    let html = (
      <div className="search-tips show-for-medium">
        <button className={`caller ${this.props.model.is_tips_on ? 'active' : ''}`} 
          onClick={this.handleSearchTipsClicked}
                aria-expanded={this.props.model.is_tips_on ? "true" : "false"}>
          <i className="fal fa-question-circle"></i>
          <span>Search Tips</span>
        </button>
      </div>
    )
    return html
  }

  searchTipDetailHtml(){
    let html = (
      <div className="search-tips-detail grid-x grid-padding-x" role="region" aria-label="Search Suggestions" >
        <div className="st-title cell small-12">
          Search Suggestions
        </div>
        {/* <div className="st-subtitle cell small-12">
        </div> */}
        <div className="st-text cell small-12">
        Search will show useful suggestions, before you hit enter, to help surface what you’re looking for.
        </div>
        <div className="st-well cell small-12">
        <p><b>Top Match</b> - Shows the top three exact matching lots, if any.</p>
        <p><b>Similar</b> - Shows other possible searches that contain your exact search term, if any.</p>
        <p><b>Related</b> - Shows other possible searches with content close to your search term, if any.</p>
        <p><b>Charities</b> - Shows charities that contain your exact search term.</p>
        </div>
      </div>
    )
    return html
  }

  searchCloseHtml(){
    let model = this.props.model
    let html = null
    const loggedIn = currentCustomerData.id ? true : false

    if(model.is_on){
      html = (
        <button className="search-close" onClick={this.handleCloseClicked}>
          <i className="fal fa-times"></i>
          <span className="show-for-medium">Close</span>
        </button>
      )
    }
    else{
      html = (
        <button className={`search-create-alert btn-secondary ${loggedIn ? '' : 'hidden'}`} onClick={this.handleCreateSearchAlertClicked}>
          {model.save_search_btn_txt}
        </button>
      )
    }
    return html
  }

  searchLoadingHtml(){
    let html = (
      <div className="ico-loading la-ball-clip-rotate-multiple la-2x" aria-hidden="true">
        <div></div>
        <div></div>
      </div>
    )

    return html
  }

  searchDetailHtml(){
    let html = null
    const state = this.props.model
    const data_results = state.results_temp || state.results || {}
    const results = data_results.results || {}
    const hasLots = !_.isEmpty(results.catalog_items)
    const hasOrgs = !_.isEmpty(results.orgs)
    const noResults = (!hasLots && !hasOrgs)

    if(state.is_on){
      if(state.is_searching){
        const loadingHtml = this.searchLoadingHtml()
        html = (
          <div className="search-results active loading text-center">
            {loadingHtml}
          </div>
        )
      }
      else{
        if(state.is_suggests_on){
          html = (
            <div className="search-results active">
              <div className="container grid-x grid-padding-x">
                <div className={`cell medium-3 show-for-medium ${noResults ? 'hidden' : ''}`}>
                  <SearchSuggest model={state}/>
                </div>
                <div className={`cell ${noResults ? 'medium-12' : 'medium-9 large-8 large-offset-1'} `}>
                  <SearchMatches model={state} />
                </div>
                <div className={`cell medium-3 hide-for-medium ${noResults ? 'hidden' : ''}`}>
                  <SearchSuggest model={state}/>
                </div>
              </div>
              {noResults ?
                (
                  <div className="container grid-x">
                    <div className="cell medium-2">
                      <RecentSearches />
                    </div>
                    <div className="cell medium-10">
                      <RecentViewed />
                    </div>
                  </div>
                )
              :
                ""
              }
            </div>
          )
        }
        else{
          html = (
            <div className="search-results recommended">
              <div className="container grid-x">
                <div className="cell medium-2">
                  <RecentSearches />
                </div>
                <div className="cell medium-10">
                  <RecentViewed />
                </div>
              </div>
            </div>
          )
        }

      }
    }
    return html
  }

  searchBarHtml(){
    let html = null
    const state = this.props.model
    const show_full_bar = state.show_full_bar
    let cls = show_full_bar ? 'full-bar' : ''
    if(state.is_on || show_full_bar){
      html = (
        <div className={`search-bar active ${cls}`}>
          <div className="container grid-x">
            <div className="cell small-7">
              <this.searchInputHtml />
            </div>
            <div className="tips-close-wrapper cell small-5 align-right">
              <div className="search-tips-wrapper">
                <this.searchTipHtml />
                {state.is_tips_on ? <this.searchTipDetailHtml /> : ''}
              </div>
              <div className="search-close-wrapper">
                <this.searchCloseHtml />
              </div>
            </div>
          </div>
        </div>
      )
    }
    else{
      html = (
        <div className="search-bar grid-x">
          <div className="cell small-12">
            <this.searchInputHtml />
          </div>
        </div>
      )
    }
    return html
  }

  render(){
    let html = (
      <div className="nav-search">
        <this.searchBarHtml />
        <this.searchDetailHtml />
      </div>
    )
    return html
  }

}

export default Page
