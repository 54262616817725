import React from 'react'
import Search from './services/Search'
import Homepage from './services/Homepage'
import ListingFilters from './components/ListingFilters'
import EntityRow from './components/EntityRow'
import TopBINRow from './components/TopBINRow'
import CategoryRow from './components/CategoryRow'
import ThemeRow from './components/ThemeRow'
import StaffPickLotRow from './components/StaffPickLotRow'
const qs = require('qs')

class Page extends React.Component {
  constructor(props){
    super(props)
    this.searchService = new Search()
    this.homepage = new Homepage()

    this.state = {
      is_searching: false,
      // filters: {},
      results: [],
      categories: []
    }

    this.changeState = this.changeState.bind(this)
    // this.search = this.search.bind(this)
    // this.listenGlobalEvents = this.listenGlobalEvents.bind(this)
    this.lotListHtml = this.lotListHtml.bind(this)

    // this.updateResultsFromUrl()
    this.listenGlobalEvents()
  }

  componentDidMount(){
    this.loadData() 
    this.updateResultsFromUrl()
  }

  loadData(){
    const self = this
    let state = self.state

    self.homepage.loadCategories().then(res => {
      const data = res.data
      state.categories = data
      self.setState(state)
    })
  }

  updateResultsFromUrl(){
    const self = this
    let state = self.state

    const qr = window.location.search.substr(1)
    const params = qs.parse(qr)
    const viewHistory = self.searchService.getViewHistory()
    const viewedLotIds = viewHistory.map((i)=>{return i.id})

    let query = {
      viewed_lot_ids: viewedLotIds,
      sort_by: params.sort_by,
      sort_direction: params.sort_direction,
      buying_format: params.buying_format
    }

    const loadEvent = new CustomEvent('load-listing-cards',{});
    document.dispatchEvent(loadEvent);
    
    self.homepage.loadEntities(query).then(res => {
      const data = res.data
      // for(let e of data){
      //   if(e.row_type == 'entity'){
      //     state.results.entities = homepage.parseEntityData({data: e})
      //   }
      //   else if(e.row_type == 'charity'){
      //     state.results.orgs = homepage.parseCharityData({data: e})
      //   }
      //   else if(e.row_type == 'theme'){
      //     state.results.themes = homepage.parseThemeData({data: e})
      //   }
      //   else if(e.row_type == 'top_bin_lots'){
      //     state.results.top_bins = homepage.parseTopBinData({data: e})
      //   }
      //   else if(e.row_type == 'custom_row'){
      //     state.results.custom_row = homepage.parseCustomRowData({data: e})
      //   }
      //   else if(e.row_type == 'featured_lot'){
      //     state.results.staff_picks = homepage.parseFeaturedData({data: e})
      //   }
      //   else{

      //   }
      // }
      
      state.results = data
      self.setState(state)


      const loadDoneEvent = new CustomEvent('load-listing-cards-done',{});
      document.dispatchEvent(loadDoneEvent);


      // let pagination = {
      //   page: 1,
      //   per_page: 48,
      //   total_objects: 1,
      //   total_pages: 1
      // }
      // const pageEvent = new CustomEvent('update-listing-pagination',{detail: {pagination: pagination}});
      // document.dispatchEvent(pageEvent);
    })

    // let urlParams = new URLSearchParams(window.location.search)
    // let queryStr = urlParams.toString()
    // this.searchService.applyQueryStrToFilters({filters: filters, queryStr: queryStr})
  }

  listenGlobalEvents(){
    const self = this

    // update url with pushstate if filter changed
    document.addEventListener("update-listing-filters", function(event){
      const filters = event.detail.filters
      self.updateUrlFromFilters({filters: filters})   
      self.updateResultsFromUrl()   
    })

    window.addEventListener("popstate", function(event){
      self.updateResultsFromUrl()
      // self.setState({filters: self.state.filters})
    })
  }

  parseCardsJson(cards){
    let newJson = []
    for(let i=0; i<cards.length; i++){
      const card = cards[i]
      // const closeTime = card.close_time ? moment(card.close_time).unix() : null
      // const currentPrice = parseInt(card.current_price)
      // let item = {
      //   id: card.id,
      //   pretty_id: card.pretty_id,
      //   title: card.name,
      //   buying_format: "",
      //   close_time: closeTime,
      //   current_price: "",
      //   estimated_price: currentPrice,
      //   lot_image: card.primary_image_thumb,
      //   lot_type: "",
      //   quantity: card.bin_qty_available,
      //   status: []
      // }
      newJson.push(card.v2)
    }
    return newJson
  }

  changeState(newState){
    this.setState(newState)
  }

  updateUrlFromFilters({filters}){
    const low = filters.price.low||''
    const high = filters.price.high||''
    const bf = filters.buying_format.code||''
    const sort_opt = filters.sort_by.query.code||''
    const sort_by = sort_opt.split(":")[0]||''
    const sort_direction = sort_opt.split(":")[1]||''
    const current_path = location.pathname
    const qs = `sort_direction=${sort_direction}&sort_by=${sort_by}&buying_format=${bf}`
    const url = `${current_path}?${qs}`

    // let event = new CustomEvent('route-to',{detail: {url: url, options:{trigger: true}}});
    // document.dispatchEvent(event);
    history.pushState(filters, null, url)
  }

  lotListHtml(){
    const self = this
    const model = self.state
    const results = model.results
    const entities = results.entities

    let entitiesList = []
    for(let i=0; i<entities.length; i++){
      const entity = entities[i]
      entitiesList.push(
        <EntityRow entity={entity} key={`entity-${i}`} />
      )
    }

    return entitiesList
  }

  splitDataForDisplay({data, segments}){
    let count = data.length / segments
    let list = []
    for(let i = 0 ; i < segments; i++){
      let r = data.splice(0, count)
      list.push(r)
    }

    return list
  }

  render(){
    const self = this
    const state = self.state
    // const total = state.results.pagination.total_objects

    // const lotsHtml = this.lotListHtml()

    let entitiesHtml = []
    // let entitiesList = this.splitDataForDisplay({data: state.results.entities, segments: 3})
    // for(let l of entitiesList){
    //   let entityHtml = []
    //   entitiesHtml.push(entityHtml)
    //   for(let e of l){
    //     entityHtml.push(
    //       <div className="">
    //         <EntityRow entity={e} key={`entity-${e.id}`} />
    //       </div>
    //     )
    //   }
    // }

    for(let e of state.results){
      if(e.row_type == 'entity'){
        const row = self.homepage.parseEntityData({data: e})
        entitiesHtml.push(<EntityRow entity={row} key={`hp-row-${row.rank}`} />)
      }
      else if(e.row_type == 'charity'){
        const row = self.homepage.parseCharityData({data: e})
        entitiesHtml.push(<EntityRow entity={row} key={`hp-row-${row.rank}`} />)
      }
      else if(e.row_type == 'category'){
        const row = self.homepage.parseCategoryData({data: e})
        entitiesHtml.push(<EntityRow entity={row} key={`hp-row-${row.rank}`} />)
      }
      else if(e.row_type == 'theme'){
        const row = self.homepage.parseThemeData({data: e})
        entitiesHtml.push(<EntityRow entity={row} key={`hp-row-${row.rank}`} />)
      }
      else if(e.row_type == 'top_bin_lots'){
        const row = self.homepage.parseTopBinData({data: e})
        entitiesHtml.push(<TopBINRow entity={row} key={`hp-row-${row.rank}`} />)
      }
      else if(e.row_type == 'custom_row'){
        const row = self.homepage.parseCustomRowData({data: e})
        entitiesHtml.push(<EntityRow entity={row} key={`hp-row-${row.rank}`} />)
      }
      else if(e.row_type == 'featured_lot'){
        const row = self.homepage.parseFeaturedData({data: e})
        const lot = row.catalog_items[0]
        entitiesHtml.push(<StaffPickLotRow entity={row} lot={lot} key={`hp-lot-${lot.id}`} />)
      }
      else{

      }
    }

    return (
      <div>
        <ListingFilters />
        {entitiesHtml}
        <div className="">
          <CategoryRow categories={state.categories} />
        </div>
      </div>
    )
  }
}

export default Page
