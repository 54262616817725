import React from "react"
import BaseComponent from "../__application/BaseComponent"

class Page extends BaseComponent {

  constructor(props) {
    super(props)
    let geo_data = this.props.geo_data
    geo_data.countries = _.filter(geo_data.countries, function(v){return v[1]!='US'})
    geo_data.countries.unshift(["United States", "US"], ["--------", "-"])
    geo_data.states.us.unshift(["Select State", ""])
    geo_data.states.canada.unshift(["Select State", ""])
    this.state = {
      parent: this.props.state,
      geo_data: geo_data,
      states_list: []
    }

    this.state.states_list = this.adjustStatesList(false)

    this.handleChange = this.props.handleChange
    this.handleFocus = this.props.handleFocus
    this.handleFocusOut = this.props.handleFocusOut
    this.handleKeyPress = this.props.handleKeyPress

    // This binding is necessary to make `this` work in the callback
    this.handleChangeCountry = this.handleChangeCountry.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleFocusOut = this.handleFocusOut.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }


  handleChangeCountry(event){
    this.handleChange(event)
    this.adjustStatesList()
  }

  adjustStatesList(updateState=true){
    let states_list_change = this.state.states_list

    if(this.state.parent.model.country_name == 'United States'){
      states_list_change = this.state.geo_data.states.us
    }
    else if(this.state.parent.model.country_name == 'Canada'){
      states_list_change = this.state.geo_data.states.canada
    }
    else{
      states_list_change = []
    }

    let parent = this.state.parent
    parent.model.state = ""

    if(updateState){
      this.setState({
        parent: parent,
        states_list: states_list_change
      })
    }
    return states_list_change
  }


  render() {
    let stateDom
    if(this.state.states_list.length == 0){
      stateDom = <input autoComplete="off" name="state" className="form-input input-state" type="text" value={this.state.parent.model.state} onKeyPress={this.handleKeyPress} onChange={this.handleChange} onFocus={this.handleFocus} onBlur={this.handleFocusOut} placeholder="State" />
    }
    else{
      stateDom = (
        <select name="state" className="form-input input-state" value={this.state.parent.model.state} onKeyPress={this.handleKeyPress} onChange={this.handleChange} onFocus={this.handleFocus} onBlur={this.handleFocusOut} placeholder="State">
          { _.map(this.state.states_list, state_with_code => <option key={state_with_code[0]} value={state_with_code[1]}>{state_with_code[0]} </option>) }
        </select>)
    }

    return (
      <div>
        <div className="form-row grid-x">
          <div className="cell medium-12">
            <div className={`form-input-wrapper ${this.state.parent.classNames.address1}`}>
              <label className="input-legend">{this.state.parent.model.errors.address1 || this.state.parent.labels.address1}</label>
              <input autoComplete="off" name="address1" className="form-input" type="text" value={this.state.parent.address1} onKeyPress={this.handleKeyPress} onChange={this.handleChange} onFocus={this.handleFocus} onBlur={this.handleFocusOut} placeholder="Address" />
            </div>
          </div>
        </div>
        <div className="form-row grid-x">
          <div className="cell medium-12">
            <div className={`form-input-wrapper ${this.state.parent.classNames.address2}`}>
              <label className="input-legend">{this.state.parent.model.errors.address2 || this.state.parent.labels.address2}</label>
              <input autoComplete="off" name="address2" className="form-input" type="text" value={this.state.parent.address2} onKeyPress={this.handleKeyPress} onChange={this.handleChange} onFocus={this.handleFocus} onBlur={this.handleFocusOut} placeholder="Apt, Suite, Bldg., etc." />
            </div>
          </div>
        </div>
        <div className="form-row grid-x">
          <div className="cell medium-7 no-padding col-input-left">
            <div className={`form-input-wrapper ${this.state.parent.classNames.country_name}`}>
              <label className="input-legend">{this.state.parent.model.errors.country_name || this.state.parent.labels.country_name}</label>
              <select name="country_name" className="form-input" value={this.state.parent.model.country_name} onKeyPress={this.handleKeyPress} onChange={this.handleChangeCountry} onFocus={this.handleFocus} onBlur={this.handleFocusOut} placeholder="Country">
                { _.map(this.state.geo_data.countries, country_with_code => <option key={country_with_code[0]} value={country_with_code[0]} disabled={country_with_code[1]=="-" ? true : null} >{country_with_code[0]} </option>) }
              </select>
            </div>
          </div>
          <div className="cell medium-5 no-padding col-input-right">
            <div className={`form-input-wrapper ${this.state.parent.classNames.state}`}>
              <label className="input-legend">{this.state.parent.model.errors.state || this.state.parent.labels.state}</label>
              {stateDom}
            </div>
          </div>
        </div>
        <div className="form-row grid-x">
          <div className="cell medium-7 no-padding col-input-left">
            <div className={`form-input-wrapper ${this.state.parent.classNames.city}`}>
              <label className="input-legend">{this.state.parent.model.errors.city || this.state.parent.labels.city}</label>
              <input autoComplete="off" name="city" className="form-input" type="text" value={this.state.parent.city} onKeyPress={this.handleKeyPress} onChange={this.handleChange} onFocus={this.handleFocus} onBlur={this.handleFocusOut} placeholder="City" />
            </div>
          </div>
          <div className="cell medium-5 no-padding col-input-right">
            <div className={`form-input-wrapper ${this.state.parent.classNames.zip}`}>
              <label className="input-legend">{this.state.parent.model.errors.zip || this.state.parent.labels.zip}</label>
              <input autoComplete="off" name="zip" className="form-input" type="text" value={this.state.parent.zip} onKeyPress={this.handleKeyPress} onChange={this.handleChange} onFocus={this.handleFocus} onBlur={this.handleFocusOut} placeholder="Zip" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Page
