import React from 'react';
import MyCBService from './services/MyCBService'

class MegaMenu extends React.Component {
  constructor(props) {
    super(props)

    this.cbService = new MyCBService()

    this.dataCategories = props.categories
    this.dataThemes = props.themes
    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.handleRootCategoryClicked = this.handleRootCategoryClicked.bind(this)
    this.loadData = this.loadData.bind(this)

    this.state = {
      active_category_id: null,
      my_cb: {
        count: 0,
        outbids: 0,
        recs: 0,
        watches: 0,
        winnings: 0
      }
    }
  }

  loadData(){
    const loggedIn = currentCustomerData.id ? true : false
    if(loggedIn){
      const self = this
      self.cbService.getData().then(data => {
        const stats = data.my_cb
        self.state.my_cb = stats
        self.setState(self.state)
      })
    }
  }

  handleRootCategoryClicked(event, category){
    const label = this.currentPageType()
    const eventLabel = label + '|' + category.slug;
    ga('send', {
        'hitType': 'event',
        'eventCategory': 'Top Nav Explore Activity',
        'eventAction': 'Primary Click',
        'eventLabel': eventLabel
      });

    dataLayer.push({
        'event': 'ua_event',
        'event_category': 'Top Nav Explore Activity',
        'event_action': 'Primary Click',
        'event_label': eventLabel
      });

    // prevent redirect in mobile click
    if(window.innerWidth < 640){
      event.preventDefault()
      return false
    }
  }

  handleChildCategoryClicked(event, rootCategory, category){
    const page = this.currentPageType()
    const eventLabel = page+'|'+rootCategory.slug+'|'+category.slug;
    ga('send', {
        'hitType': 'event',
        'eventCategory': 'Top Nav Explore Activity',
        'eventAction': 'Secondary Click',
        'eventLabel': eventLabel
      });

    dataLayer.push({
        'event': 'ua_event',
        'event_category': 'Top Nav Explore Activity',
        'event_action': 'Secondary Click',
        'event_label': eventLabel
      });
  }

  currentPageType(){
    const page = window.location.href
    const location_array = page.split('/')
    if (location_array[3] == '#'){
      return 'homepage'
    } else {
      return location_array[3]
    }
  }


  handleHover({event, category}) {
    if(category == 'my-charitybuzz'){
      this.setState({active_category_id: category})
    }
    else{
      this.setState({active_category_id: category.id})
    }
  }
  
  handleClickOutside(event) {
    const dom = event.target
    const insideClasses = [
      'explore-lots-wrapper', 
      'mega-menu',
      'nav-explore',
      'mmm',
    ];
    const clickOutside = !insideClasses.some(className => dom.className.includes(className))
    if(clickOutside){
      if(this.props.isVisible){
        this.props.setVisibility(false)
        $(".nav-explore-up-down, .up-down-symbol").removeClass("fa-angle-up").addClass("fa-angle-down")
      }
    }
  }

  componentDidMount() {
    this.loadData()
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  UNSAFE_componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  render() {
    const loggedIn = currentCustomerData.id ? true : false
    const categories = this.dataCategories
    const my_cb = this.state.my_cb
    const ViewAllHtml = ( <ul className='subcategory-group view-all'> TEST </ul> )
    return (
      <section
      id="explore-lots-menu"
      aria-labelledby="nav-button-for-mega-menu"
      className={this.props.navClass + (this.props.isVisible ? ' mega-menu active' : ' mega-menu')}
      aria-expanded={!!this.props.isVisible}
      style={this.props.isVisible ? {}:{visibility:"hidden"}}
      >
        {/* Loop through category names to output category names and links */}
        <div className="mmm mega-menu__category-wrapper">
          <ul className="mmm">
            <li className="mmm category-link view-all">
              <a className='mmm' href="/categories/view-all/catalog_items?hide=carousel">View All <i className="fal fa-angle-right"></i></a>
            </li>

            {/* <li className="category-link buy-now">
              <a href="/theme/buy-now/lots?sort_direction=asc&sort_by=current_price&buying_format=Buy%20It%20Now&default=1">Buy Now <i className="fal fa-angle-right"></i></a>
            </li> */}

            {/* {
              this.dataThemes.map(theme => {
                return(
                  <li className="category-link curated" key={theme.theme_id}>
                    <a href={`/themes/${theme.slug}/lots`}>{theme.curated_title || theme.theme_name}</a>
                  </li>
                )
              })
            } */}

            {
              loggedIn ? 
                (
                  <li className={`mmm category-link my-charitybuzz ${this.state.active_category_id=='my-charitybuzz' ? 'active' : ''}`} 
                    onMouseEnter={(event)=>{this.handleHover({event, category:'my-charitybuzz'})}}>
                    <a className='mmm' href="#">
                      My Charitybuzz 
                      <span className={`mmm watch-circle my-cb-circle my-cb-count ${my_cb.recs>0 ? '' : 'hidden'}`}>
                        {my_cb.recs}
                      </span>
                    </a>
                  </li>
                )
              :
              ''
            }


            {categories.map(category => {
              if (category.children.length >= 1) 
                return <li 
                  className={`mmm category-link ${category.slug} ${this.state.active_category_id==category.id ? 'active' : ''}`}
                  key={category.slug}
                  onMouseEnter={(event)=>{this.handleHover({event, category})}}
                  data-categoryid={category.slug}
                  >
                    <a 
                    className='mmm'
                    href={"/categories/" + category.slug + "/catalog_items?hide=carousel"}
                    onClick={(event) => { this.handleRootCategoryClicked(event, category) }}
                    data-category-id={category.slug}
                    >{category.category_name}</a>
                  </li>
            })}
          </ul>
        </div>
        {/* Loop through category children to output subcategory names and links */}
        <div className="mmm mega-menu__subcategory-wrapper">
          <ul 
            className={`mmm subcategory-group ${this.state.active_category_id=='my-charitybuzz' ? 'active' : ''}`}
            key='my-charitybuzz'
            >
              <li className='mmm' key='my-winnings'>
                <a className='mmm' href={"/categories/currently-winning/catalog_items"}>Currently Winning <span className={`mmm watch-circle ${my_cb.winnings>0 ? '' : 'hidden'}`}>{my_cb.winnings}</span></a>
              </li>
              <li className='mmm'  key='my-watches'>
                <a className='mmm' href={"/categories/my-watches/catalog_items"}>My Watches <span className={`mmm watch-circle ${my_cb.watches>0 ? '' : 'hidden'}`}>{my_cb.watches}</span></a>
              </li>
              <li className='mmm' key='my-outbids'>
                <a className='mmm' href={"/categories/my-outbids/catalog_items"}>My Outbids <span className={`mmm watch-circle ${my_cb.outbids>0 ? '' : 'hidden'}`}>{my_cb.outbids}</span></a>
              </li>
          </ul>

          {categories.map(category => (
            <div 
            className={`mmm ${category.category_name}`}
            key={category.slug}
            >
              <div 
              className={`mmm subcategory-group ${this.state.active_category_id==category.id ? 'active' : ''}`}
              key={category.slug}
              >
              <a href={"/categories/" + category.slug + "/catalog_items?hide=carousel"}
                className="mmm subcategory-group__view-all">View All {category.category_name} <i className="fal fa-angle-right"></i></a>
              <ul className='mmm'>
              {/* Hide all children with hidden: true */}
                {category.children.map(child => {
                  if (child.hidden === false)
                    return <li
                    className='mmm' 
                    key={child.slug}
                    >
                      <a className='mmm' href={"/categories/" + child.slug + "/catalog_items?hide=carousel"}
                          onClick={(event) => { this.handleChildCategoryClicked(event, category, child) }}
                      >{child.category_name}</a>
                    </li>
                })}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </section>
    )
  }
}

export default MegaMenu;