import React from "react"

class Page extends React.Component {
  constructor(props) {
    super(props)

    this.handleInputChanged = this.handleInputChanged.bind(this)
    this.handleInputFocused = this.handleInputFocused.bind(this)
  }

  handleInputFocused(){
    let model = this.props.model
    let filters = model.filters
    filters.sort_by.on = false
    filters.departments.on = false
    filters.details.on = false
    filters.orgs.on = false
    filters.themes.on = false
    this.props.changeState(model)
  }

  handleInputChanged({event, leg}){
    const self = this
    const interval = 300
    let model = self.props.model
    let filters = model.filters
    let price = filters.price
    let n = event.currentTarget.value
    n = parseInt(n)
    if(isNaN(n)){
      n = leg=='low' ? 0 : 1000000
    }
    price[leg] = n
    model.filters.pagination.page = 1
    self.props.changeState(model)

    if(self.timer){
      clearTimeout(self.timer)
      self.timer = null
    }
    self.timer = setTimeout(() => {
      self.props.search({})
    }, interval)
  }

  render(){
    const self = this
    let list = []
    const model = self.props.model
    const filters = model.filters
    const low = filters.price.low
    const high = filters.price.high

    let html = (
      <div>
        <div className="filter-title">
          Current Price
        </div>
        <div className="price-range-wrapper grid-x text-center">
          <div className="ico-dollar cell small-2">
            <i className="fal fa-dollar-sign"></i>
          </div>
          <div className="input-price cell small-5">
            <div className="i-label">Lowest Price</div>
            <input value={low} onFocus={this.handleInputFocused} onChange={(e)=>{self.handleInputChanged({event: e, leg: 'low'})}}/>
          </div>
          <div className="input-price cell small-5">
            <div className="i-label">Highest Price</div>
            <input value={high} onFocus={this.handleInputFocused} onChange={(e)=>{self.handleInputChanged({event: e, leg: 'high'})}}/>
          </div>
        </div>
      </div>
    )
    return html
  }
}

export default Page
