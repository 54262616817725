import React from "react"
import BaseComponent from "../__application/BaseComponent"

class Page extends BaseComponent {

  constructor(props) {
    super(props)
  }


  render() {
    const self = this
    const tag = self.props.tag
    let t = ""
    if(tag){
      t = tag.toLowerCase()
    }

    let p = {
      css_class: "",
      style: ""
    }
    if(t == 'announcement'){
      p.css_class = 'fal fa-megaphone'
      p.style = {color: "#F09334"}
    }
    else if(t == 'bug fix'){
      p.css_class = 'fal fa-bug'
      p.style = {color: "#2B9CD6"}
    }
    else if(t == 'improvement'){
      p.css_class = 'fal fa-chart-line'
      p.style = {color: "#59BCA4"}
    }
    else if(t == 'maintenance'){
      p.css_class = 'fal fa-wrench'
      p.style = {color: "#F09334"}
    }
    else if(t == 'new'){
      p.css_class = 'fal fa-sparkles'
      p.style = {color: "#59BCA4"}
    }
    else if(t == 'welcome'){
      p.css_class = 'fal fa-comment-alt-smile'
      p.style = {color: "#2B9CD6"}
    }
    else {
      p.css_class = 'fal fa-megaphone'
      p.style = {}
    }


    return (
      <i className={p.css_class} style={p.style}></i>
    );
  }
}

export default Page
