import React from 'react';
import Notification from './services/Notification'

class Page extends React.Component {
  constructor(props) {
    super(props)

    this.changePhone = this.changePhone.bind(this)
    this.yesClicked = this.yesClicked.bind(this)
    this.noClicked = this.noClicked.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.listenEvents = this.listenEvents.bind(this)

    this.myRef = React.createRef()
    this.notificationService = new Notification()
    this.state = {
      settings: {
        phone: "",
        bid_notify: null,
        watch_notify: null,
        to_prompt_for_bid: null,
        to_prompt_for_watch: null
      },
      agreed_to_sms: true,
      errors: null,
      event_action: null, // bid or watch
      last_action_id: null
    }
  }

  checkSMSSetting(){
    const self = this
    const loggedIn = window.currentCustomerData.id
    if(!loggedIn){
      return
    }

    this.notificationService.checkSMSSettings().then((res)=>{
      let s = self.state
      s.settings = res
      self.setState(s)
      if(s.event_action=='bid' && s.settings.to_prompt_for_bid){
        self.openModal() 
      }
      else if(s.event_action=='watch' && s.settings.to_prompt_for_watch){
        self.openModal() 
      }
    })
  }

  setSMSSetting(){
    const self = this
    this.notificationService.setSMSSettings({settings: self.state.settings, event_action: self.state.event_action, last_action_id: self.state.last_action_id}).then((res)=>{
      self.closeModal()
    })
  }

  openModal(){
    let modal = this.myRef
    let elem = modal.current
    $(elem).foundation()
    $(elem).foundation('open');
  }

  closeModal(){
    let modal = this.myRef
    let elem = modal.current
    // $(elem).foundation()
    $(elem).foundation('close');
  }

  changePhone(event){
    const val = event.target.value
    let s = this.state
    s.settings.phone = val
    this.setState(s)
  }

  yesClicked(){
    let s = this.state
    // if (!s.agreed_to_sms){
    //   s.errors = "You must agree before continue."
    //   this.setState(s)
    //   return
    // }
    if (_.isEmpty(s.settings.phone)){
      s.errors = "You must enter a valid phone number."
      this.setState(s)
      return
    }

    else{
      s.errors = null
      if(s.event_action == 'bid'){
        s.settings.bid_notify = true
      }
      else if(s.event_action == 'watch'){
        s.settings.watch_notify = true
      }
      this.setState(s)
      this.setSMSSetting()
    }
  }

  noClicked(){
    let s = this.state
    if(s.event_action == 'bid'){
      s.settings.bid_notify = false
    }
    else if(s.event_action == 'watch'){
      s.settings.watch_notify = false
    }
    this.setState(s)
    this.setSMSSetting()
  }

  listenEvents(){
    const self = this
    document.addEventListener("check_notification_settings", function(event){
      const detail = event.detail
      let s = self.state
      s.event_action = detail.event_action
      s.last_action_id = detail.last_action_id
      self.setState(s)
      self.checkSMSSetting()
    })
  }

  bidNotifyHtml(){
    return(
      <div className="medium-12 cell">
        <p className="lead text-center">
          <b>Don’t miss the auction closing!</b>
          &nbsp; <i className="fal fa-sms fa-2x" style={{"color": "#7373ed"}}></i>
        </p>
        <p style={{"fontSize": "12px"}}>
          Sign up for text messages to get notified if you’re outbid. Auctions move fast – sign up for texts to make sure you’re the winning bidder! 
        </p>
      </div>
    )
  }

  bidNotifyLegalHtml(){
    return(
      <div className="medium-12 cell">
        <p>
          <label style={{"fontSize": "9px", "lineHeight": "1.2"}}>
            By submitting this form, you agree to receive recurring automated promotional and personalized marketing text messages (e.g. cart reminders) from Charitybuzz at the cell number used when signing up. Consent is not a condition of any purchase. Reply HELP for help and STOP to cancel. Msg frequency varies. Msg and data rates may apply. View <a href="https://attnl.tv/t/5cc" target="_blank">Terms</a> & <a href="https://attnl.tv/p/5cc" target="_blank">Privacy</a>.
          </label>
        </p>
      </div>
    )
  }

  watchNotifyHtml(){
    return(
      <div className="medium-12 cell">
        <p className="lead text-center">
          <b>Never miss an auction closing again!</b>
          &nbsp; <i className="fal fa-sms fa-2x" style={{"color": "#7373ed"}}></i>
        </p>
        <p style={{"fontSize": "12px"}}>
          Auctions move fast, and if you want to place a bid before it’s too late, sign up for texts to make sure you get notified before an item closes and win what you want!
        </p>
      </div>
    )
  }

  watchNotifyLegalHtml(){
    return(
      <div className="medium-12 cell">
        <p>
          <label style={{"fontSize": "9px", "lineHeight": "1.2"}}>
            By submitting this form, you agree to receive recurring automated promotional and personalized marketing text messages (e.g. cart reminders) from Charitybuzz at the cell number used when signing up. Consent is not a condition of any purchase. Reply HELP for help and STOP to cancel. Msg frequency varies. Msg and data rates may apply. View <a href="https://attnl.tv/t/5cc" target="_blank">Terms</a> & <a href="https://attnl.tv/p/5cc" target="_blank">Privacy</a>.
          </label>
        </p>
      </div>
    )
  }

  componentDidMount(){
    // this.checkSMSSetting()
    this.listenEvents()
  }

  render() {
    const self = this
    // let checked = this.state.agreed_to_sms
    // const onAgreeClicked = function(){self.setState({agreed_to_sms: !checked})}

    const copy = this.state.event_action == 'bid' ? this.bidNotifyHtml() : this.watchNotifyHtml()
    const legal = this.state.event_action == 'bid' ? this.bidNotifyLegalHtml() : this.watchNotifyLegalHtml()

    return (
      <div className="reveal" data-reveal ref={this.myRef}>
        <button className="close-button" data-close aria-label="Close modal" type="button" onClick={this.closeModal}>
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="grid-x grid-padding-x">
          {copy}
          <div className="medium-12 cell">
            <p>
              Phone Number: &nbsp; &nbsp; <input size="15" onChange={this.changePhone} value={this.state.settings.phone}/>
            </p>
          </div>
          {legal}
          <div className="medium-12 cell">
            <p style={{"color": "red"}}>
              {this.state.errors}
            </p>
          </div>
          <div className="medium-12 cell align-center">
            <div className="grid-x grid-padding-x">
              <div className="small-6 cell">
                <button className="button expanded success" onClick={this.yesClicked}> Yes </button>
              </div>
              <div className="small-6 cell">
                <button className="button expanded secondary" onClick={this.noClicked}> No </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Page;